import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Type List Api method declarations
 */
export const typeListApi = {
  geTypeList(request) {
    return postData(ApiUris.typeListApi.getTypeList, request);
  },
  getTypeListByid(id) {
    return getData(`${ApiUris.typeListApi.getTypeListById}/${id}`);
  },
//   deleteFleetListByid(id) {
//     return deleteData(`${ApiUris.fleetListApi.}?id=${id}`);
//   },
  createTypeList(request) {
    return postData(ApiUris.typeListApi.addTypeList, request);
  },
  editTypeList(request) {
    return postData(ApiUris.typeListApi.updateTypetList, request);
  },
};
