import { postFormData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Support Api method declarations
 */
export const SupportAPi = {
  supportData(request) {
    return postFormData(ApiUris.supportMailApi.support, request);
  },
};
