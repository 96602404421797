import React, { useEffect } from "react";
import { useYear } from "../common/hooks";
import { MenuItem, Select } from "@mui/material";
import { MenuProps } from "../pages/admin/FleetList/styledrop";
import { AdminPageStyle } from "../pages/admin/style";

export const YearSelect = ({ yearValue, handleYearChange, setYear, width }) => {
  const { year, getYearApi } = useYear();
  const classes = AdminPageStyle();
  useEffect(() => {
    getYearApi();
  }, []);
  useEffect(() => {
    // setYear(year);
  }, [year]);
  return (
    <Select
      size="small"
      variant="standard"
      className={classes.textinput}
      sx={{ width: width ? width : "148px !important" }}
      onChange={handleYearChange}
      value={yearValue}
      InputLabelProps={{
        shrink: false,
      }}
      MenuProps={MenuProps}
    >
      {year &&
        year?.length > 0 &&
        year?.map((item) => {
          return (
            <MenuItem key={item.id} value={item?.id}>
              {item.year}
            </MenuItem>
          );
        })}
    </Select>
  );
};
