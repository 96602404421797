import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  createFilterOptions,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdminPageStyle } from "../../pages/admin/style";
import { useForm } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuProps, StyledPopper } from "../../pages/admin/FleetList/styledrop";
import { CommonSelect } from "../CommonSelect";

export const NewVehicleInfo = ({
  yearValue,
  setYearValue,
  year,
  setMakeValue,
  setModelValueData,
  makeValue,
  make,
  modelValueData,
  modelData,
  setMpgValue,
  mpgValue,
  paymentAmount,
  setPaymentAmount,
  fundValue,
  setFundType,
  setFundValue,
  fundList,
  newCostValue,
  setNewCostValue,
  newResaleValue,
  setNewResaleValue,
  setYearFalse,
  fuelList,
  fuelId,
  setFuelId,
  editData,
  setNewAdd,
}) => {
  const classes = AdminPageStyle();
  const filter = createFilterOptions();
  const { register, handleSubmit, reset, setValue } = useForm({});

  const handleYearChange = (event) => {
    let data = event.target.value;
    if (data) {
      setYearValue(data);
    }
  };
  const handleMakeChange = (event, values) => {
    const id = values?.id;
    if (id) {
      setMakeValue({ id: values?.id, name: values?.name });
    } else {
      setMakeValue();
      setModelValueData();
    }
  };
  const handleModelChange = (event, value) => {
    const dataValue = value?.modelId;
    console.log("dataValue", dataValue);
    const dataValueModel = value?.model;
    let filterdData;
    if (editData && editData?.modelId == dataValue) {
      filterdData = editData;
    } else {
      filterdData = modelData?.filter(
        (model) => model?.modelId === dataValue
      )?.[0];
    }
    if (dataValue && dataValueModel && dataValue != "Add") {
      setModelValueData({ modelId: dataValue, model: dataValueModel });
      setMpgValue(filterdData?.mpg);
    } else if (dataValueModel && dataValue == "Add") {
      let lengthModel =
        modelData && modelData?.length > 0 ? modelData[0]?.totalCount : 0;
      setModelValueData({ modelId: lengthModel + 1, model: dataValueModel });
      setMpgValue(0);
      setNewAdd("Add");
    } else {
      setModelValueData();
    }
  };
  const handleFuelChange = (event) => {
    let data = event.target.value;
    setFuelId(data);
  };

  const handleNewMPG = (e) => {
    if (e.target.value) {
      setMpgValue(Number(e.target.value));
    } else {
      setMpgValue(null);
    }
  };

  const handlePaymentAmount = (event) => {
    setPaymentAmount(event.target.value);
    setValue("paymentAmount", event.target.value);
  };

  const handleFundChange = (event, values) => {
    const id = values?.id;
    setFundType(values);
    if (id) {
      setFundValue({ id: id, fundingType: values?.fundingType });
    }
  };

  const handleCost = (event) => {
    setNewCostValue(event.target.value);
    setValue("newVehicleCost", event.target.value);
    setYearFalse(false);
  };

  const handleChange = (event) => {
    setNewResaleValue(event.target.value);
    setValue("newEstimatedResaleValue", event.target.value);
    setYearFalse(false);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          new vehicle information
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  New vehicle Year
                </span>
                <Select
                  size="small"
                  variant="standard"
                  className={classes.textinput}
                  sx={{ width: "148px !important" }}
                  value={yearValue ? yearValue : null}
                  onChange={handleYearChange}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  MenuProps={MenuProps}
                >
                  {year &&
                    year?.length > 0 &&
                    year?.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item?.id}>
                          {item.year}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  New vehicle Make
                </span>
                <Autocomplete
                  id="combo-box-demo"
                  disablePortal
                  value={makeValue ? makeValue : null}
                  className={classes.textinput}
                  sx={{ width: "148px !important" }}
                  onChange={handleMakeChange}
                  options={make}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params, option) => (
                    <TextField {...params} variant="standard" />
                  )}
                  PopperComponent={(props) => <StyledPopper {...props} />}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  New vehicle Model
                </span>
                <Autocomplete
                  id="combo-box-demo"
                  disablePortal
                  disabled={makeValue?.id && yearValue ? false : true}
                  value={modelValueData ? modelValueData : null}
                  className={classes.textinput}
                  sx={{ width: "148px !important" }}
                  onChange={handleModelChange}
                  options={modelData}
                  getOptionLabel={(option) => option.model}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.model
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        modelId: "Add",
                        model: inputValue,
                      });
                    }
                    return filtered;
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.modelId}>
                        {option.model}
                      </li>
                    );
                  }}
                  renderInput={(params, option) => (
                    <TextField {...params} variant="standard" />
                  )}
                  PopperComponent={(props) => <StyledPopper {...props} />}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>Fuel</span>
                <CommonSelect
                  className={classes.textinput}
                  handleChange={handleFuelChange}
                  menuProp={MenuProps}
                  option={fuelList}
                  value={fuelId}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  New Vehicle MPG
                </span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  value={mpgValue}
                  className={classes.textinput}
                  {...register("mpg", { valueAsNumber: true })}
                  onChange={handleNewMPG}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Payment Amount
                </span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  value={paymentAmount}
                  className={classes.textinput}
                  {...register("paymentAmount", { valueAsNumber: true })}
                  onChange={handlePaymentAmount}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Funding Type
                </span>
                <Autocomplete
                  id="combo-box-demo"
                  disablePortal
                  value={fundValue ? fundValue : null}
                  className={classes.textinput}
                  sx={{ width: "148px !important" }}
                  onChange={handleFundChange}
                  options={fundList}
                  getOptionLabel={(option) => option.fundingType}
                  renderInput={(params, option) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  New Vehicle Cost
                </span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  value={newCostValue}
                  onChange={handleCost}
                  className={classes.textinput}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  New Vehicle Estimated Resale Value
                </span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  value={newResaleValue}
                  type="number"
                  onChange={handleChange}
                  className={classes.textinput}
                />
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
