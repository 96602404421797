import React from "react";
import OTPInput from "../../components/OtpInput";
import { useForm } from "react-hook-form";
import { LoginStyle } from "./style";
import { useAuth } from "../../common/hooks";
import BouncingDotsLoader from "../../components/DotLoading";

export const OtpScreen = () => {
  const classes = LoginStyle();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { onVerifyOtp, loading } = useAuth();

  const handleVerifyOtp = (event) => {
    const otp = event?.otp;
    if (otp) {
      onVerifyOtp(otp);
    }

    // await onLoggedIn({ ...payload });
  };
  return (
    <>
      <video autoPlay loop muted className={classes.backgroundVideo}>
        <source src="/login-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={classes.loginPage}>
        <div className={classes.FormDiv}>
          <div className={classes.form}>
            <form class="login-form" onSubmit={handleSubmit(handleVerifyOtp)}>
              <img
                src="/whitelogo.png"
                width={"160px"}
                alt="logo"
                style={{ padding: 2, margin: "5px 0px" }}
              />
              <OTPInput
                label="OTP"
                name="otp"
                setValue={setValue}
                register={register}
                validationSchema={{ required: true }}
                errors={errors}
                required
              />
              <button type="submit" className={classes.formButton}>
                {loading ? <BouncingDotsLoader /> : "Verify Otp"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
