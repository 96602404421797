import { Box, Button, Stack } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { maintenanceAnalysisColumnData } from "../../common/constants/gridRowColumn";
import {
  useCompany,
  useDealer,
  useMaintainance,
  useNewVehicle,
  useUser,
} from "../../common/hooks";
import { SearchBar } from "../../components/SearchBar";
import { layoutStyle } from "../layout-ui/style";
import { DeleteDialog } from "../../components/DeleteDialog";
import { TruckLoader } from "./truckLoader";
import { useDebounce } from "../../components/useDebounce";
import {
  actionColumnFunction,
  checkFieldColumn,
  checkbox,
  getDefaultColDef,
  sidebarMenu,
} from "../../common/constants/commonFunction";
import { getCompanyId } from "../../common/constants/commonFunction";
import { TAB_CONTENT, TAB_VALUE } from "../../common/constants/popupMessage";

export const MaintenanceAnalysis = () => {
  const classes = layoutStyle();
  const [columnFinal, setColumnFinal] = useState([]);
  const [rowDataFinal, setRowDataFinal] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  // const [deleteValue, setDeleteValue] = useState();
  // const { state } = useLocation();
  const navigation = useNavigate();
  const {
    getMaintananceApi,
    maintanance,
    // deleteMaintananceByIdApi,
    loading,
    setMaintanance,
  } = useMaintainance();
  const { setSelectedNav } = useUser();
  const { companyNameHeader, dealerNameHeader } = useCompany();
  const userType = localStorage.getItem("type");
  const defaultColDef = useMemo(() => getDefaultColDef(), []);
  const { setNewFleetNo } = useDealer();
  const { setToastClose } = useNewVehicle();

  useEffect(() => {
    setNewFleetNo(null);
    setToastClose(false);
  }, []);
  const handleAddButton = () => {
    navigation("/new-fleetlist", {
      state: {
        tabValue: TAB_VALUE.FLEET_LIST,
        selectedContent: TAB_CONTENT.FLEET_LIST,
      },
    });
  };
  const onCellClicked = (event) => {
    let checkField = checkFieldColumn(event);
    if (checkField) {
      handleEditButton(event?.data);
    }
  };
  const handleEditButton = (params) => {
    navigation("/new-fleetlist", {
      state: {
        tabValue: TAB_VALUE.MAINTENANCE_ANALYSIS,
        selectedContent: TAB_CONTENT.MAINTENANCE_ANALYSIS,
        data: params,
      },
    });
  };

  // const handleDeleteButton = async (values) => {
  //   setDeleteOpen(true);
  //   setDeleteValue(values);
  // };

  // const handleDelete = async () => {
  //   const companyId = getCompanyId(userType, companyNameHeader);
  //   let payload = {
  //     id: deleteValue,
  //     companyId: companyId,
  //   };
  //   await deleteMaintananceByIdApi(payload);
  //   setDeleteOpen(false);
  // };

  // const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };
  const handleClickSearch = (event) => {
    // setAnchorElSearch(event.currentTarget);
  };

  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };
  const searchData = rowDataFinal?.filter((maintanace) =>
    maintanace?.fleetNo?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const debouncedSearch = useDebounce(onSearchFunc, 500);

  useEffect(() => {
    if (searchValue) {
      setRowDataFinal([...searchData]);
    } else if (maintanance?.length > 0) {
      setRowDataFinal([...maintanance]);
    }
  }, [searchValue, maintanance]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (maintanance?.length > 0) {
      setRowDataFinal(maintanance);
    } else {
      setRowDataFinal([]);
    }
  }, [maintanance]);
  useEffect(() => {
    if (companyNameHeader) {
      const companyId = getCompanyId(userType, companyNameHeader);
      getMaintananceApi({
        companyId: companyId,
        dealerId: dealerNameHeader?.id,
      });
    } else {
      setMaintanance([]);
    }
  }, [companyNameHeader]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (maintenanceAnalysisColumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        deleteButton: false,
      });
      setColumnFinal([
        checkbox,
        ...maintenanceAnalysisColumnData,
        actionColumn,
      ]);
    }
  }, [maintenanceAnalysisColumnData]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleNext = (event) => {
    setSelectedNav(sidebarMenu.fuel);
    navigation("/fuel-analysis");
  };
  const handleBack = () => {
    setSelectedNav(sidebarMenu.replacement);
    navigation("/replacement-schedule");
  };
  return (
    <Box className={classes.mainContent}>
      <Box className={classes.gridContantFleetList}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Box className={classes.headerNameText}>Maintenance Analysis</Box>
          <Box className={classes.addEditGrid}>
            <Button
              variant="outlined"
              className={classes.fleetListNewButton}
              onClick={handleBack}
            >
              <span style={{ fontSize: 12 }}>Back</span>
            </Button>
            <Button
              variant="outlined"
              className={classes.fleetListNewButton}
              onClick={handleNext}
            >
              <span style={{ fontSize: 12 }}>Next</span>
            </Button>
            <Button
              variant="outlined"
              className={classes.fleetListNewButton}
              onClick={handleAddButton}
            >
              NEW
            </Button>
            <SearchBar onSearch={onSearch} />
          </Box>
        </Stack>
        {loading ? (
          <TruckLoader />
        ) : (
          <AgGridReact
            className="ag-theme-quartz"
            rowSelection="multiple"
            onSelectionChanged={(event) => {
              const selectedNodes = event.api.getSelectedNodes();
              const selectedRows = selectedNodes.map((node) => node.data);
              setSelectedRows(selectedRows);
            }}
            suppressRowClickSelection
            pagination={true}
            rowData={rowDataFinal}
            defaultColDef={defaultColDef}
            checkboxSelection
            columnDefs={columnFinal}
            onCellClicked={onCellClicked}
          />
        )}
      </Box>
      {deleteOpen && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={() => setDeleteOpen(false)}
          // deleteApi={() => handleDelete()}
        />
      )}
    </Box>
  );
};
