import { deleteData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Dealer Company Api method declarations
 */
export const dealerCompanyApi = {
  getDealerCompanyList(request) {
    return postData(ApiUris.dealerCompanyApi.getDealerCompanyList, request);
  },
  createDealerCompany(request) {
    return postData(ApiUris.dealerCompanyApi.addDealerCompany, request);
  },
  getDealerCompany(request) {
    return postData(ApiUris.dealerCompanyApi.getDealerCompany, request);
  },
  deleteDealerCompany(request) {
    return deleteData(
      `${ApiUris.dealerCompanyApi.deleteDealerCompany}?id=${request}`
    );
  },
  editDealerCompany(request) {
    return postData(`${ApiUris.dealerCompanyApi.editDealerCompany}`, request);
  },
};
