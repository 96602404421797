import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

export const fuelEconomyApi = {
  getFuelEconomyList(request) {
    return postData(ApiUris.fuelEconomyApi.getFuelEconomyList, request);
  },
  getFuelEconomyById(id) {
    return getData(`${ApiUris.fuelEconomyApi.getFuelEconomyById}/${id}`);
  },
  createFuelEconomy(request) {
    return postData(ApiUris.fuelEconomyApi.addFuelEconomy, request);
  },
  updateFuelEconomy(request) {
    return postData(ApiUris.fuelEconomyApi.updateFuelEconomy, request);
  },
  deleteFuelEconomy(id) {
    return deleteData(`${ApiUris.fuelEconomyApi.deleteFuelEconomy}?id=${id}`);
  },
  createMultipleFuelEconomy(request) {
    return postData(ApiUris.fuelEconomyApi.fuelEconomyCreateMultiple, request);
  },
};
