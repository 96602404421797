import { useState, useEffect } from "react";

const useFuelStatistics = (fuelListFilter, fuelAverageMileageCache) => {
  const [anticipatedMpgValue, setAnticipatedMpgValue] = useState(0);
  const [totalFuelSpend, setTotalFuelSpend] = useState(0);
  const [annualSpendVehicle, setAnnualSpendVehicle] = useState(0);
  const [monthlySpendVehicle, setMonthlySpendVehicle] = useState(0);
  const [foreCastedValue, setForeCastedValue] = useState(0);
  const [currentFuelSize, setCurrentFuelSize] = useState(0);
  const [averageFuelGallon, setAverageFuelGallon] = useState(0);
  const [cycleFuelYearCost, setCycleFuelYearCost] = useState(0);
  const [cycleFuelYearCost2, setCycleFuelYearCost2] = useState(0);
  const [cycleFuelYearCost3, setCycleFuelYearCost3] = useState(0);
  const [cycleFuelYearCost4, setCycleFuelYearCost4] = useState(0);
  const [cycleFuelYearCost5, setCycleFuelYearCost5] = useState(0);
  const [fuelAverageMileage, setFuelAverageMileage] = useState(0);
  const [currentYearTotalCost, setCurrentYearTotalCost] = useState(0);

  useEffect(() => {
    if (fuelListFilter?.length > 0) {
      let sum = 0;
      let totalCost = 0;
      let averageGallon = 0;
      let cycleFuelYearSum = 0;
      let cycleFuelYearSum2 = 0;
      let cycleFuelYearSum3 = 0;
      let cycleFuelYearSum4 = 0;
      let cycleFuelYearSum5 = 0;
      let forecastedSum = 0;
      let annualCurrent = 0;
      let spendAnnualCurrent = 0;
      let monthlySpendAnaly = 0;
      let anticipatedCostCurrentPrice = 0;

      fuelListFilter.forEach((item) => {
        sum += Number(item["originalFuelEconomy"] || 0);
        averageGallon += Number(item["averageFuelPrice"] || 0);
        cycleFuelYearSum += Number(item["anticipatedFuelCostYear1"] || 0);
        cycleFuelYearSum2 += Number(item["anticipatedFuelCostYear2"] || 0);
        cycleFuelYearSum3 += Number(item["anticipatedFuelCostYear3"] || 0);
        cycleFuelYearSum4 += Number(item["anticipatedFuelCostYear4"] || 0);
        cycleFuelYearSum5 += Number(item["anticipatedFuelCostYear5"] || 0);
        forecastedSum += Number(item["annualFuelSpendForecasted"] || 0);
        annualCurrent += Number(item["annualFuelCostCurrent"] || 0);
        anticipatedCostCurrentPrice += Number(
          item["anticipatedFuelCostCurrent"] || 0
        );
      });

      if (cycleFuelYearSum > 0) {
        cycleFuelYearSum = cycleFuelYearSum / fuelListFilter.length;
      }
      setAnticipatedMpgValue(anticipatedCostCurrentPrice);

      if (sum > 0) {
        totalCost = sum / fuelListFilter.length;
      }

      if (annualCurrent > 0) {
        spendAnnualCurrent = annualCurrent / fuelListFilter.length;
      }

      if (averageGallon > 0) {
        averageGallon = averageGallon / fuelListFilter.length;
      }

      if (fuelAverageMileageCache > 0) {
        let mileage = fuelAverageMileageCache / fuelListFilter.length;
        setFuelAverageMileage(mileage);
      }

      if (spendAnnualCurrent > 0) {
        monthlySpendAnaly = spendAnnualCurrent / 12;
      }

      setTotalFuelSpend(annualCurrent);
      setAnnualSpendVehicle(spendAnnualCurrent);
      setMonthlySpendVehicle(monthlySpendAnaly);
      setForeCastedValue(forecastedSum);
      setCurrentFuelSize(fuelListFilter.length);
      setCurrentYearTotalCost(totalCost);
      setAverageFuelGallon(averageGallon);
      setCycleFuelYearCost(cycleFuelYearSum);
      setCycleFuelYearCost2(cycleFuelYearSum2);
      setCycleFuelYearCost3(cycleFuelYearSum3);
      setCycleFuelYearCost4(cycleFuelYearSum4);
      setCycleFuelYearCost5(cycleFuelYearSum5);
    }
  }, [fuelAverageMileageCache, fuelListFilter]);

  return {
    anticipatedMpgValue,
    totalFuelSpend,
    annualSpendVehicle,
    monthlySpendVehicle,
    foreCastedValue,
    currentFuelSize,
    averageFuelGallon,
    cycleFuelYearCost,
    cycleFuelYearCost2,
    cycleFuelYearCost3,
    cycleFuelYearCost4,
    cycleFuelYearCost5,
    fuelAverageMileage,
    currentYearTotalCost,
  };
};

export default useFuelStatistics;
