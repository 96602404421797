import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, Grid, InputLabel, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useMake } from "../../common/hooks";
import { layoutStyle } from "../../pages/layout-ui/style";
import { useEffect } from "react";
import readXlsxFile from "read-excel-file";
import { useState } from "react";

export default function AddMake({ open, title, data, handleClose }) {
  const classes = layoutStyle();
  const { createMakeApi, editMakeApi, makeById } = useMake();
  // const [XLSXData, setXLSXData] = useState();
  // const handleOnChange = (e) => {
  //   let rowDataName = [];
  //   const input = document.getElementById("input");
  //   readXlsxFile(input.files[0]).then((rows) => {
  //     rows?.map(async (items) => {
  //       rowDataName.push(items);
  //     });
  //     setXLSXData(rowDataName);
  //   });
  // };
  // const addXLSXData = async () => {
  //   let dataUpload = [];
  //   XLSXData?.map(async (items) => {
  //     let payload = {
  //       name: items[0] == null ? "" : items[0],
  //       // address: items[2] == null ? "" : items[2],
  //       //  make: items[1] == null ? "" : items[1],
  //       // location: items[3] == null ? "" : items[3],
  //       // status: items[4] == "Status" ? true : false,
  //       // region: items[5] == null ? "" : items[5],
  //     };
  //     await createMakeApi(payload);
  //     dataUpload.push(payload);
  //   });
  // };
  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
    setValue,
  } = useForm({
    defaultValues: data && data.name ? { name: data?.name } : { name: "" },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    // resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (data) {
      setValue("name", data?.name);
    } else {
      setValue("name", "");
    }
  }, [data]);

  const onSubmit = async (values) => {
    const payload = {
      ...values,
    };
    if (data?.id) {
      const editPayload = {
        ...values,
        id: data?.id,
      };
      editMakeApi(editPayload);
    } else {
      createMakeApi(payload);
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <Drawer
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <DialogTitle
          className={classes.dailogTitle}
          id="customized-dialog-title"
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* <div>
            <Button
              className={[classes.fleetListNewButton, classes.fontSize12]}
              onClick={addXLSXData}
            >
              Upload
            </Button>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <label htmlFor="input">
                <Button
                  variant="contained"
                  className={[classes.fleetListNewButton, classes.fontSize12]}
                  component="span"
                >
                  Choose File
                </Button>
              </label>
              <input
                type="file"
                onChange={handleOnChange}
                id="input"
                style={{ display: "none" }}
              />
            </Box>
          </div> */}
          <Grid container className={classes.disFlexCenter}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid className={classes.companyForm}>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Name</InputLabel>
                  <TextField
                    error={errors?.name}
                    className={classes.fontSize12}
                    type="text"
                    name="name"
                    fullWidth
                    {...register("name")}
                  />
                </Grid>
                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.fleetListNewButton}
                  autoFocus
                  sx={{ mt: 3, height: "50px" }}
                >
                  {title}
                </Button>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
}
