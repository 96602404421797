import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Fund List Api method declarations
 */
export const fundListApi = {
  getFundList(request) {
    return postData(ApiUris.fundListApi.getFundList, request);
  },
  getFundListByid(id) {
    return getData(`${ApiUris.fundListApi.deleteFundListById}/${id}`);
  },
  createFundList(request) {
    return postData(ApiUris.fundListApi.addFundList, request);
  },
  editFundtList(request) {
    return postData(ApiUris.fundListApi.updateFundtList, request);
  },
  deleteFundListByid(id) {
    return deleteData(`${ApiUris.fundListApi.deleteFundListById}?id=${id}`);
  },
};
