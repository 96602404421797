import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Maintainance Api method declarations
 */
export const maintainanceListApi = {
  getMaintainanceList(request) {
    return postData(ApiUris.maintainanceListApi.getMaintainanceList, request);
  },
  getMaintainanceListByid(id) {
    return getData(`${ApiUris.maintainanceListApi.getMaintainanceById}/${id}`);
  },
  //   deleteFleetListByid(id) {
  //     return deleteData(`${ApiUris.fleetListApi.}?id=${id}`);
  //   },
  createMaintainanceList(request) {
    return postData(ApiUris.maintainanceListApi.addMaintainanceList, request);
  },
  editMaintainanceList(request) {
    return postData(
      ApiUris.maintainanceListApi.updateMaintainanceList,
      request
    );
  },
  deleteMaintainanceList(id) {
    return deleteData(`${ApiUris.maintainanceListApi.deleteMaintainanceList}?id=${id}`);
  },
};
