import React from "react";
import { layoutStyle } from "../layout-ui/style";
import { Grid, TextField, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUser } from "../../common/hooks/user";
import { USER_CONTROL } from "../../common/constants/popupMessage";

const AddUser = () => {
  const classes = layoutStyle();
  const navigation = useNavigate();
  const { register, handleSubmit } = useForm();

  const { createUser, getUser } = useUser();
  const handleSaveUser = async (values) => {
    const payload = {
      name: values.name,
      email: values.email,
      password: values.password,
      phone: values.phone,
      type: values.type,
      userName: values.username,
      Deleted: false,
      status: USER_CONTROL.ACTIVATE,
    };

    await createUser(payload);
    await getUser();

    navigation("/manage-user");
  };
  return (
    <Box className={classes.mainContent}>
      <Box sx={{ boxShadow: 2, p: 2 }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <h3>Add User</h3>
          <Button
            className={[classes.fleetListNewButton, classes.fontSize12]}
            onClick={handleSubmit(handleSaveUser)}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Grid
        container
        sx={{ boxShadow: 2, px: 2, py: 3, pb: 5, height: "450px" }}
      >
        <form>
          <Grid
            container
            lg={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item lg={4}>
              <TextField
                style={{
                  width: "90%",
                  margin: "5px",
                  fontSize: "12px !important",
                }}
                type="text"
                label="Name"
                color="success"
                variant="standard"
                InputLabelProps={{ style: { fontSize: "12px" } }}
                {...register("name")}
              />

              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Email"
                color="success"
                variant="standard"
                InputLabelProps={{ style: { fontSize: "12px" } }}
                {...register("email")}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Username"
                color="success"
                variant="standard"
                InputLabelProps={{ style: { fontSize: "12px" } }}
                {...register("username")}
              />

              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="password"
                label="Password"
                color="success"
                variant="standard"
                InputLabelProps={{ style: { fontSize: "12px" } }}
                {...register("password")}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Phone"
                color="success"
                variant="standard"
                InputLabelProps={{ style: { fontSize: "12px" } }}
                {...register("phone")}
              />

              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Type"
                color="success"
                variant="standard"
                InputLabelProps={{ style: { fontSize: "12px" } }}
                {...register("type")}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Box>
  );
};

export default AddUser;
