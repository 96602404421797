import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Drawer,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDealer } from "../../common/hooks/dealer-hook";
import { layoutStyle } from "../../pages/layout-ui/style";
import { useCompany, useDealerCompany } from "../../common/hooks";
import { useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const AddDealerCompany = ({ openDealer, setOpenDealer,setRefreshData,editData }) => {
  const classes = layoutStyle();
  // const [refreshData, setRefreshData] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedDealer, setSelectedDealer] = useState("");
  const [page, setPage] = useState({ pageSize: 25, page: 0 });

  const { getDealerApi, dealer, dealerById, editDealerApi } = useDealer();
  const { addDealerCompanyApi,editDealerCompany } = useDealerCompany();
  const { company, getCompanyApi } = useCompany();

  const handleClose = () => {
    setOpenDealer(false);
    setSelectedCompany(false);
    setSelectedDealer(false);
    reset();
  };

  useEffect(() => {
    getCompanyApi({ limit: page.pageSize, offset: page.page });
    getDealerApi({ limit: page.pageSize, offset: page.page });
  }, []);

  useEffect(() => {
    if(editData){
      setSelectedCompany(editData.companyId)
      setSelectedDealer(editData.dealerId) 
    }
  },[editData])

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const onSubmit = async () => {
    if(editData){
      const payload = {
        id:editData.dealerCompanyId,
        companyId: selectedCompany,
        dealerId: selectedDealer,
        deleted: false,
      };
     await editDealerCompany(payload);
      setRefreshData(true);
      handleClose();
    }else{
    const payload = {
      companyId: selectedCompany,
      dealerId: selectedDealer,
      deleted: false,
    };
    await addDealerCompanyApi(payload);
    setRefreshData(true);
    handleClose();
  }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };
  const handleDealerChange = (event) => {
    setSelectedDealer(event.target.value);
  };
  const onInvalid = (errors) => console.error(errors);

  return (
    <Grid container justifyContent="center">
      <React.Fragment>
        <Drawer
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openDealer}
          maxWidth="sm"
          anchor="bottom"
          fullHeight
          style={{ top: "100px ", bottom: "56px !important" }}
          className="DrawerName"
        >
          <DialogTitle
            className={classes.dailogTitle}
            id="customized-dialog-title"
          >
            {dealerById ? "Edit Assign Company" : "Assign Company"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container className={classes.disFlexCenter}>
              <form onSubmit={handleSubmit(onSubmit, onInvalid)} style={{width:"40%"}}>
                <Grid container className={classes.companyForm}>
                  <Grid className={classes.compnayFormField}>
                    <InputLabel className={classes.fontSize12}>
                      Company Name
                    </InputLabel>
                    <Select
                      value={selectedCompany}
                      onChange={handleCompanyChange}
                      fullWidth
                    >
                      {company?.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company?.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid className={classes.compnayFormField}>
                    <InputLabel className={classes.fontSize12}>
                      Dealer Name
                    </InputLabel>
                    <Select
                      value={selectedDealer}
                      onChange={handleDealerChange}
                      fullWidth
                    >
                      {dealer?.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.dealerName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Button
                    variant="outlined"
                    type="submit"
                    className={classes.fleetListNewButton}
                    autoFocus
                    fullWidth
                    sx={{ mt: 3,padding:"10px 20px" }}
                  >
                    save
                  </Button>
                </Grid>
              </form>
            </Grid>
          </DialogContent>
        </Drawer>
      </React.Fragment>
    </Grid>
  );
};
