import { ErrorFields } from "./ErrorFields";

export const FindGridNullValue = (rowDataFinal) => {
  let emptyArray = [];
  rowDataFinal?.map((items) => {
    if (checkNullValue(items[checkFields(items)])) {
      const dataField = checkFields(items);
      dataField?.map((item) => {
        if (
          item === ErrorFields.newYear &&
          checkNullValue(items[ErrorFields.newYear])
        ) {
          emptyArray.push({
            [ErrorFields.newYear]: items[ErrorFields.newYear],
          });
        }
        if (
          item === ErrorFields.newModel &&
          checkNullValue(items[ErrorFields.newModel])
        ) {
          emptyArray.push({
            [ErrorFields.newModel]: items[ErrorFields.newModel],
          });
        }
        if (
          item === ErrorFields.newMake &&
          checkNullValue(items[ErrorFields.newMake])
        ) {
          emptyArray.push({
            [ErrorFields.newMake]: items[ErrorFields.newMake],
          });
        }
        if (
          item === ErrorFields.newMpg &&
          checkNullValue(items[ErrorFields.newMpg])
        ) {
          emptyArray.push({ [ErrorFields.newMpg]: items[ErrorFields.newMpg] });
        }
        if (
          item === ErrorFields.paymentAmount &&
          checkNullValue(items[ErrorFields.paymentAmount])
        ) {
          emptyArray.push({
            [ErrorFields.paymentAmount]: items[ErrorFields.paymentAmount],
          });
        }
        if (
          item === ErrorFields.fundingType &&
          checkNullValue(items[ErrorFields.fundingType])
        ) {
          emptyArray.push({
            [ErrorFields.fundingType]: items[ErrorFields.fundingType],
          });
        }
        if (
          item === ErrorFields.newVehicleCost &&
          checkNullValue(items[ErrorFields.newVehicleCost])
        ) {
          emptyArray.push({
            [ErrorFields.newVehicleCost]: items[ErrorFields.newVehicleCost],
          });
        }
        if (
          item === ErrorFields.newEstimatedResaleValue &&
          checkNullValue(items[ErrorFields.newEstimatedResaleValue])
        ) {
          emptyArray.push({
            [ErrorFields.newEstimatedResaleValue]:
              items[ErrorFields.newEstimatedResaleValue],
          });
        }
        if (
          item === ErrorFields.residualPercentage &&
          checkNullValue(items[ErrorFields.residualPercentage])
        ) {
          emptyArray.push({
            [ErrorFields.residualPercentage]:
              items[ErrorFields.residualPercentage],
          });
        }
        if (
          item === ErrorFields.make &&
          checkNullValue(items[ErrorFields.make])
        ) {
          emptyArray.push({ [ErrorFields.make]: items[ErrorFields.make] });
        }
        if (
          item === ErrorFields.model &&
          checkNullValue(items[ErrorFields.model])
        ) {
          emptyArray.push({ [ErrorFields.model]: items[ErrorFields.model] });
        }
        if (
          item === ErrorFields.year &&
          checkNullValue(items[ErrorFields.year])
        ) {
          emptyArray.push({ [ErrorFields.year]: items[ErrorFields.year] });
        }
        if (
          item === ErrorFields.fuel &&
          checkNullValue(items[ErrorFields.fuel])
        ) {
          emptyArray.push({ [ErrorFields.fuel]: items[ErrorFields.fuel] });
        }
        if (
          item === ErrorFields.driveTrain &&
          checkNullValue(items[ErrorFields.driveTrain])
        ) {
          emptyArray.push({
            [ErrorFields.driveTrain]: items[ErrorFields.driveTrain],
          });
        }
        if (
          item === ErrorFields.type &&
          checkNullValue(items[ErrorFields.type])
        ) {
          emptyArray.push({ [ErrorFields.type]: items[ErrorFields.type] });
        }
      });
    }
  });
  return emptyArray;
};

export const checkFields = (fields) => {
  if (Object.keys(fields)?.length > 0) {
    return Object.keys(fields);
  }
};

export const uniqueNullValues = (rowDataFinal) => {
  const nullValues = FindGridNullValue(rowDataFinal);
  const uniqueKeys = [...new Set(nullValues.flatMap(Object.keys))];
  if (uniqueKeys?.length > 0) {
    return uniqueKeys;
  } else {
    return "";
  }
};
export const uniqueVehicleNullValues = (rowDataFinal) => {
  const nullValues = FindGridNullValue(rowDataFinal);
  const uniqueKeys = [...new Set(nullValues.flatMap(Object.keys))];
  if (uniqueKeys?.length > 0) {
    return uniqueKeys;
  } else {
    return "";
  }
};
export const checkNullValue = (item) => {
  return item == null || item == "" || item == undefined || item == 0
    ? true
    : false;
};

export const getNullVehicleFields = (fields) => {
  if (fields?.length > 0) {
    const jsonObject = Object.assign(
      {},
      ...fields?.map((key) => ({ [key]: undefined }))
    );
    const keys = Object.keys(jsonObject);
    const keysString = keys.join(", ");
    return keysString;
  }
};
