import React, { useState } from "react";
import {
  TextField,
  Stack,
  FormLabel,
  Typography,
  FormHelperText,
} from "@mui/material";

const OTPInput = ({
  label,
  name,
  length = 6,
  setValue,
  register,
  validationSchema,
  errors,
  required,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (setValue) {
      setValue(name, newOtp.join(""));
    }

    // Move to next input field if filled
    if (value && index < length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData("text");
    if (isNaN(paste)) return;

    const pasteArray = paste.split("").slice(0, length);
    const newOtp = [...otp];

    pasteArray.forEach((char, idx) => {
      newOtp[idx] = char;
    });

    setOtp(newOtp);

    for (let i = 0; i < newOtp.length; i++) {
      let element = newOtp[i];
      setValue(`otp-input-${i}`, element);
    }

    if (setValue) {
      setValue(name, newOtp.join(""));
    }

    // Focus the last filled input
    const lastFilledIndex = pasteArray.length - 1;
    if (lastFilledIndex < length - 1) {
      document.getElementById(`otp-input-${lastFilledIndex}`).focus();
    }
  };

  return (
    <>
      <FormLabel
        component="legend"
        sx={{ mb: 0.3, fontSize: "12.5px", color: "#ffffff", fontWeight: 500 }}
      >
        {label}{" "}
        {required && (
          <Typography
            component="span"
            color="error"
            ml={0.2}
            sx={{ lineHeight: "normal" }}
          >
            *
          </Typography>
        )}
      </FormLabel>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-around",
          alignItems: "center",
          margin: "20px 5px",
        }}
        spacing={2}
      >
        {otp.map((data, index) => (
          <TextField
            key={index}
            id={`otp-input-${index}`}
            type="text"
            name={`otp-input-${index}`}
            {...register(`otp-input-${index}`, validationSchema)}
            inputProps={{ maxLength: 1 }}
            value={data}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onFocus={(e) => e.target.select()}
            onPaste={handlePaste}
            error={
              errors[`otp-input-${index}`] && !!errors[`otp-input-${index}`]
            }
            sx={{
              textAlign: "center",
              "& .MuiInputBase-input": {
                textAlign: "center",
                fontSize: "1.5rem",
                width: "30px",
                color: "#000000",
              },
              ".MuiOutlinedInput-root": {
                backgroundColor: "#ffffff",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#ffffff",
              },
            }}
          />
        ))}
      </Stack>
      {(errors["otp-input-0"] ||
        errors["otp-input-1"] ||
        errors["otp-input-2"] ||
        errors["otp-input-3"] ||
        errors["otp-input-4"] ||
        errors["otp-input-5"]) && (
        <FormHelperText error>Otp is required.</FormHelperText>
      )}
    </>
  );
};

export default OTPInput;
