import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
import { AdminPageStyle } from "../../pages/admin/style";

export const AnticipateAnnualFuelEconomy = ({
  currentYear,
  firstYear,
  secondYear,
  thirdYear,
  fourthYear,
  fifthYear,
}) => {
  const classes = AdminPageStyle();

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          anticipated annual fuel economy
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Current</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {currentYear?.toFixed(1)}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year 1</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {firstYear}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year 2</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {secondYear}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year 3</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {thirdYear}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year 4</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {fourthYear}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year 5</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {fifthYear}
                </span>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
