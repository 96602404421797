import { deleteData, getData, postData } from "./base-api";
import ApiUris, { baseURL } from "./apiUris";

/**
 * Replacement Schedule Api method declarations
 */
export const replacementScheduleApi = {
  getReplacement(request) {
    return postData(
      ApiUris.replacementScheduleListApi.getReplacementList,
      request
    );
  },
  getReplacementByid(id) {
    return getData(
      `${ApiUris.replacementScheduleListApi.getReplacementListById}/${id}`
    );
  },
  deleteReplacementByid(id) {
    return deleteData(
      `${ApiUris.replacementScheduleListApi.deleteReplacementListById}?id=${id}`
    );
  },
  createReplacement(request) {
    return postData(
      ApiUris.replacementScheduleListApi.addReplacementList,
      request
    );
  },
  editReplacement(request) {
    return postData(
      ApiUris.replacementScheduleListApi.updateReplacementtList,
      request
    );
  },
};
