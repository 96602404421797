import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdminPageStyle } from "../../pages/admin/style";
import { addComma } from "../../common/services/number-service";

export const VehicleInfo = ({
  data,
  state,
  fleetList,
  vehicleInfoValue,
  companyNameHeader,
  setData,
  setVehicleInfoValue,
}) => {
  const classes = AdminPageStyle();

  const handleFleetChange = (event, value) => {
    setData(value?.fleetId);
    setVehicleInfoValue(value);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          vehicle info
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Company</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {companyNameHeader?.companyName}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Fleet List
                </span>
                <Autocomplete
                  id="combo-box-demo"
                  disablePortal
                  disabled={state?.data ? true : false}
                  className={classes.textinput}
                  sx={{ width: "148px !important" }}
                  onChange={handleFleetChange}
                  defaultValue={data}
                  options={fleetList}
                  getOptionLabel={(option) => option.fleetNo}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.fleetId}>
                        {option.fleetNo}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {vehicleInfoValue?.year}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Age</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {vehicleInfoValue?.age}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Make</span>
                <span>{vehicleInfoValue?.make}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Modal</span>
                <span>{vehicleInfoValue?.model}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>State</span>
                <span>{vehicleInfoValue?.state}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Vehicle Annual Mileage
                </span>
                {addComma(Math.round(vehicleInfoValue?.annualMileage))}
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Vehicle Total Miles
                </span>
                {addComma(Math.round(vehicleInfoValue?.totalMiles))}
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
