import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * State Api method declarations
 */
export const stateListApi = {
  getStateList(request) {
    return postData(ApiUris.stateListApi.getStateList, request);
  },
  getStateListByid(id) {
    return getData(`${ApiUris.stateListApi.getStateById}/${id}`);
  },
//   deleteFleetListByid(id) {
//     return deleteData(`${ApiUris.fleetListApi.}?id=${id}`);
//   },
  createStateList(request) {
    return postData(ApiUris.stateListApi.addStateList, request);
  },
  editStateList(request) {
    return postData(ApiUris.stateListApi.updateStateList, request);
  },
};
