import { makeStyles } from "@mui/styles";

export const LoginStyle = makeStyles((theme) => {
  return {
    container: {
      backgroundImage: `url("/bgimg.jpg")`,
      backgroundRepeat: "none",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
      position: "relative",
    },

    formContainer: {
      padding: "20px",
      position: "absolute",
      background: "red",
      width: "500px",
      height: "250px",
      float: "right",
      margin: "10px",
      [theme.breakpoints.only("sm")]: {
        "@media screen and (min-width: 520px)": {
          width: "250px ",
          height: "220px ",
          background: "green",
        },
      },
      [theme.breakpoints.only("xs")]: {
        "@media screen and (min-width: 320px)": {
          width: "150px !important",
          height: "120px !important",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        },
      },
    },

    backgroundVideo: {
      width: "100%",
      height: "100%",
      position: "absolute",
      objectFit: "cover",
      transitionTimingFunction: "ease",
      backgroundImage: "Black",
    },

    loginPage: {
      height: "100vh",
      width: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      // background: "red",
      marginRight: "22rem",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url(./bgImg.jpg)`,
    },
    form: {
      position: "relative",
      filter: "drop-shadow(0 0 2px #000000)",
      borderRadius: "5px",
      width: "50%",
      // height: 100,
      backgroundColor: "#0000008a",
      padding: "60px",
    },
    formImg: {
      position: "absolute",
      height: "20px",
      top: "230px",
      right: "60px",
      cursor: "pointer",
    },
    formInput: {
      outline: 0,
      background: "#f2f2f2",
      borderRadius: "4px",
      width: "90%",
      border: 0,
      margin: "15px 0 ",
      padding: "15px",
      fontSize: "14px",
      "&:focus formInput": {
        boxShadow: "0 0 5px 0 rgba(106, 98, 210)",
      },
    },
    span: {
      color: "red",
      margin: "10px 0 ",
      fontSize: "14px",
    },
    formButton: {
      outline: 0,
      background: "#949300",
      width: "100%",
      border: 0,
      marginTop: "10px",
      borderRadius: "3px",
      padding: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
      transition: "all 0.4s ease-in-out",
      cursor: "pointer",
    },

    paragraph: {
      color: "white",
    },

    message: {
      margin: "15px 0",
      textAlign: "center",
    },

    formMessageA: {
      fontSize: "14px",
      color: "white",
      textDecoration: "none",
    },
    FormDiv: {
      // paddingRight: "7px !important",
      marginLeft: "100px !important",
      // opacity: 0.9,
    },
    versionLogin: {
      color: "white",
      textAlign: "center",
    },
  };
});
