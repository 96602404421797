import * as XLSX from "xlsx";

const removeCommas = (str) => str.replace(/,/g, "");

export const csvFileToArray = (string, page) => {
  const rows = string.split("\n");
  let headers;
  if (rows[0].split(";")?.length > 1) {
    headers = rows[0].split(";").map((header) => header.trim()?.toLowerCase());
  } else {
    headers = rows[0].split(",").map((header) => header.trim()?.toLowerCase());
  }

  let dataHeader = [];
  headers?.map((items) => {
    let string = items.replace(/\s/g, "");
    let stringData;
    if (string == "totalmiles") {
      stringData = string.replace("totalmiles", "totalMiles");
    } else if (string == "statecode") {
      stringData = string.replace("statecode", "stateCode");
    } else if (string == "drivetrain") {
      stringData = string.replace("drivetrain", "driveTrain");
    } else if (string == "drivername") {
      stringData = string.replace("drivername", "driverName");
    } else {
      stringData = string;
    }
    dataHeader.push(stringData);
  });

  headers = [...dataHeader];
  const data = [];
  const regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
  for (let i = 1; i < rows.length; i++) {
    let values;
    if (rows[i].split(";")?.length > 1) {
      values = rows[i].split(";");
    } else {
      values = rows[i].split(regex);
    }
    if (values.length === headers.length) {
      if (page === "mpg") {
        const obj = { id: i };
        headers.forEach((header, index) => {
          obj[header] = values[index].trim();
        });
        data.push(obj);
      } else {
        const obj = {};
        headers.forEach((header, index) => {
          let value = values[index].trim().replace(/^"|"$/g, "");
          if (header === "totalMiles") {
            value = removeCommas(value);
          }
          obj[header] = value;
        });
        data.push(obj);
      }
    }
  }
  return { data, headers };
};

export const xlsxFileToArray = (file, page) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      let data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Processing headers
      const headers = json[0].map((header) => {
        let string = header.trim().toLowerCase().replace(/\s/g, "");
        if (string === "totalmiles") {
          return "totalMiles";
        } else if (string === "statecode") {
          return "stateCode";
        } else if (string === "drivetrain") {
          return "driveTrain";
        } else if (string === "drivername") {
          return "driverName";
        }
        return string;
      });

      // Processing data rows

      if (page === "mpg") {
        data = json.slice(1).map((row, index) => {
          const obj = { id: index + 1 };
          headers.forEach((header, i) => {
            obj[header] = row[i] ? row[i].toString().trim() : "";
          });
          return obj;
        });
      } else {
        data = json.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            let value = row[index] ? row[index].toString().trim() : "";
            if (header === "totalMiles") {
              value = removeCommas(value);
            }
            obj[header] = value;
          });
          return obj;
        });
      }
      resolve({ data, headers });
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};
