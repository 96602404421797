import { useState, useEffect } from "react";

const useMaintenanceStatistics = (maintenance) => {
  const [currentTotalAnnualizedSpend, setCurrentTotalAnnualizedSpend] =
    useState(0);
  const [currentYearTotalCost, setCurrentYearTotalCost] = useState(0);
  const [maintenanceYear, setMaintenanceYear] = useState(0);
  const [maintenanceVehicleSpend, setMaintenanceVehicleSpend] = useState(0);
  const [maintenanceVehicleSpendMonthly, setMaintenanceVehicleSpendMonthly] =
    useState(0);
  const [averageCostPerMilesMaintenance, setAverageCostPerMilesMaintenance] =
    useState(0);
  const [avgCostPerMiles, setAvgCostPerMiles] = useState(0);
  const [oneYearTotalCostValue, setOneYearTotalCostValue] = useState(0);
  const [oneYearMaintenanceCost, setOneYearMaintenanceCost] = useState(0);
  const [oneYearMaintenanceTotalMiles, setOneYearMaintenanceTotalMiles] =
    useState(0);

  useEffect(() => {
    if (maintenance?.length > 0) {
      let sum = 0;
      let currentYearTotal = 0;
      let totalMileageValue = 0;
      let oneYearMaintenanceSum = 0;
      let sumCost = 0;
      let oneYearMaintenanceTotalMilesSum = 0;
      let currentYearTotalCost11 = 0;

      maintenance.forEach((item) => {
        sum += Number(item["year"] || 0);
        currentYearTotal += Number(item["currentYearTotalCost"] || 0);
        currentYearTotalCost11 += Number(item["currentYearTotalCost"] || 0);
        totalMileageValue += Number(item["totalMiles"] || 0);
        oneYearMaintenanceSum += Number(item["year_1_TotalCost"] || 0);
        oneYearMaintenanceTotalMilesSum += Number(
          item["year_1_CostPerMile"] || 0
        );
      });

      let sumYear = sum / maintenance.length;
      setCurrentTotalAnnualizedSpend(currentYearTotal);
      setCurrentYearTotalCost(currentYearTotal);
      setMaintenanceYear(sumYear);

      let vehicleSpend = currentYearTotalCost11 / maintenance.length;
      setMaintenanceVehicleSpend(vehicleSpend);

      let monthlySpend = vehicleSpend / 12 || 0;
      setMaintenanceVehicleSpendMonthly(monthlySpend);

      setAverageCostPerMilesMaintenance(totalMileageValue);

      let totalMileageSum = vehicleSpend / totalMileageValue;
      setAvgCostPerMiles(totalMileageSum.toFixed(5));

      if (oneYearMaintenanceSum > 0) {
        sumCost = oneYearMaintenanceSum / maintenance.length;
      }
      setOneYearTotalCostValue(oneYearMaintenanceSum);
      setOneYearMaintenanceCost(sumCost);

      if (oneYearMaintenanceTotalMilesSum > 0) {
        oneYearMaintenanceTotalMilesSum =
          oneYearMaintenanceTotalMilesSum / maintenance.length;
      }
      setOneYearMaintenanceTotalMiles(oneYearMaintenanceTotalMilesSum);
    }
  }, [maintenance]);

  return {
    currentTotalAnnualizedSpend,
    currentYearTotalCost,
    maintenanceYear,
    maintenanceVehicleSpend,
    maintenanceVehicleSpendMonthly,
    averageCostPerMilesMaintenance,
    avgCostPerMiles,
    oneYearTotalCostValue,
    oneYearMaintenanceCost,
    oneYearMaintenanceTotalMiles,
  };
};

export default useMaintenanceStatistics;
