import { Box } from "@mui/material";
import React from "react";

export const TruckLoader = ({ mainContent }) => {
  return (
    <Box class="loader-wrapper">
      <Box class="loader-box">
        <div class="dot-spinner">
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
        </div>
      </Box>
    </Box>
  );
};