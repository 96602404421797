import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  formatDonutChartTooltipValue,
  getFileName,
} from "../../../common/constants/commonFunction";

export const DonutChart = ({ seriesData }) => {
  const [data, setData] = useState([
    Number(seriesData?.Current),
    Number(seriesData?.Year1),
    Number(seriesData?.Year2),
    Number(seriesData?.Year3),
    Number(seriesData?.Year4),
    Number(seriesData?.Year5),
  ]);

  useEffect(() => {
    if (seriesData) {
      setData([
        Number(seriesData?.Current),
        Number(seriesData?.Year1),
        Number(seriesData?.Year2),
        Number(seriesData?.Year3),
        Number(seriesData?.Year4),
        Number(seriesData?.Year5),
      ]);
    }
  }, [seriesData]);
  const options = {
    chart: {
      type: "donut",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: getFileName("FuelDonut_chart"),
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: getFileName("FuelDonut_chart"),
          },
          png: {
            filename: getFileName("FuelDonut_chart"),
          },
        },
        autoSelected: "zoom",
      },
    },
    colors: ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#FF8C33", "#dfc7a7"],
    labels: ["Current", "Year1", "Year2", "Year3", "Year4", "Year5"],
    tooltip: {
      y: {
        formatter: (val) => formatDonutChartTooltipValue(val),
        height: 1,
      },
    },
    legend: {
      position: "bottom",
      offsetY: 0,
      height: 0,
    },
    responsive: [
      {
        // breakpoint: 480,
        options: {
          legend: {
            position: "relative",
          },
        },
      },
    ],
  };
  return <Chart options={options} series={data} type="donut" height={280} />;
};
