import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
import { getCurrencyValue } from "../../common/services/number-service";
import { AdminPageStyle } from "../../pages/admin/style";

export const AnnualFuelCost = ({
  annualCostCurrent,
  editData,
  fuelYear1,
  fuelYear2,
  fuelYear3,
  fuelYear4,
  fuelYear5,
}) => {
  const classes = AdminPageStyle();

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          annual fuel cost
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Current</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {annualCostCurrent
                    ? getCurrencyValue(annualCostCurrent)
                    : editData?.annualFuelCostCurrent
                    ? getCurrencyValue(editData?.annualFuelCostCurrent)
                    : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year 1</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {fuelYear1
                    ? getCurrencyValue(fuelYear1)
                    : editData?.annualFuelCostYear1
                    ? getCurrencyValue(editData?.annualFuelCostYear1)
                    : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year 2</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {fuelYear2
                    ? getCurrencyValue(fuelYear2)
                    : editData?.annualFuelCostYear2
                    ? getCurrencyValue(editData?.annualFuelCostYear2)
                    : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year 3</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {fuelYear3
                    ? getCurrencyValue(fuelYear3)
                    : editData?.annualFuelCostYear3
                    ? getCurrencyValue(editData?.annualFuelCostYear3)
                    : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year 4</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {fuelYear4
                    ? getCurrencyValue(fuelYear4)
                    : editData?.annualFuelCostYear4
                    ? getCurrencyValue(editData?.annualFuelCostYear4)
                    : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Year 5</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {fuelYear5
                    ? getCurrencyValue(fuelYear5)
                    : editData?.annualFuelCostYear5
                    ? getCurrencyValue(editData?.annualFuelCostYear5)
                    : 0}
                </span>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
