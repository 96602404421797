import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export const handleExportButton = async (analysisResultId) => {
  let reportData = JSON.parse(analysisResultId?.reportData);
  let fleet = [reportData?.fleet];
  let fleetMix = [...reportData?.fleetMix];
  let maintenance = [reportData?.maintainanceCost];
  let cycling = [reportData?.cyclingComparision];
  let fuelCost = [reportData?.fuelCost];
  const maintenanceStartCol = 4;
  const hrStartCol = 4;
  const FuelStartCol = 1;
  const FleetMixCol = 1;
  const combinedData = {
    Fleet: fleet.map((item) => ({
      "Current Fleet Size": item?.currentFleetSize,
      "Avg. Vehicle Model Year": item?.avgVehicleYear,
      "Avg. New Vehicle Price": item?.avgNewVehiclePrice,
      "Avg. Current Mileage": Math.round(item?.currentMileage),
      "Current Cycles": item?.currentCycle,
      "Avg. Annual Miles": Math.round(item?.annualMiles),
    })),
    Maintenance: maintenance.map((item) => ({
      "Total Annualized Spend": item?.totalAnnualized?.toFixed(2),
      "Per Vehicle Spend": item?.vehicleSpend?.toFixed(2),
      "Vehicle Spend/Month": item?.vehicleSpendMonth?.toFixed(2),
      "Avg. Cost Per Mile Per Vehicle Spend": item?.costPerMile?.toFixed(2),
    })),
    CyclingComparision: cycling.map((item) => ({
      "Avg. Year 1 Cost Per Miles": item?.avgYear1CostPerMiles?.toFixed(2),
      "Avg. Year 1 MPG": item?.avgYear1Mpg?.toFixed(2),
      "Fuel Spend Year 1": item?.fuelSpendY1?.toFixed(2),
      "Monthly Lease Cost": item?.monthlyLeaseCost,
      "Monthly Maintanance Year1": item?.monthlyMaintananceYear1?.toFixed(2),
      ProposedCycle: item?.proposedCycle,
    })),
    FuelCost: fuelCost.map((item) => ({
      "Annual Spend Vehicle": item?.annualSpendVehicle?.toFixed(2),
      "Avg. Annual Miles": Math.round(item?.avgAnnualMiles),
      "Avg. Mpg": item?.avgMpg?.toFixed(2),
      "Cost Per Gallon": item?.costPerGallon?.toFixed(2),
      "Monthly Spend Vehicle": item?.monthlySpendVehicle?.toFixed(2),
      "Number Of Vehicle": item?.numberOfVehicle,
      "Total Fuel Spend": item?.totalFuelSpend?.toFixed(2),
    })),
    FleetMix: fleetMix.map((item) => ({
      "Fiscal Year": item?.fiscalYear,
      "Fleet Size": item?.fleetSize,
      "Annual Needs": item?.annualNeeds,
      "Purchased/Financed": item?.purchasedFinancial,
      "Cycling Payment": item?.cyclingPayment,
      "Cash Outlay": item?.cashOutlay,
      "Current Payment": item?.currentPayment,
      Maintenance: item?.maintenance,
      Fuel: item?.fuel,
      "Fleet Spend": item?.fleetSpeed,
      "Vehicle Equity": item?.vehicleEquity,
      Savings: item?.savings,
    })),
  };
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("PowerBi-Report");

  // Define styles
  const headerStyle = {
    font: { bold: true },
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FDFD97" }, // Yellow fill color
    },
  };

  const sectionHeaderStyle = {
    font: { bold: true, size: 14 },
    border: {
      bottom: { style: "thin" },
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8DB4E2" }, // Light purple fill color
    },
  };
  const cellStyle = {
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      wrapText: true,
    },
  };
  const boldCellStyle = {
    font: { bold: true },
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  const data = combinedData;
  let currentRow = 1;
  if (data.Fleet) {
    worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
    const sectionCell = worksheet.getCell(`A${currentRow}`);
    sectionCell.value = "Fleet";
    sectionCell.style = sectionHeaderStyle;
    currentRow++;

    // Determine headers based on data keys
    const headers = Object.keys(data.Fleet[0]);

    // Add transposed headers
    headers.forEach((header, index) => {
      const cell = worksheet.getCell(currentRow + index, 1);
      cell.value = header;
      cell.style = headerStyle;
    });

    // Add transposed data rows
    data.Fleet.forEach((item, colIndex) => {
      headers.forEach((header, rowIndex) => {
        const cell = worksheet.getCell(currentRow + rowIndex, colIndex + 2);

        if (typeof value === "number") {
          cell.value = item[header]; // This will set the value as a number
        } else {
          cell.value = Number(item[header]); // For non-numeric values
        }
        cell.style = cellStyle;
      });
    });

    // Move currentRow to the next empty row after Fleet section
    currentRow += headers.length + 2;
  }
  if (data.Maintenance) {
    const startRow = 1; // Maintenance section starts at the first row

    // Add section heading and merge cells
    worksheet.mergeCells(
      startRow,
      maintenanceStartCol,
      startRow,
      maintenanceStartCol + 1
    );
    const sectionCell = worksheet.getCell(startRow, maintenanceStartCol);
    sectionCell.value = "Maintenance Cost";
    sectionCell.style = sectionHeaderStyle;

    // Determine headers based on data keys
    const headers = Object.keys(data.Maintenance[0]);

    // Add transposed headers
    headers.forEach((header, index) => {
      const cell = worksheet.getCell(startRow + index + 1, maintenanceStartCol);
      cell.value = header;
      cell.style = headerStyle;
    });

    // Add transposed data rows
    data.Maintenance.forEach((item, colIndex) => {
      headers.forEach((header, rowIndex) => {
        const cell = worksheet.getCell(
          startRow + rowIndex + 1,
          maintenanceStartCol + colIndex + 1
        );
        if (typeof value === "number") {
          cell.value = item[header]; // This will set the value as a number
        } else {
          cell.value = Number(item[header]); // For non-numeric values
        }
        cell.style = cellStyle;
      });
    });
  }
  if (data.FuelCost) {
    const startRow = 10; // Maintenance section starts at the first row

    // Add section heading and merge cells
    worksheet.mergeCells(startRow, FuelStartCol, startRow, FuelStartCol + 1);
    const sectionCell = worksheet.getCell(startRow, FuelStartCol);
    sectionCell.value = "Fuel Cost";
    sectionCell.style = sectionHeaderStyle;

    // Determine headers based on data keys
    const headers = Object.keys(data.FuelCost[0]);

    // Add transposed headers
    headers.forEach((header, index) => {
      const cell = worksheet.getCell(startRow + index + 1, FuelStartCol);
      cell.value = header;
      cell.style = headerStyle;
    });

    // Add transposed data rows
    data.FuelCost.forEach((item, colIndex) => {
      headers.forEach((header, rowIndex) => {
        const cell = worksheet.getCell(
          startRow + rowIndex + 1,
          FuelStartCol + colIndex + 1
        );
        if (typeof value === "number") {
          cell.value = item[header]; // This will set the value as a number
        } else {
          cell.value = Number(item[header]); // For non-numeric values
        }
        cell.style = cellStyle;
      });
    });
  }
  if (data.CyclingComparision) {
    const startRow = 10; // Maintenance section starts at the first row

    // Add section heading and merge cells
    worksheet.mergeCells(startRow, hrStartCol, startRow, hrStartCol + 1);
    const sectionCell = worksheet.getCell(startRow, hrStartCol);
    sectionCell.value = "Cycling Comparision";
    sectionCell.style = sectionHeaderStyle;

    // Determine headers based on data keys
    const headers = Object.keys(data.CyclingComparision[0]);

    // Add transposed headers
    headers.forEach((header, index) => {
      const cell = worksheet.getCell(startRow + index + 1, hrStartCol);
      cell.value = header;
      cell.style = headerStyle;
    });

    // Add transposed data rows
    data.CyclingComparision.forEach((item, colIndex) => {
      headers.forEach((header, rowIndex) => {
        const cell = worksheet.getCell(
          startRow + rowIndex + 1,
          hrStartCol + colIndex + 1
        );
        if (typeof value === "number") {
          cell.value = item[header]; // This will set the value as a number
        } else {
          cell.value = Number(item[header]); // For non-numeric values
        }
        cell.style = cellStyle;
      });
    });
  }
  if (data.FleetMix) {
    const startRow = 20; // Maintenance section starts at the first row

    // Add section heading and merge cells
    worksheet.mergeCells(startRow, FleetMixCol, startRow, FleetMixCol + 1);
    const sectionCell = worksheet.getCell(startRow, FleetMixCol);
    sectionCell.value = "Fleet Mix";
    sectionCell.style = sectionHeaderStyle;

    // Determine headers based on data keys
    const headers = Object.keys(data.FleetMix[0]);

    // Add transposed headers
    headers.forEach((header, index) => {
      const cell = worksheet.getCell(startRow + index + 1, FleetMixCol);
      cell.value = header;
      cell.style = headerStyle;
    });
    // Add transposed data rows
    data.FleetMix.forEach((item, colIndex) => {
      headers.forEach((header, rowIndex) => {
        const cell = worksheet.getCell(
          startRow + rowIndex + 1,
          FleetMixCol + colIndex + 1
        );
        cell.value = item[header];
        cell.style = cellStyle;
        if (header === "Fiscal Year") {
          cell.style = boldCellStyle;
        }
      });
    });

    const totalSavings = data.FleetMix.reduce(
      (sum, item) => sum + item.Savings,
      0
    );
    currentRow += data.FleetMix.length;
    const totalLabelCell = worksheet.getCell(33, 6);
    totalLabelCell.value = "Total Savings";
    totalLabelCell.style = headerStyle;

    const totalCell = worksheet.getCell(33, 7);
    totalCell.value = totalSavings;
    totalCell.style = headerStyle;
  }
  // Move currentRow to the next empty row after Fleet section for other sections
  for (const [section, items] of Object.entries(data)) {
    if (
      section === "Fleet" ||
      section === "Maintenance" ||
      section === "FuelCost" ||
      section === "CyclingComparision" ||
      section === "FleetMix"
    )
      continue;
    const startRows = 10; // Maintenance section starts at the first row

    // Add section heading and merge cells
    worksheet.mergeCells(startRows, FuelStartCol, startRows, FuelStartCol + 1);
    const sectionCell = worksheet.getCell(startRows, FuelStartCol);
    sectionCell.value = section;
    sectionCell.style = sectionHeaderStyle;
    currentRow++;

    // Determine headers based on data keys
    const headers = Object.keys(items[0]);

    // Add headers
    worksheet.addRow(headers);
    const headerRow = worksheet.getRow(currentRow);
    headerRow.font = headerStyle.font;
    headerRow.fill = headerStyle.fill;
    headerRow.border = headerStyle.border;
    // worksheet
    //   .getRow(currentRow)
    //   .eachCell({ includeEmpty: true })
    //   .forEach((cell) => {
    //     cell.style = headerStyle;
    //   });
    currentRow++;

    // Add data rows
    items.forEach((item) => {
      worksheet.addRow(Object.values(item));
      currentRow++;
    });

    // Add a blank row between sections
    worksheet.addRow([]);
    currentRow++;
  }
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    const columnCells = column.values.filter(
      (val) => val !== undefined && val !== null
    );
    columnCells.forEach((cell) => {
      const columnLength = cell.toString().length;
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
    });
    column.width = maxLength < 10 ? 10 : maxLength;
  });
  // Save the workbook
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${analysisResultId?.reportName}.xlsx`);
};
