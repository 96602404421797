export function ReplacementYearToggle(field, toggle, value) {
  let year = {};
  if (field === "year1") {
    year = {
      ...year,
      year1: toggle.target.checked,
    };
  } else {
    year = {
      ...year,
      year1: value.data.year1,
    };
  }
  if (field === "year2") {
    year = {
      ...year,
      year2: toggle.target.checked,
    };
  } else {
    year = {
      ...year,
      year2: value.data.year2,
    };
  }
  if (field === "year3") {
    year = {
      ...year,
      year3: toggle.target.checked,
    };
  } else {
    year = {
      ...year,
      year3: value.data.year3,
    };
  }
  if (field === "year4") {
    year = {
      ...year,
      year4: toggle.target.checked,
    };
  } else {
    year = {
      ...year,
      year4: value.data.year4,
    };
  }
  if (field === "year5") {
    year = {
      ...year,
      year5: toggle.target.checked,
    };
  } else {
    year = {
      ...year,
      year5: value.data.year5,
    };
  }
  return year;
}

export const yearCountValue = (replacementList) => {
  let year1 = 0;
  let year2 = 0;
  let year3 = 0;
  let year4 = 0;
  let year5 = 0;
  replacementList?.map((items) => {
    if (items.year1) {
      year1 += 1;
    }
    if (items.year2) {
      year2 += 1;
    }
    if (items.year3) {
      year3 += 1;
    }
    if (items.year4) {
      year4 += 1;
    }
    if (items.year5) {
      year5 += 1;
    }
  });
  return { year1, year2, year3, year4, year5 };
};

export const YearConstant = {
  year1: "year1",
  year2: "year2",
  year3: "year3",
  year4: "year4",
  year5: "year5",
};
