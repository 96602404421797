import { useState, useEffect } from "react";

const useFleetStatistics = (fleetList) => {
  const [currentFleetSize, setCurrentFleetSize] = useState(0);
  const [oldVehicleYear, setOldVehicleYear] = useState(0);
  const [avgYear, setAvgYear] = useState(0);
  const [currentTotalMileage, setCurrentTotalMileage] = useState(0);
  const [fuelAverageMileageCache, setFuelAverageMileageCache] = useState(0);

  useEffect(() => {
    let sum = 0;
    let currentMileage = 0;
    let averageFuelMileage = 0;
    let ageMax = [];

    if (fleetList?.length > 0) {
      setCurrentFleetSize(fleetList.length);
      fleetList.forEach((item) => {
        sum += Number(item["age"] || 0);
        currentMileage += Number(item["totalMiles"] || 0);
        averageFuelMileage += Number(item["annualMileage"] || 0);
        ageMax.push(item.year);
      });

      let ageMaxValue = Math.min(...ageMax);
      let oldYear = new Date().getFullYear() - ageMaxValue;
      setOldVehicleYear(oldYear);

      let yearAvg = sum / fleetList.length;
      let avgAnnualMiles = averageFuelMileage / fleetList.length;
      setAvgYear(yearAvg);

      let totalCurrentMileage = currentMileage / fleetList.length;
      setCurrentTotalMileage(totalCurrentMileage);

      setFuelAverageMileageCache(avgAnnualMiles);
    }
  }, [fleetList]);

  return {
    currentFleetSize,
    oldVehicleYear,
    avgYear,
    currentTotalMileage,
    fuelAverageMileageCache,
  };
};

export default useFleetStatistics;
