import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FuelAnalysiscolumnData } from "../../common/constants/gridRowColumn";
import {
  useCompany,
  useDealer,
  useFuelAnalysis,
  useNewVehicle,
  useUser,
} from "../../common/hooks";
import { DeleteDialog } from "../../components/DeleteDialog";
import { SearchBar } from "../../components/SearchBar";
import { layoutStyle } from "../layout-ui/style";
import { TruckLoader } from "./truckLoader";
import { useDebounce } from "../../components/useDebounce";
import {
  actionColumnFunction,
  checkFieldColumn,
  checkbox,
  getDefaultColDef,
  sidebarMenu,
} from "../../common/constants/commonFunction";
import { getCompanyId } from "../../common/constants/commonFunction";
import { TAB_CONTENT, TAB_VALUE } from "../../common/constants/popupMessage";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const FuelAnalysis = () => {
  const classes = layoutStyle();
  const [page, setPage] = useState({ pageSize: 10000, page: 0 });
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnFinal, setColumnFinal] = useState([]);
  const [value, setValue] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowDataFinal, setRowDataFinal] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState();
  const { state } = useLocation();
  const navigation = useNavigate();
  const {
    getFuelAnalysisApi,
    fuelAnalysisList,
    deleteFuelAnalysisListByIdApi,
    getFuelAnalysisApiList,
    fuelListFilter,
    loading,
    setFuelListFilter,
  } = useFuelAnalysis();
  const { companyNameHeader, dealerNameHeader } = useCompany();
  const { setSelectedNav } = useUser();

  const userType = localStorage.getItem("type");
  const defaultColDef = useMemo(() => getDefaultColDef(), []);
  const { setNewFleetNo } = useDealer();
  const { setToastClose } = useNewVehicle();

  const handleClose = () => {
    setOpen(false);
    setValue(0);
  };
  useEffect(() => {
    setNewFleetNo(null);
  }, []);
  const onCellClicked = (event) => {
    let checkField = checkFieldColumn(event);
    if (checkField) {
      handleEditButton(event?.data);
    }
  };

  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };
  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };

  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };
  const searchData = rowDataFinal?.filter((fuel) =>
    fuel?.fleetNo?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const debouncedSearch = useDebounce(onSearchFunc, 500);

  useEffect(() => {
    if (searchValue) {
      setRowDataFinal([...searchData]);
    } else if (fuelListFilter?.length > 0) {
      setRowDataFinal([...fuelListFilter]);
    }
  }, [searchValue, fuelListFilter]);
  useEffect(() => {
    if (state?.data) {
      setRowDataFinal([...state.data]);
      setRows([...state.data]);
    } else {
      if (fuelListFilter?.length > 0) {
        setRowDataFinal(fuelListFilter);
      } else {
        setRowDataFinal([]);
      }
    }
  }, [fuelListFilter, state]);

  useEffect(() => {
    if (columns?.length > 0) {
      columns?.map((item) => {
        item["width"] = 160;
        return true;
      });
    }
  }, [columns]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${month}/${day}/${year}  ${hours}:${minutes}:${seconds}`;
  };
  useEffect(() => {
    if (FuelAnalysiscolumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        deleteButton: false,
      });

      const dateColumn = {
        field: "date",
        headerName: "Date",
        minWidth: 160,
        valueFormatter: (params) => formatDate(params.value),
      };

      setColumnFinal([
        checkbox,
        ...FuelAnalysiscolumnData,
        dateColumn,
        actionColumn,
      ]);
    }
  }, [FuelAnalysiscolumnData]);

  const handleAddButton = () => {
    navigation("/new-fleetlist", {
      state: {
        tabValue: TAB_VALUE.FLEET_LIST,
        selectedContent: TAB_CONTENT.FLEET_LIST,
      },
    });
  };
  const handleEditButton = (params) => {
    navigation("/new-fleetlist", {
      state: {
        tabValue: TAB_VALUE.FUEL_ANALYSIS,
        selectedContent: TAB_CONTENT.FUEL_ANALYSIS,
        data: params,
      },
    });
  };
  const handleDeleteButton = async (values) => {
    setDeleteOpen(true);
    setDeleteValue(values);
  };

  const handleDelete = async () => {
    const companyId = getCompanyId(userType, companyNameHeader);
    let payload = {
      id: deleteValue,
      companyId: companyId,
    };
    await deleteFuelAnalysisListByIdApi(payload);
    setDeleteOpen(false);
  };

  useEffect(() => {
    setToastClose(false);
    if (companyNameHeader) {
      const companyId = getCompanyId(userType, companyNameHeader);
      getFuelAnalysisApiList({
        fleetNo: "",
        companyId: companyId,
        dealerId: dealerNameHeader?.id,
      });
    } else {
      setFuelListFilter([]);
    }
  }, [companyNameHeader]);

  // useEffect(() => {

  //   getFuelAnalysisApi();
  // }, []);
  const handleNext = (event) => {
    setSelectedNav(sidebarMenu.newVehicle);
    navigation("/funding-newvehicle");
  };
  const handleBack = () => {
    setSelectedNav(sidebarMenu.maintenance);
    navigation("/maintenance-analysis");
  };
  return (
    <Box className={classes.mainContent}>
      <CustomTabPanel value={value} index={0}>
        <Box className={classes.gridContantFleetList}>
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Box className={classes.headerNameText}>Fuel Analysis</Box>
            <Box className={classes.addEditGrid}>
              <Button
                variant="outlined"
                className={classes.fleetListNewButton}
                onClick={handleBack}
              >
                <span style={{ fontSize: 12 }}>Back</span>
              </Button>
              <Button
                variant="outlined"
                className={classes.fleetListNewButton}
                onClick={handleNext}
              >
                <span style={{ fontSize: 12 }}>Next</span>
              </Button>
              <Button
                variant="outlined"
                className={classes.fleetListNewButton}
                onClick={handleAddButton}
              >
                NEW
              </Button>
              <SearchBar onSearch={onSearch} />
            </Box>
          </Stack>
          {loading ? (
            <TruckLoader />
          ) : (
            <AgGridReact
              className="ag-theme-quartz"
              rowSelection="multiple"
              onSelectionChanged={(event) => {
                const selectedNodes = event.api.getSelectedNodes();
                const selectedRows = selectedNodes.map((node) => node.data);
                setSelectedRows(selectedRows);
              }}
              suppressRowClickSelection
              pagination={true}
              rowData={rowDataFinal}
              defaultColDef={defaultColDef}
              checkboxSelection
              columnDefs={columnFinal}
              onCellClicked={onCellClicked}
            />
          )}
        </Box>
      </CustomTabPanel>
      {deleteOpen && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={() => setDeleteOpen(false)}
          deleteApi={() => handleDelete()}
        />
      )}
    </Box>
  );
};
