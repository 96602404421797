import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Drawer,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useCompany, useDealer } from "../../common/hooks";
import { validEmail, validPhone } from "../../common/utils/validation";
import { useUser } from "../../common/hooks/user";
import { layoutStyle } from "../layout-ui/style";
import { MenuProps } from "./FleetList/styledrop";
import { typeConstant } from "../../common/constants/commonFunction";
import { USER_CONTROL } from "../../common/constants/popupMessage";
import { localStorageItemNames } from "../../common/constants";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

export default function AddManageUser({ open, setOpen, rowData, handleClose }) {
  const classes = layoutStyle();
  const { createUser, getUser, updateUser } = useUser();
  const { getDealerApi, dealer, loading } = useDealer();
  // const [companyData, setCompanyData] = useState();
  // const [companyId, setCompanyId] = useState();
  const [dealarId, setDealerId] = useState();
  const [type, setType] = useState("");
  const { getCompanyApi, company } = useCompany();
  const handleDealerChange = (event, values) => {
    setDealerId(values?.props?.value);
  };
  // const handleCompanyChange =(event,values)=>{
  //   setCompanyData(values)
  //   setCompanyId(values?.id)
  // }
  const initialValues = {
    name: "",
    email: "",
    userName: "",
    password: "",
    phone: "",
    type: "",
  };

  // const schema = yup.object().shape({
  //   // name: yup.string().required("Name is required."),
  //   // email: yup.string().required("Email is required."),
  //   // userName: yup.string().required("Username is required."),
  //   // phone: yup.string().required("Phone is required."),
  //   type: yup.string().required("Type is required."),
  // });

  const generateInitialValues = (props) => {
    if (rowData) {
      for (const key in initialValues) {
        if (key in rowData && rowData[key] !== null) {
          if (Array.isArray(initialValues[key])) {
            initialValues[key] =
              Array.isArray(rowData[key]) && rowData[key].length > 0
                ? rowData[key]
                : initialValues[key];
          } else {
            initialValues[key] = rowData[key];
          }
        }
      }
    }
    return initialValues;
  };
  useEffect(() => {
    getDealerApi({ limit: 10000, offset: 0 });
    getCompanyApi({ limit: 10000, offset: 0 });
  }, [rowData]);

  useEffect(() => {
    if (rowData) {
      setType(rowData?.type);
    } else {
      setType(type);
    }
  }, [rowData]);

  useEffect(() => {
    if (type == typeConstant.dealer) {
      setDealerId(rowData?.typeId);
    }
  }, [type]);

  const handleType = (event) => {
    setType(event.target.value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: generateInitialValues(),
    mode: "onSubmit",
    reValidateMode: "onChange",
    // resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(generateInitialValues());
  }, [rowData]);

  const onSubmit = async (values) => {
    try {
      const userTypeId = localStorage.getItem(localStorageItemNames.userID);
      const payload = {
        name: values.name,
        email: values.email,
        password: values.password,
        phone: values.phone,
        type: type,
        userName: values.userName,
        deleted: false,
        typeId: dealarId,
        // status: USER_CONTROL.ACTIVATE,
        createdBy: Number(userTypeId),
        updatedBy: Number(userTypeId),
      };
      if (rowData) {
        const editPayload = {
          ...payload,
          id: rowData?.id,
        };

        await updateUser(editPayload);
        // await getUser();
      } else {
        await createUser(payload);
        // await getUser();
      }
      setDealerId(null);
      handleClose();
      reset();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const onInvalid = (errors) => console.error(errors);

  const handleOnClose = () => {
    setDealerId(null);
    setType("");
    handleClose();
  };

  return (
    <React.Fragment>
      <Drawer
        onClose={handleOnClose}
        open={open}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <DialogTitle
          className={classes.dailogTitle}
          id="customized-dialog-title"
        >
          {rowData ? "Edit Manage User" : "Add Manage User"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container className={classes.disFlexCenter}>
            <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
              <Grid
                sx={{ width: "30vw", marginY: 1, paddingX: 2 }}
                className={classes.companyForm}
              >
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Name</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    color="success"
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("name", {
                      required: "Name field is required",
                    })}
                    error={errors?.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Email</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    color="success"
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("email", {
                      required: "Email field is required",
                      validate: (value) => {
                        return validEmail(value);
                      },
                    })}
                    helperText={errors?.email?.message}
                    error={errors?.email}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>
                    User Name
                  </InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    color="success"
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("userName", {
                      required: "User Name field is required",
                    })}
                    error={errors?.userName}
                    helperText={errors?.userName?.message}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Password</InputLabel>
                  <TextField
                    type="password"
                    fullWidth
                    color="success"
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("password", {
                      required: "Password field is required",
                    })}
                    error={errors?.password}
                    helperText={errors?.password?.message}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Phone</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    color="success"
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("phone", {
                      required: "Phone field is required",
                      validate: (value) => {
                        return validPhone(value);
                      },
                    })}
                    error={errors?.phone}
                    helperText={errors?.phone?.message}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Type</InputLabel>
                  <Select
                    style={{ fontSize: "12px" }}
                    value={type}
                    {...register("type")}
                    onChange={(event) => handleType(event)}
                  >
                    <MenuItem value={"admin"}>Admin</MenuItem>
                    <MenuItem value={"dealer"}>Dealer</MenuItem>
                  </Select>
                </Grid>
                {type == typeConstant.dealer && (
                  <>
                    {loading ? (
                      "Fetching data......"
                    ) : (
                      <>
                        <Grid className={classes.compnayFormField}>
                          <InputLabel style={{ fontSize: "12px" }}>
                            Dealer
                          </InputLabel>
                          <Select
                            value={dealarId}
                            onChange={handleDealerChange}
                            fullWidth
                            sx={{ fontSize: 12 }}
                            MenuProps={MenuProps}
                          >
                            {dealer?.map((dealer) => (
                              <MenuItem key={dealer.id} value={dealer.id}>
                                {dealer?.dealerName}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dealer}
                        value={dealarData}
                        sx={{ fontSize: 12 }}
                        getOptionLabel={(option) => option.dealerName}
                        renderInput={(params) => (
                          <TextField sx={{ fontSize: 12 }} {...params} />
                        )}
                        onChange={handleDealerChange}
                      /> */}
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.fleetListNewButton}
                  autoFocus
                  fullWidth
                  sx={{ mt: 3 }}
                >
                  {rowData ? "Edit User" : "Add User"}
                </Button>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
}
