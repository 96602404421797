import { makeStyles } from "@mui/styles";
export const CommonStyle = makeStyles((theme) => {
  return {
    main: {
      opacity: "1",
      color: "rgb(52, 71, 103)",
      boxShadow: " none",
      width: "100%",
      cursor: "pointer",
      margin: "0px 10px !important",
    },

    box1: {
      color: "rgb(255, 255, 255)",
      width: "5rem",
      height: "5rem",
      display: "flex",
      opacity: 1,
      // boxShadow: "1px 1px 6px lightgrey !important",
      background: "#fff !important",
      alignItems: "center",
      borderRadius: "20%",
      justifyContent: "center",
      "&:hover ": {
        // background: `url(${HeaderHover})`,
        // backgroundSize: "cover",
        background: "#fff !important",
      },
    },
    paper: {
      display: "flex",
      height: "110px",
      justifyContent: "center",
      flexDirection: "column",
      overflowWrap: "break-word",
      borderRadius: "12px !important",
      background: "#20769bbd   !important",
      // background: `url(${Header})`,
      // backgroundSize: "cover",
      boxShadow:
        "rgb(0 0 0 / 10 %) 0rem 0.25rem 0.375rem - 0.0625rem, rgb(0 0 0 / 6 %) 0rem 0.125rem 0.25rem - 0.0625rem !important",
      "&:hover ": {
        // background: `url(${HeaderHover})`,
        // backgroundSize: "cover",
        background: "#9CBF2E !important",
        boxShadow:
          "rgb(0 0 0 / 14 %) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40 %) 0rem 0.4375rem 0.625rem - 0.3125rem !important",
      },
    },
    descSpan: {
      margin: "0px",
      fontSize: "1rem !important",
      lineHeight: "1.5",
      letterSpacing: "0.02857em",
      opacity: "1",
      textTransform: "none",
      verticalAlign: "unset",
      textDecoration: "none !important",
      color: "#fff",
      fontWeight: "700 !important",
      // width: "200px ",
      // "&:hover": {
      //   color: "black !important",
      // },
    },
    mainBox: {
      display: "flex",
      margin: "10px",
      // justifyContent: "space-between",
      alignItems: "center",
      padding: "0 12px",
      gap: 10,
      //background: "#fff !important",
      // height: "110px !important",
    },
    text: {
      textAlign: "right",
      lineHeight: "0",
      opacity: "1",
      background: "transparent",
      color: "rgb(52, 71, 103)",
      boxShadow: "none",
    },
    paperNew: {
      display: "flex",
      height: "130px",
      flexDirection: "column",
      overflowWrap: "break-word",
      borderRadius: "12px !important",
    },
    svg: {
      userSelect: "none",
      width: "50px !important",
      height: "50px !important",
      overflow: "hidden",
      display: "inline-block",
      textAlign: "center",
      flexShrink: 0,
      // fontAize: "1.5rem",
    },
    textinput: {
      "& .css-1x51dt5-MuiInputBase-input-MuiInput-input": {
        // height: "1.4em !important",
        padding: "0px !important",
        fontSize: "12px !important",
        color: "#18333A !important",
      },
      "& .css-cnfv5a-MuiInputBase-root-MuiInput-root::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      // width: "120px",
      fontSize: "12px !important",
      color: "#18333A !important",
    },
    
    mainBoxHover: {
      "&:hover $box1": {
        background: "#fff !important",
      },
      "&:hover $paper": {
        background: "#9CBF2E !important",
        boxShadow:
          "rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem !important",
      },
    },
  };
});
