import CloseIcon from "@mui/icons-material/Close";
import {
  Drawer,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCompany, useMake, useModel, useYear } from "../../common/hooks";
import { layoutStyle } from "../../pages/layout-ui/style";
import readXlsxFile from "read-excel-file";

export default function AddModel({
  open,
  setOpen,
  title,
  setRefreshGrid,
  data,
  handleClose,
}) {
  const classes = layoutStyle();
  const [binaryImage, setBinaryImage] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const { getCompanyApi, company } = useCompany();
  const { make } = useMake();
  const { year, getYearApi } = useYear();
  const { createModelApi, editModelApi } = useModel();
  // const [XLSXData, setXLSXData] = useState();
  // const handleOnChange = (e) => {
  //   let rowDataName = [];
  //   const input = document.getElementById("input");
  //   readXlsxFile(input.files[0]).then((rows) => {
  //     rows?.map(async (items) => {
  //       rowDataName.push(items);
  //     });
  //     setXLSXData(rowDataName);
  //   });
  // };
  // const addXLSXData = async () => {
  //   let dataUpload = [];
  //   XLSXData?.map(async (items) => {
  //     let payload = {
  //       name: items[0] == null ? "" : items[0],
  //       // address: items[2] == null ? "" : items[2],
  //       make: items[1] == null ? "" : items[1],
  //       // location: items[3] == null ? "" : items[3],
  //       // status: items[4] == "Status" ? true : false,
  //       // region: items[5] == null ? "" : items[5],
  //     };
  //     await createModelApi(payload);
  //     dataUpload.push(payload);
  //   });
  // };
  // const handleClose = () => {
  //   setSelectedCompany("");
  //   setSelectedYear("");
  //   setOpen(false);
  //   reset();
  // };
  useEffect(() => {
    getCompanyApi();
    getYearApi();
  }, []);
  const companyData = {};
  const defaultValue = {
    companyName: "",
    companyLogo: null,
  };

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
    setValue,
  } = useForm({
    defaultValues: defaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    // resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (data) {
      setValue("name", data?.model);
      setSelectedCompany(data?.makeId);
      setSelectedYear(data?.yearId);
    } else {
      setValue("name", "");
      setSelectedCompany("");
      setSelectedYear("");
    }
  }, [data]);

  const onSubmit = async (values) => {
    let payload = {
      ...values,
      makeId: selectedCompany,
      yearId: selectedYear,
    };
    if (data?.modelId) {
      await editModelApi({
        ...payload,
        id: data?.modelId,
      });
    } else {
      await createModelApi(payload);
    }
    setRefreshGrid(true);
    handleClose();
  };

  const handleCompanyName = (event) => {
    setCompanyName(event.target.value);
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "calc(85vh - 380px)",
      },
    },
    MenuListProps: {
      style: {
        padding: 0,
      },
    },
  };

  return (
    <React.Fragment>
      <Drawer
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <DialogTitle
          className={classes.dailogTitle}
          id="customized-dialog-title"
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container className={classes.disFlexCenter}>
            {/* <div>
              <Button
                className={[classes.fleetListNewButton, classes.fontSize12]}
                onClick={addXLSXData}
              >
                Upload
              </Button>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <label htmlFor="input">
                  <Button
                    variant="contained"
                    className={[classes.fleetListNewButton, classes.fontSize12]}
                    component="span"
                  >
                    Choose File
                  </Button>
                </label>
                <input
                  type="file"
                  onChange={handleOnChange}
                  id="input"
                  style={{ display: "none" }}
                />
              </Box>
            </div> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid className={classes.companyForm}>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Name</InputLabel>
                  <TextField
                    // error={errors?.companyName}
                    className={classes.fontSize12}
                    type="text"
                    name="name"
                    fullWidth
                    {...register("name")}
                    onChange={handleCompanyName}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Make</InputLabel>
                  <Select
                    className="aaaaaa"
                    value={selectedCompany}
                    onChange={handleCompanyChange}
                    fullWidth
                    MenuProps={MenuProps}
                    sx={{ fontSize: 12 }}
                  >
                    {make?.map((make) => (
                      <MenuItem key={make.id} value={make.id}>
                        {make?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Year</InputLabel>
                  <Select
                    value={selectedYear}
                    onChange={handleYearChange}
                    fullWidth
                    MenuProps={MenuProps}
                    sx={{ fontSize: 12 }}
                  >
                    {year?.map((yearValue) => (
                      <MenuItem key={yearValue.id} value={yearValue.id}>
                        {yearValue?.year}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.fleetListNewButton}
                  autoFocus
                  sx={{ mt: 3, height: "50px" }}
                >
                  {title}
                </Button>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
}
