import { Save } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useCompany,
  useDealer,
  useFleetList,
  useFuelList,
  useReplacement,
  useTypeList,
} from "../../../common/hooks";
import { AdminPageStyle } from "../style";
import { getCompanyId } from "../../../common/constants/commonFunction";
import { FleetInfo } from "../../../components/Replacement-Schedule/FleetInfo";
import { CycleYearInfo } from "../../../components/Replacement-Schedule/CycleYearinfo";
import { cycleYear } from "../../../common/constants/selectConstants";

export const NewFleetListReplacementSchedule = ({
  selectedContent,
  fleetNoValue,
}) => {
  const classes = AdminPageStyle();
  const { state } = useLocation();
  const { getFleetListApi, fleetList } = useFleetList();
  const {
    createReplacementApi,
    editReplacementListApi,
    getReplacementListApi,
    replacementList,
  } = useReplacement();
  const { companyNameHeader, dealerNameHeader } = useCompany();
  const { manualCalculationApi } = useDealer();
  const { typeListById, getTypeListByIdApi } = useTypeList();
  const { fuelListById, getFuelListByIdApi } = useFuelList();
  const [vehicleInfoValue, setVehicleInfoValue] = useState();
  const [vehicleInfoData, setVehicleInfoData] = useState({});
  const [editData, setEditData] = useState();
  const userType = localStorage.getItem("type");
  const companyId = getCompanyId(userType, companyNameHeader);
  const [year1ValueData, setYear1ValueData] = useState({
    year1: editData?.year1 || false,
    year2: editData?.year2 || false,
    year3: editData?.year3 || false,
    year4: editData?.year4 || false,
    year5: editData?.year5 || false,
  });
  const [fleetNo, setFleetNo] = useState(state?.data);
  useEffect(() => {
    if (fleetNoValue && fleetList?.length > 0) {
      let filterValue = fleetList?.find(
        (item) => item?.fleetNo == fleetNoValue
      );
      if (filterValue) {
        setVehicleInfoValue(filterValue);
      }
    }
  }, [fleetNoValue, fleetList]);

  useEffect(() => {
    if (replacementList?.length > 0) {
      let replacementData = replacementList?.filter(
        (item) => item?.fleetNo === vehicleInfoValue?.fleetNo
      )?.[0];
      if (replacementList?.length > 0) {
        setEditData(replacementList[0]);
        setYear1ValueData({
          year1: replacementData?.year1,
          year2: replacementData?.year2,
          year3: replacementData?.year3,
          year4: replacementData?.year4,
          year5: replacementData?.year5,
        });
      }
    }
  }, [replacementList]);

  useEffect(() => {
    if (vehicleInfoValue) {
      const newData = fleetList?.filter(
        (item) => item?.fleetId === vehicleInfoValue
      )?.[0];
      setVehicleInfoData(newData);
    }
  }, [vehicleInfoValue]);

  const [checked, setChecked] = useState(
    new Array(cycleYear?.length).fill(false)
  );

  useEffect(() => {
    if (vehicleInfoData) {
      const newOpenCollapse = [...checked];
      newOpenCollapse[vehicleInfoData?.yearId - 1] = true;
      setChecked(newOpenCollapse);
    }
  }, [vehicleInfoData]);

  useEffect(() => {
    if (companyId && dealerNameHeader) {
      if (vehicleInfoValue?.fleetNo) {
        getReplacementListApi({
          fleetNo: vehicleInfoValue?.fleetNo,
          companyId: companyId,
          dealerId: dealerNameHeader?.id,
        });
      } else {
        getFleetListApi({
          companyId: companyId,
          dealerId: dealerNameHeader?.id,
        });
      }
    }
  }, [companyId, vehicleInfoValue?.fleetNo]);

  useEffect(() => {
    if (vehicleInfoValue) {
      getReplacementListApi({
        fleetNo: vehicleInfoValue?.fleetNo,
        companyId: companyId,
        dealerId: dealerNameHeader?.id,
      });
    }
  }, [vehicleInfoValue]);

  useEffect(() => {
    if (vehicleInfoValue) {
      getFuelListByIdApi(vehicleInfoValue.fuelId);
      getTypeListByIdApi(vehicleInfoValue.typeId);
    }
  }, [vehicleInfoValue]);

  const handleSaveReplacement = async (formData) => {
    let payload = {
      year1: year1ValueData?.year1 ? true : false,
      year2: year1ValueData?.year2 ? true : false,
      year3: year1ValueData?.year3 ? true : false,
      year4: year1ValueData?.year4 ? true : false,
      year5: year1ValueData?.year5 ? true : false,
      fleetNo: vehicleInfoValue?.fleetNo,
      companyId: companyId,
      dealerId: dealerNameHeader?.id,
    };

    let editPayload = {
      year1: year1ValueData?.year1 ? true : false,
      year2: year1ValueData?.year2 ? true : false,
      year3: year1ValueData?.year3 ? true : false,
      year4: year1ValueData?.year4 ? true : false,
      year5: year1ValueData?.year5 ? true : false,
      fleetNo: vehicleInfoValue?.fleetNo,
      companyId: companyId,
      id: editData?.replacementId,
      dealerId: dealerNameHeader?.id,
    };

    let manualCalculation = {
      year: vehicleInfoValue?.year,
      totalMiles: vehicleInfoValue?.totalMiles,
      make: vehicleInfoValue?.make,
      model: vehicleInfoValue?.model,
      vin: vehicleInfoValue?.vin,
      stateCode: vehicleInfoValue?.stateCode,
      dealer: vehicleInfoValue?.dealerName,
      company: companyNameHeader?.companyName,
      typeId: vehicleInfoValue?.typeId,
      fleetNo: fleetNo?.fleetNo,
      cyclingFuelEco: 0,
      fuelId: vehicleInfoValue?.fuelId,
      replacementSchedule: {
        id: editData?.replacementId,
        deleted: false,
        fleetNo: fleetNo?.fleetNo,
        year1: year1ValueData?.year1 ? true : false,
        year2: year1ValueData?.year2 ? true : false,
        year3: year1ValueData?.year3 ? true : false,
        year4: year1ValueData?.year4 ? true : false,
        year5: year1ValueData?.year5 ? true : false,
      },
      mpg: vehicleInfoValue?.mpg,
      stateName: vehicleInfoValue?.state,
    };
    if (state?.data) {
      await editReplacementListApi(editPayload);
      await manualCalculationApi(manualCalculation);
    } else {
      await createReplacementApi(payload);
      await manualCalculationApi(manualCalculation);
    }
  };
  return (
    <Box>
      <Box className={classes.fleetListInformation}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.headerFleet}
            padding={"10px !important"}
          >
            {vehicleInfoValue ? `${selectedContent} : ` : selectedContent}
            {vehicleInfoValue?.fleetNo ? vehicleInfoValue?.fleetNo : ""}
            <Button
              className={classes.headerSaveButton}
              onClick={handleSaveReplacement}
              startIcon={<Save />}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
          <FleetInfo
            companyNameHeader={companyNameHeader}
            state={state}
            fleetNo={fleetNo}
            fleetList={fleetList}
            vehicleInfoValue={vehicleInfoValue}
            fuelListById={fuelListById}
            typeListById={typeListById}
            setChecked={setChecked}
            cycleYear={cycleYear}
            setVehicleInfoValue={setVehicleInfoValue}
            setFleetNo={setFleetNo}
          />
          <CycleYearInfo
            setYear1ValueData={setYear1ValueData}
            year1ValueData={year1ValueData}
          />
        </Grid>
      </Box>
    </Box>
  );
};
