import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Tab, Tabs } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  columnData,
  getColorChange,
  ManageUserColumnData,
} from "../../common/constants/gridRowColumn";
import {
  useCompany,
  useDealer,
  useFleetList,
  useNewVehicle,
  useUser,
} from "../../common/hooks";
import { DeleteDialog } from "../../components/DeleteDialog";
import { layoutStyle } from "../layout-ui/style";
import { TruckLoader } from "./truckLoader";
import {
  actionColumnFunction,
  checkFieldColumn,
  checkbox,
  getCompanyId,
  getDefaultColDef,
} from "../../common/constants/commonFunction";
import { sidebarMenu } from "../../common/constants/commonFunction";
import { FleetCsvUpload } from "../../components/FleetCsvUpload";
import { FleetHeaderButton } from "../../components/FleetHeaderButton";
import {
  CustomTabPanel,
  a11yProps,
} from "../../components/FleetTabPanelCustom";
import { TAB_CONTENT, TAB_VALUE } from "../../common/constants/popupMessage";
import { SpeakToTextButton } from "../../common/services/SpeakToTextButton";

export const FleetList = () => {
  const classes = layoutStyle();
  const [columnFinal, setColumnFinal] = useState([]);
  const [value, setValue] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowDataFinal, setRowDataFinal] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    fleetList,
    getFleetListApi,
    setEditData,
    setTotalCount,
    deletFleetListByIdApi,
    loading,
    multipleFleetDeleteApi,
    setFleetList,
    requireFields,
  } = useFleetList();
  const [page, setPage] = useState({ pageSize: 10000, page: 0 });
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState();
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const { companyNameHeader, dealerNameHeader } = useCompany();
  const [mpgNull, setMpgNull] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [file, setFile] = useState();
  const [rows, setRows] = useState([]);
  const {
    fleetUpload,
    errorMessage,
    setErrorMessage,
    companyDealerId,
    setFleetUpload,
    setNewFleetNo,
  } = useDealer();
  const userType = localStorage.getItem("type");
  const { setSelectedNav } = useUser();
  const { state } = useLocation();
  const navigation = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [rowDataCSV, setRowDataCsv] = useState([]);
  const [originalColumn, setOriginalColumn] = useState(false);
  const { setToastClose } = useNewVehicle();

  const handleClose = () => {
    setOpen(false);
    setValue(0);
    setRows([]);
    setFile(null);
    setErrorMessage("");
    setAddButtonDisabled(false);
  };

  useEffect(() => {
    if (companyNameHeader) {
      let companyValue = getCompanyId(userType, companyNameHeader);
      setCompanyId(companyValue);
    }
  }, [companyNameHeader]);

  useEffect(() => {
    const searchData = fleetList?.filter((fleet) =>
      fleet?.fleetNo?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    if (searchValue) {
      setRowDataFinal([...searchData]);
    } else if (fleetList?.length > 0) {
      setRowDataFinal([...fleetList]);
    }
  }, [searchValue, fleetList]);

  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  useEffect(() => {
    setErrorMessage("");
    setToastClose(false);
  }, []);

  useEffect(() => {
    let companyValue = getCompanyId(userType, companyNameHeader);
    if (companyValue && dealerNameHeader) {
      getFleetListApi({
        limit: page.pageSize,
        offset: page.page,
        filter: {},
        companyId: companyValue,
        dealerId: dealerNameHeader?.id,
      });
    } else {
      setRowDataFinal([]);
      setFleetList([]);
      setMpgNull([]);
    }
  }, [fleetUpload, companyNameHeader, dealerNameHeader, companyDealerId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (state?.data) {
      setRowDataFinal([...state.data]);
      setRows([...state.data]);
    } else {
      if (fleetList?.length > 0) {
        let rowsMpg = [];
        fleetList?.map((items) => {
          if (items?.mpg === 0) {
            rowsMpg.push(items?.fleetNo);
          }
        });
        setMpgNull(rowsMpg);
        setRowDataFinal([...fleetList]);
      } else {
        setRowDataFinal([]);
      }
    }
  }, [fleetList, state]);

  useEffect(() => {
    if (errorMessage == "empty") {
      setTotalCount(fleetList?.length);
      handleClose();
      setRows([]);
      setFleetUpload(false);
    } else if (errorMessage) {
      // showToast(errorMessage)
    }
  }, [errorMessage]);

  const handleEditButton = (params) => {
    setEditData(params);
    navigation("/new-fleetlist", {
      state: {
        tabValue: TAB_VALUE.FLEET_LIST,
        selectedContent: TAB_CONTENT.FLEET_LIST,
        data: params,
      },
    });
  };

  const onCellClicked = (event) => {
    let checkField = checkFieldColumn(event);
    if (checkField) {
      handleEditButton(event?.data);
    }
  };

  const handleDeleteButton = async (values) => {
    const fleetId = values?.fleetId
      ? [values?.fleetId]
      : values.map((item) => item?.fleetId);
    setDeleteOpen(true);
    if (Array.isArray(fleetId)) {
      setDeleteValue(fleetId);
    } else {
      setDeleteValue({ fleetId });
    }
  };

  const handleDelete = async () => {
    setDeleteOpen(false);
    let dealerId = dealerNameHeader?.id;
    if (Array.isArray(deleteValue)) {
      const ids = deleteValue;
      let payload = { ids, companyId, dealerId };
      await multipleFleetDeleteApi(payload);
    } else {
      let payload = {
        ids: deleteValue,
        companyId: companyId,
        dealerId: dealerId,
      };
      await multipleFleetDeleteApi(payload);
    }
    setDeleteValue(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNext = (event) => {
    setSelectedNav(sidebarMenu.replacement);
    navigation("/replacement-schedule");
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleRowSelection = (event) => {
    setSelectedRows(event.api.getSelectedNodes().map((node) => node.data));
  };

  useEffect(() => {
    setEditData({});
    setNewFleetNo(null);
  }, []);

  useEffect(() => {
    if (rowDataCSV?.length > 0) {
      setRows(rowDataCSV);
    } else {
      setRows([]);
    }
  }, [rowDataCSV]);

  useEffect(() => {
    if (
      requireFields?.length == 0 &&
      columnFinal?.length > 0 &&
      mpgNull?.length == 0
    ) {
      columnFinal?.map((item) => {
        item["cellStyle"] = { background: "white", color: "black" };
      });
      setColumnFinal([...columnFinal]);
    } else {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        handleDeleteButton: handleDeleteButton,
        deleteButton: true,
      });
      columnData?.map((items) => {
        items["cellStyle"] = (params) => getColorChange(params);
      });
      setColumnFinal([checkbox, ...columnData, actionColumn]);
    }
  }, [requireFields, columnData, mpgNull]);

  return (
    <Box className={classes.mainContent}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} p={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{ textTransform: "capitalize", fontWeight: 600 }}
              label="Fleet List"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ textTransform: "capitalize", fontWeight: 600 }}
              label="Fleet Upload CSV / XLSX"
              {...a11yProps(1)}
            />
            <div className={classes.notediv}>
              <div className={classes.noteContain}>
                {/* <div className={classes.speakToText}>
                  <SpeakToTextButton
                    value={
                      "Red color in grid is for MPG not found in Government website, Yellow color in grid is for Vehicle Model closest match"
                    }
                  />
                </div> */}
              </div>
              <div className={classes.noteContain}>
                <div className={classes.redbox}> </div>&nbsp;MPG not found in
                Government website.
              </div>
              <div className={classes.noteContain}>
                <div className={classes.yellowbox}> </div>&nbsp;Vehicle Model
                -closest match used.
              </div>
            </div>
          </Tabs>
        </Box>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box className={classes.gridContantFleetListPage}>
          <Box>
            <span
              style={
                mpgNull?.length > 0
                  ? { color: "red" }
                  : { color: "transperent" }
              }
            >
              {mpgNull &&
                mpgNull?.length > 0 &&
                "MPG value is required for following fleets: "}
              {mpgNull &&
                mpgNull?.length > 0 &&
                mpgNull?.map((item) => {
                  return <span key={item}>{item ? item + " " : ""}</span>;
                })}
            </span>
          </Box>
          <FleetHeaderButton
            handleNext={handleNext}
            handleClick={handleClick}
            selectedRows={selectedRows}
            setSearchValue={setSearchValue}
            anchorEl={anchorEl}
            handleOnClose={handleOnClose}
            handleDeleteButton={handleDeleteButton}
            companyId={companyId}
            setSelectedRows={setSelectedRows}
            rowDataFinal={rowDataFinal}
          />
          {loading ? (
            <TruckLoader />
          ) : (
            <AgGridReact
              className="ag-theme-quartz"
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rowDataFinal.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows(selectedRows);
              }}
              rowSelection="multiple"
              suppressRowClickSelection
              pagination={true}
              rowData={rowDataFinal}
              defaultColDef={defaultColDef}
              checkboxSelection
              columnDefs={columnFinal}
              onSelectionChanged={handleRowSelection}
              onCellClicked={onCellClicked}
            />
          )}
        </Box>
      </CustomTabPanel>
      <FleetCsvUpload
        handleClose={handleClose}
        open={open}
        classes={classes}
        defaultColDef={defaultColDef}
        handleRowSelection={handleRowSelection}
        addButtonDisabled={addButtonDisabled}
        setAddButtonDisabled={setAddButtonDisabled}
        setRowDataCsv={setRowDataCsv}
        columnFinal={columnFinal}
        rows={rows}
        setRows={setRows}
        setFile={setFile}
        file={file}
      />
      {deleteOpen && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={() => setDeleteOpen(false)}
          deleteApi={() => handleDelete()}
        />
      )}
    </Box>
  );
};
