import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Tab, Tabs, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
  makeColumnData,
  modelColumnData,
  yearColumnData,
} from "../../common/constants/gridRowColumn";
import { useMake, useModel, useYear } from "../../common/hooks";
import { SearchBar } from "../../components/SearchBar";
import { layoutStyle } from "../layout-ui/style";
import AddMake from "./AddMake";
import AddModel from "./AddModel";
import AddYear from "./AddYear";
import { DeleteDialog } from "../../components/DeleteDialog";
import { TruckLoader } from "./truckLoader";
import {
  actionColumnFunction,
  checkbox,
  getDefaultColDef,
} from "../../common/constants/commonFunction";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ManageVehicle = () => {
  const classes = layoutStyle();
  const [file, setFile] = useState();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnFinal, setColumnFinal] = useState([]);
  const [columnMake, setColumnMake] = useState([]);
  const [columnYear, setColumnYear] = useState([]);
  const [value, setValue] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowDataFinal, setRowDataFinal] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Add Make");
  const [modelTitle, setModelTitle] = useState("Add Model");
  const [yearTitle, setYearTitle] = useState("Add Year");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermModel, setSearchTermModel] = useState("");
  const [searchTermYear, setSearchTermYear] = useState("");
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openYear, setOpenYear] = useState(false);
  const [page, setPage] = useState({ pageSize: 10000, page: 0 });
  const [refreshData, setRefreshData] = useState(false);
  const [dataToEdit, setDataToEdit] = useState("");
  const [modelRowData, setModelRowData] = useState([]);
  const [yearRowData, setYearRowData] = useState([]);
  const [rowDataFinalMake, setRowDataFinalMake] = useState([]);
  const [rowDataFinalModel, setRowDataFinalModel] = useState([]);
  const [rowDataFinalYear, setRowDataFinalYear] = useState([]);
  const [deleteOpenMake, setDeleteOpenMake] = useState(false);
  const [deleteValueMake, setDeleteValueMake] = useState();
  const [deleteOpenModel, setDeleteOpenModel] = useState(false);
  const [deleteValueModel, setDeleteValueModel] = useState();
  const [deleteOpenYear, setDeleteOpenYear] = useState(false);
  const [deleteValueYear, setDeleteValueYear] = useState();

  const { make, getMakeApi, deleteMakeApi, createMakeApi, makeLoading } =
    useMake();
  const {
    getModel,
    getModelList,
    model,
    modelData,
    deleteModelApi,
    modelLoading,
  } = useModel();
  const { getYearApi, year, deleteYearApi, yearLoading } = useYear();

  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  useEffect(() => {
    if (value === 1) {
      getModelList({ limit: 10000, offset: 0 });
    } else if (value === 2) {
      getYearApi({ limit: 10000, offset: 0 });
    }
  }, [value, refreshGrid]);

  useEffect(() => {
    if (searchTerm) {
      const filteredData = rowDataFinalMake?.filter((item) =>
        item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
      setRowDataFinal(filteredData);
    } else {
      setRowDataFinal([...rowDataFinalMake]);
    }
  }, [rowDataFinalMake, searchTerm]);

  useEffect(() => {
    if (searchTermModel) {
      const filteredData = rowDataFinalModel?.filter((item) =>
        item?.model?.toLowerCase().includes(searchTermModel?.toLowerCase())
      );
      setModelRowData(filteredData);
    } else {
      setModelRowData([...rowDataFinalModel]);
    }
  }, [rowDataFinalModel, searchTermModel]);

  useEffect(() => {
    if (searchTermYear) {
      const filteredData = rowDataFinalYear?.filter((item) => {
        const yearString = String(item?.year);
        return yearString.includes(searchTermYear);
      });
      setYearRowData(filteredData);
    } else {
      setYearRowData([...rowDataFinalYear]);
    }
  }, [rowDataFinalYear, searchTermYear]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fileReader = new FileReader();

  useEffect(() => {
    getMakeApi({ limit: page.pageSize, offset: page.page });
  }, [refreshData]);

  useEffect(() => {
    if (make?.length > 0) {
      setRowDataFinalMake([...make]);
    }
    if (modelData?.length > 0) {
      setRowDataFinalModel([...modelData]);
    }
    if (year?.length > 0) {
      setRowDataFinalYear([...year]);
    }
  }, [make, modelData, year]);

  const csvFileToArray = (string) => {
    const rows = string.split("\n");
    let headers;
    if (rows[0].split(";")?.length > 1) {
      headers = rows[0].split(";").map((header) => header.trim());
    } else {
      headers = rows[0].split(",").map((header) => header.trim());
    }

    const data = [];
    for (let i = 1; i < rows.length; i++) {
      let values;
      if (rows[i].split(";")?.length > 1) {
        values = rows[i].split(";");
      } else {
        values = rows[i].split(",");
      }
      if (values.length === headers.length) {
        const obj = { id: i };
        headers.forEach((header, index) => {
          obj[header] = values[index].trim();
        });
        data.push(obj);
      }
    }

    setRows(data);
    setColumns(
      headers.map((header) => ({ field: header, headerName: header }))
    );
  };

  const handleEditButton = (rowData) => {
    setTitle("Edit Make");
    setDataToEdit(rowData);
    setOpen(true);
  };
  const handleDeleteMakeClick = async (values) => {
    setDeleteOpenMake(true);
    setDeleteValueMake(values?.id);
  };

  const handleDeleteMake = async () => {
    await deleteMakeApi(deleteValueMake);
    setDeleteOpenMake(false);
  };

  useEffect(() => {
    if (makeColumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        handleDeleteButton: handleDeleteMakeClick,
        deleteButton: true,
      });
      setColumnMake([checkbox, ...makeColumnData, actionColumn]);
    }
  }, [makeColumnData, selectedRows]);

  const handleEditModelButton = (params) => {
    setDataToEdit(params);
    setModelTitle("Edit Model");
    setOpenModel(true);
  };
  const handleDeleteModelClick = async (values) => {
    setDeleteOpenModel(true);
    setDeleteValueModel(values?.modelId);
  };

  const handleDeleteModel = async () => {
    await deleteModelApi(deleteValueModel);
    setDeleteOpenModel(false);
  };

  useEffect(() => {
    if (modelColumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditModelButton,
        handleDeleteButton: handleDeleteModelClick,
        deleteButton: true,
      });
      setColumnFinal([checkbox, ...modelColumnData, actionColumn]);
    }
  }, [selectedRows, modelColumnData]);

  const handleEditYearButton = (params) => {
    setDataToEdit(params);
    setYearTitle("Edit Year");
    setOpenYear(true);
  };
  const handleDeleteYearClick = async (values) => {
    setDeleteOpenYear(true);
    setDeleteValueYear(values?.id);
  };

  const handleDeleteYear = async () => {
    await deleteYearApi(deleteValueYear);
    setDeleteOpenYear(false);
  };

  useEffect(() => {
    if (yearColumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditYearButton,
        handleDeleteButton: handleDeleteYearClick,
        deleteButton: true,
      });
      setColumnYear([checkbox, ...yearColumnData, actionColumn]);
    }
  }, [selectedRows, yearColumnData]);

  useEffect(() => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text, event);
      };

      fileReader.readAsText(file);
    } else {
      setRows([]);
      setColumns([]);
    }
  }, [file]);

  const handleAddButton = () => {
    setDataToEdit("");
    setOpen(true);
  };

  const handleAddYearButton = () => {
    setDataToEdit("");
    setOpenYear(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModel(false);
    setDataToEdit("");
    setTitle("Add Make");
    setYearTitle("Add Year");
    setOpenYear(false);
  };

  const handleModelButton = (event) => {
    setOpenModel(true);
    setDataToEdit("");
    setModelTitle("Add Model");
  };
  const onSearchModel = (event) => {
    setSearchTermModel(event.target.value);
  };
  const onSearchMake = (event) => {
    setSearchTerm(event.target.value);
  };
  const onSearchYear = (event) => {
    setSearchTermYear(event.target.value);
  };
  const [XLSXData, setXLSXData] = useState();
  const addXLSXData = async () => {
    let dataUpload = [];
    XLSXData?.map(async (items) => {
      let payload = {
        name: items,
      };
      await createMakeApi(payload);
      dataUpload.push(payload);
    });
  };
  const handleOnChange = (e) => {
    let rowDataName = [];
    const input = document.getElementById("input");
    // readXlsxFile(input.files[0]).then((rows) => {
    //   rows.map(async (items) => {
    //     if (items[0] !== null) {
    //       rowDataName.push(items[0]);
    //     }
    //   });
    //   setXLSXData(rowDataName);
    // });
  };
  return (
    <Box className={classes.mainContent}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} p={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Make"
              sx={{ textTransform: "capitalize", fontWeight: 600 }}
              {...a11yProps(0)}
            />
            <Tab
              label="Model"
              sx={{ textTransform: "capitalize", fontWeight: 600 }}
              {...a11yProps(1)}
            />
            <Tab
              label="Year"
              sx={{ textTransform: "capitalize", fontWeight: 600 }}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box className={classes.gridContantFleetList}>
          <Box className={classes.addEditGrid}>
            <Button
              variant="outlined"
              className={classes.fleetListNewButton}
              onClick={handleAddButton}
            >
              Add
            </Button>
            <SearchBar onSearch={onSearchMake} />
          </Box>
          {makeLoading ? (
            <TruckLoader />
          ) : (
            <AgGridReact
              className="ag-theme-quartz"
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rowDataFinal.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows(selectedRows);
              }}
              rowSelection="multiple"
              suppressRowClickSelection
              pagination={true}
              rowData={rowDataFinal}
              defaultColDef={defaultColDef}
              checkboxSelection
              columnDefs={columnMake}
            />
          )}
          <AddMake
            setOpen={setOpen}
            open={open}
            title={title}
            data={dataToEdit}
            handleClose={() => handleClose()}
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box className={classes.gridContantFleetList}>
          <Box className={classes.addEditGrid}>
            <Button
              variant="outlined"
              className={classes.fleetListNewButton}
              onClick={handleModelButton}
            >
              Add
            </Button>
            <SearchBar onSearch={onSearchModel} />
          </Box>
          {modelLoading ? (
            <TruckLoader />
          ) : (
            <AgGridReact
              className="ag-theme-quartz"
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rowDataFinal.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows(selectedRows);
              }}
              rowSelection="multiple"
              suppressRowClickSelection
              pagination={true}
              rowData={modelRowData}
              defaultColDef={defaultColDef}
              checkboxSelection
              columnDefs={columnFinal}
            />
          )}
          <AddModel
            setOpen={setOpenModel}
            open={openModel}
            title={modelTitle}
            data={dataToEdit}
            handleClose={() => handleClose()}
            setRefreshGrid={setRefreshGrid}
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Box className={classes.gridContantFleetList}>
          <Box className={classes.addEditGrid}>
            <Button
              variant="outlined"
              className={classes.fleetListNewButton}
              onClick={handleAddYearButton}
            >
              Add
            </Button>
            <SearchBar onSearch={onSearchYear} />
          </Box>
          {yearLoading ? (
            <TruckLoader />
          ) : (
            <AgGridReact
              className="ag-theme-quartz"
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rowDataFinal.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows(selectedRows);
              }}
              rowSelection="multiple"
              suppressRowClickSelection
              pagination={true}
              // rowData={year}
              rowData={yearRowData}
              defaultColDef={defaultColDef}
              checkboxSelection
              columnDefs={columnYear}
            />
          )}
          <AddYear
            setOpen={setOpenYear}
            open={openYear}
            title={yearTitle}
            data={dataToEdit}
            handleClose={() => handleClose()}
          />
        </Box>
      </CustomTabPanel>

      {deleteOpenMake && (
        <DeleteDialog
          open={deleteOpenMake}
          setOpen={() => setDeleteOpenMake(false)}
          deleteApi={() => handleDeleteMake()}
        />
      )}
      {deleteOpenModel && (
        <DeleteDialog
          open={deleteOpenModel}
          setOpen={() => setDeleteOpenModel(false)}
          deleteApi={() => handleDeleteModel()}
        />
      )}

      {deleteOpenYear && (
        <DeleteDialog
          open={deleteOpenYear}
          setOpen={() => setDeleteOpenYear(false)}
          deleteApi={() => handleDeleteYear()}
        />
      )}
    </Box>
  );
};
