import { postData, getData, deleteData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Company Api method declarations
 */
export const CompanyAPi = {
  getCompany(request) {
    return postData(ApiUris.companyApi.getCompany, request);
  },
  getCompanyByid(id) {
    return getData(`${ApiUris.companyApi.getCompanyById}/${id}`);
  },
  createCompany(request) {
    return postData(ApiUris.companyApi.addCompany, request);
  },
  updateCompany(request) {
    return postData(ApiUris.companyApi.updateCompany, request);
  },
  deleteCompany(id) {
    return deleteData(`${ApiUris.companyApi.deleteCompany}?id=${id}`);
  },
};
