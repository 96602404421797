export const proposedCalculation = (replacement) => {
  let dataReplace = replacement?.map((items, index) => {
    let returnValue;

    if (items.year1) {
      returnValue = { ...returnValue, year1: 1 };
    }
    if (items.year2) {
      returnValue = { ...returnValue, year2: 2 };
    }
    if (items.year3) {
      returnValue = { ...returnValue, year3: 3 };
    }
    if (items.year4) {
      returnValue = { ...returnValue, year4: 4 };
    }
    if (items.year5) {
      returnValue = { ...returnValue, year5: 5 };
    }
    return {
      ...returnValue,
    };
  });
  let array = [];
  dataReplace?.map((items, index) => {
    let year = `year${items.value}`;
    let key = Object.keys(items);
    if (year) {
      let firstIndex = key[0];
      let lastIndex = key[key?.length - 1];
      let firstIndexValue = items?.[firstIndex];
      let lastIndexValue = items?.[lastIndex];
      let yearValue = Number(lastIndexValue) - Number(firstIndexValue);
      array.push(yearValue);
    }
  });
  let sumArray = 0;
  array.map((items) => {
    sumArray += Number(items);
  });

  return sumArray;
};
