import React from "react";
import Chart from "react-apexcharts";
import {
  formatChartTooltipValue,
  getFileName,
} from "../../../common/constants/commonFunction";

export const BarChart = ({ seriesData, labels }) => {
  const series = [
    {
      name: "Net Profit",
      data: seriesData,
    },
  ];
  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: getFileName("FristYearBar_chart"),
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: getFileName("FristYearBar_chart"),
          },
          png: {
            filename: getFileName("FristYearBar_chart"),
          },
        },
        autoSelected: "zoom",
      },
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadiusApplication: "end", // 'around', 'end'
        borderRadiusWhenStacked: "last", // 'all', 'last'
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "10px",
              color: "#4F575A",
              fontWeight: "400",
            },
            formatter: function (value) {
              return "$" + Math.round(value);
            },
          },
        },
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          fontSize: 8,
          fontWeight: 600,
        },
      },
      axisBorder: {
        show: true,
        color: "#000",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      axisBorder: {
        show: true,
        width: 1,
        color: "#000",
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        formatter: function (value) {
          return Math.round(value);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => formatChartTooltipValue(val),
      },
    },
  };
  return <Chart options={options} series={series} type="bar" height={330} />;
};
