import { Edit } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { companiesColumnData } from "../../common/constants/gridRowColumn";
import { useCompany, useDealerCompany } from "../../common/hooks";
import { DeleteDialog } from "../../components/DeleteDialog";
import { SearchBar } from "../../components/SearchBar";
import CompanyForm from "../../components/dealerForm";
import { useDebounce } from "../../components/useDebounce";
import { layoutStyle } from "../layout-ui/style";
import { TruckLoader } from "./truckLoader";
import {
  actionColumnFunction,
  checkbox,
  getDefaultColDef,
} from "../../common/constants/commonFunction";

export const ManageDealerCompanies = () => {
  const classes = layoutStyle();
  const [selectedRows, setSelectedRows] = useState([]);
  const [openCompany, setOpenCompany] = useState(false);
  const [title, setTitle] = useState("Add Company");
  const [searchValue, setSearchValue] = useState("");
  const [columnFinal, setColumnFinal] = useState([]);
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [rowDataValue, setRowDataValue] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [page, setPage] = useState({ pageSize: 10000, page: 0 });
  const [editValue, setEditValue] = useState();
  const [mainData, setMainData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState("");

  const { getCompanyApi, loading, companyName, addCompanyApi } = useCompany();

  const {
    dealerCompanyData,
    getDealerCompanyApi,
    deleteCompanyDealer,
    loadingDealerCompany,
    setDealerCompanyData,
  } = useDealerCompany();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState();
  const userType = localStorage.getItem("type");
  const typeId = parseInt(localStorage.getItem("typeId"));

  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };
  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };

  const onAddBtnClick = () => {
    setOpenCompany(true);
    setTitle("Add Company");
    setEditValue(null);
  };
  const handleEditButton = (value) => {
    setOpenCompany(true);
    setTitle("Edit Company");
    setEditValue(value);
  };

  const handleImageClick = (imageUrl) => {
    setClickedImageUrl(imageUrl);
    setOpenModal(true);
  };
  const handleCloseImageModal = () => {
    setOpenModal(false);
  };
  const handleDeleteButton = async (values) => {
    setDeleteOpen(true);
    setDeleteValue(values?.dealerCompanyId);
  };

  const handleDelete = async () => {
    let payload = {
      id: deleteValue,
      dealerId: typeId,
    };
    await deleteCompanyDealer(payload);
    setDeleteOpen(false);
  };

  useEffect(() => {
    if (companiesColumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        handleDeleteButton: handleDeleteButton,
        deleteButton: true,
      });
      setColumnFinal([checkbox, ...companiesColumnData, actionColumn]);
    }
  }, [companiesColumnData, selectedRows]);

  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };
  const debouncedSearch = useDebounce(onSearchFunc, 500);
  const searchData = rowDataValue?.filter((dealerCompanyData) =>
    userType === "dealer"
      ? dealerCompanyData?.data?.data?.companyName
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase())
      : dealerCompanyData?.companyName
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase())
  );

  useEffect(() => {
    if (companyName?.length > 0 && userType === "dealer") {
      setRowDataValue([...companyName]);
    } else if (dealerCompanyData?.length > 0 && userType === "admin") {
      setRowDataValue([...dealerCompanyData]);
    }
  }, [companyName, dealerCompanyData]);

  useEffect(() => {
    if (searchValue) {
      setMainData([...searchData]);
    } else if (companyName?.length > 0) {
      setMainData([...companyName]);
    } else if (dealerCompanyData?.length > 0) {
      dealerCompanyData?.map((item) => {
        if (item.field == "companyImage") {
          item["cellRenderer"] = (item) => (
            <img
              alt="image"
              src={`data:image/jpg;base64, ${item}`}
              height="200"
              width="200"
            />
          );
        }
      });
      setMainData([...dealerCompanyData]);
    } else if (companyName?.length > 0 && userType === "dealer") {
      setMainData(companyName.map((item) => item?.data?.data));
    } else if (dealerCompanyData?.length > 0 && userType === "admin") {
      setMainData(dealerCompanyData);
    }
  }, [searchValue, companyName, dealerCompanyData]);

  useEffect(() => {
    if (userType?.toLowerCase() === "admin") {
      getCompanyApi({ limit: page.pageSize, offset: page.page });
    } else if (userType?.toLowerCase() === "dealer") {
      if (typeId) {
        let payload = {
          dealerId: typeId,
          limit: page.pageSize,
          offset: page.page,
        };
        getDealerCompanyApi(payload);
      } else {
        setDealerCompanyData([]);
      }
    }
  }, [refreshData]);
  const ImageModal = () => {
    return (
      <Modal open={openModal} onClose={handleCloseImageModal}>
        <Box
          sx={{
            position: "absolute",
            outline: "none",
            overflow: "hidden",
            margin: "20px auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              bgcolor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 24,
              borderRadius: 4,
              maxHeight: "400px",
              maxWidth: "400px",
              padding: 10,
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "rgba(0, 0, 0, 0.54)",
              }}
              onClick={handleCloseImageModal}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={`data:image/png;base64,${clickedImageUrl}`}
              alt="Company Logo"
              width={400}
              height={400}
            />
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <Box className={classes.mainContentCompany}>
      <Box className={classes.companyHeaderDealer}>
        <Box className={classes.companyMain}>
          <h3>Companies</h3>
        </Box>
        <Box className={classes.dealerAddButtonDiv}>
          <Box className={classes.dealerAddDownload}>
            <Button
              variant="outlined"
              className={[classes.fleetListNewButton, classes.fontSize12]}
              onClick={onAddBtnClick}
            >
              Add
            </Button>
          </Box>

          <Box
            style={{
              marginBottom: searchValue?.length > 2 && "-34px",
              zIndex: 1,
            }}
          >
            {searchValue?.length > 2 && (
              <Box style={{ display: "flex", marginLeft: "22px" }}>
                <Typography
                  sx={{
                    p: 1,
                    height: "22px",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    width: "100%",
                  }}
                >
                  Search Company Name For:
                  <h4 style={{ color: "blue" }}> {searchValue}</h4>
                </Typography>
              </Box>
            )}
          </Box>
          <div style={{ display: "flex" }}>
            <SearchBar onSearch={onSearch} />
          </div>
        </Box>
      </Box>

      {(userType == "dealer" ? loadingDealerCompany : loading) ? (
        <TruckLoader />
      ) : (
        <Box className={classes.gridContantDealer}>
          <AgGridReact
            className="ag-theme-quartz"
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids);
            }}
            rowSelection="multiple"
            suppressRowClickSelection
            pagination={true}
            rowData={dealerCompanyData}
            defaultColDef={defaultColDef}
            columnDefs={columnFinal}
            checkboxSelection
          />
        </Box>
      )}
      {deleteOpen && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={() => setDeleteOpen(false)}
          deleteApi={() => handleDelete()}
        />
      )}
      <ImageModal />
      <CompanyForm
        setOpen={setOpenCompany}
        open={openCompany}
        title={title}
        setRefreshData={setRefreshData}
        editValue={editValue}
      />
    </Box>
  );
};
