import React, { useState, createContext, useCallback } from "react";
import { yearApi } from "../api";

/**
 * Authentication context
 */
export const YearContext = createContext();

/**
 * Authentication data provider
 */
export const YearProvider = ({ children }) => {
  const [year, setYear] = useState();
  const [yearLoading, setYearLoading] = useState(false);
  const [yearById, setYearById] = useState();

  const getYearApi = async (values) => {
    setYearLoading(true);
    const response = await yearApi.getYear({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setYearLoading(false);
    }

    if (response?.data?.data) {
      setYear(response?.data?.data);
      setYearLoading(false);
    } else {
      setYearLoading(false);
    }

    return response.data;
  };
  const getYearByIdApi = async (id) => {
    setYearLoading(true);
    const response = await yearApi.getYearById(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setYearLoading(false);
    }

    if (response?.data?.data) {
      setYearById(response?.data?.data);
      setYearLoading(false);
    } else {
      setYearLoading(false);
      setYearById([]);
    }

    return response.data;
  };
  const createYearApi = async (request) => {
    // setYearLoading(true);
    try {
      setYearLoading(true);
      const response = await yearApi.createYear(request);
      if (response.data.succeeded === true) {
        // alert(response.data.messages.length===0?"aaaa":"bbbb");/
        getYearApi();
        setYearLoading(false);
      } else {
        setYearLoading(false);
      }
    } catch (error) {
      setYearLoading(false);
    }
  };
  const updateYearApi = async (request) => {
    // setYearLoading(true);
    try {
      setYearLoading(false);
      const response = await yearApi.updateYear(request);
      if (response.data.succeeded === true) {
        getYearApi();
        setYearLoading(false);
        return response;
      }
    } catch (error) {
      setYearLoading(false);
    }
  };

  const deleteYearByIdApi = async (id) => {
    setYearLoading(true);
    const response = await yearApi.deleteYearByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setYearLoading(false);
    }

    if (response?.data?.data) {
      getYearApi();
      setYearLoading(false);
    } else {
      setYearLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    year,
    setYear,
    yearLoading,
    setYearLoading,
    yearById,
    setYearById,
    getYearApi: useCallback((request) => {
      getYearApi(request);
    }, []),
    createYearApi: useCallback((request) => {
      createYearApi(request);
    }, []),
    updateYearApi: useCallback((request) => {
      updateYearApi(request);
    }, []),
    deleteYearApi: useCallback((request) => {
      deleteYearByIdApi(request);
    }, []),
    getYearByIdApi: useCallback((request) => {
      getYearByIdApi(request);
    }, []),
  };

  return (
    <YearContext.Provider value={contextValue}>{children}</YearContext.Provider>
  );
};
