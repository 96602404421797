import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { AdminPageStyle } from "../../pages/admin/style";

export const FuelInfo = ({
  timeoutId,
  setCycleFuelEconomy,
  setTimeoutId,
  originalFuelEconomy,
  setOriginalFuelEconomy,
  cycleFuelEconomy,
}) => {
  const classes = AdminPageStyle();

  const handleCycleFuelEconomy = (e) => {
    let data = Number(e.target.value);
    if (!e.target.value) {
      setCycleFuelEconomy(null);
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setCycleFuelEconomy(data);

    const newTimeoutId = setTimeout(() => {
      if (data > 0 && data < 5) {
        setCycleFuelEconomy(5);
      } else {
        setCycleFuelEconomy(data);
      }
      setTimeoutId(null);
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const handleFuelEconomy = (e) => {
    let data = Number(e.target.value);
    if (e.target.value) {
      setOriginalFuelEconomy(data);
    } else {
      setOriginalFuelEconomy(null);
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          fuel info
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Original Fuel Economy
                </span>
                <span className={classes.fleetListGenInfoInputText}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    type="number"
                    value={originalFuelEconomy}
                    className={classes.textinput}
                    onChange={handleFuelEconomy}
                  />
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Cycling Fuel Economy
                </span>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  type="number"
                  value={cycleFuelEconomy}
                  className={classes.textinput}
                  onChange={handleCycleFuelEconomy}
                />
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
