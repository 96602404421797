import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import Paper from "@mui/material/Paper";
import { layoutStyle } from "./style";
import { Typography, Container, Box, Button, Drawer } from "@mui/material";
import { useCompany, useUser } from "../../common/hooks";
import { showToast } from "../../components/Toast";
import { SupportModal } from "../../components/SupportModal";
import { WebVersion } from "../../common/api/apiUris";
const Footer = () => {
  const [value, setValue] = React.useState(0);
  const classes = layoutStyle();
  const [version, setVersion] = React.useState();
  const [openSupport, setOpenSupport] = React.useState(false);
  const { getUserById, userData } = useUser();
  React.useEffect(() => {
    const local = localStorage.getItem("version");
    const interval = setTimeout(() => {
      fetch("/version/version.json")
        .then((res) => res.json())
        .then((data) => {
          if (data?.version === local) {
            setVersion(data?.version);
          } else {
            setVersion(local);
          }
        });
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  const handleOpenSupport = () => {
    setOpenSupport(true);
  };
  React.useEffect(() => {
    const userId = localStorage.getItem("cashflow_admin_user_id");
    getUserById(userId);
  }, []);
  return (
    <>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 9999 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          className={classes.bottomNav}
        >
          <Container maxWidth="100%">
            <Box className={classes.footerNav}>
              <Typography
                variant="h4"
                noWrap
                component="Box"
                fontWeight="bold"
                sx={{ display: { xs: "none", sm: "block", lg: "flex" } }}
              >
                <img
                  src="/whitelogo.png"
                  width={"120px"}
                  alt="logo"
                  style={{ padding: 2 }}
                />
              </Typography>
              <Box className={classes.widthFooterVersion}>
                <div className={classes.footerVersionDiv}>
                  <span className={classes.footerVersion}>
                    &#169; Copyright 2024 One Nexus Group. All rights reserved.
                  </span>
                  <div className={classes.footerVersion}>
                    <div className={classes.footerVersionText}>
                      {WebVersion}
                    </div>
                  </div>
                </div>
              </Box>
              <Box>
                {/* <div>
                  <Button
                    className={classes.footerVersionButton}
                    onClick={handleOpenSupport}
                  >
                    Support
                  </Button>
                </div> */}
              </Box>
            </Box>
          </Container>
        </BottomNavigation>
      </Paper>
      {userData && openSupport && (
        <SupportModal
          close={() => setOpenSupport(false)}
          open={openSupport}
          userData={userData}
        />
      )}
    </>
  );
};

export default Footer;
