import { Edit } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { UploadCsvMpgDataColumn } from "../common/constants/gridRowColumn";
import { useCompany } from "../common/hooks";
import { useFuelEconomy } from "../common/hooks/fueleconomy-hook";
import { TruckLoader } from "../pages/admin/truckLoader";
import { layoutStyle } from "../pages/layout-ui/style";
import { DeleteDialog } from "./DeleteDialog";
import {
  actionColumnFunction,
  checkbox,
  getDefaultColDef,
} from "../common/constants/commonFunction";

const ShowMpgData = ({ open, setOpen }) => {
  const classes = layoutStyle();
  const [rowDataFinal, setRowDataFinal] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnFinal, setColumnFinal] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState();
  const { companyNameHeader } = useCompany();
  const {
    fuelEconomy,
    loading,
    getFuelEconomyApi,
    deleteFuelEconomyByIdApi,
    editFuelEconomyApi,
  } = useFuelEconomy();

  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState();

  const handleEditButton = (params) => {
    setEditData(params);
    setEditOpen(true);
  };

  const handleEditChange = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditSave = async () => {
    await editFuelEconomyApi(editData);
    setEditOpen(false);
  };

  const handleDeleteButton = async (values) => {
    setDeleteOpen(true);
    setDeleteValue(values?.id);
  };

  const handleDelete = async () => {
    let payload = {
      id: deleteValue,
      companyId: companyNameHeader.id,
    };
    await deleteFuelEconomyByIdApi(payload);
    setDeleteOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getFuelEconomyApi();
  }, []);

  useEffect(() => {
    if (fuelEconomy?.length > 0) {
      setRowDataFinal(fuelEconomy);
    } else {
      setRowDataFinal([]);
    }
  }, [fuelEconomy]);

  useEffect(() => {
    if (UploadCsvMpgDataColumn) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        handleDeleteButton: handleDeleteButton,
        deleteButton: true,
      });
      setColumnFinal([checkbox, ...UploadCsvMpgDataColumn, actionColumn]);
    }
  }, [UploadCsvMpgDataColumn]);

  return (
    <React.Fragment>
      <Drawer
        open={open}
        maxWidth="sm"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="modal-title" className={classes.dailogTitle}>
          Manual Fuel Economy Data
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "calc(100vh - 220px)",
              width: "100%",
            }}
          >
            {loading ? (
              <TruckLoader />
            ) : (
              <AgGridReact
                className="ag-theme-quartz"
                rowSelection="multiple"
                onSelectionChanged={(event) => {
                  const selectedNodes = event.api.getSelectedNodes();
                  const selectedRows = selectedNodes.map((node) => node.data);
                  setSelectedRows(selectedRows);
                }}
                suppressRowClickSelection
                pagination={true}
                rowData={rowDataFinal}
                columnDefs={columnFinal}
                defaultColDef={defaultColDef}
                checkboxSelection
              />
            )}
          </Box>
        </DialogContent>
      </Drawer>
      {deleteOpen && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={() => setDeleteOpen(false)}
          deleteApi={() => handleDelete()}
        />
      )}

      {editOpen && (
        <Drawer
          open={editOpen}
          onClose={() => setEditOpen(false)}
          maxWidth="sm"
          anchor="bottom"
          fullHeight
          style={{ top: "100px ", bottom: "56px !important" }}
          className="DrawerName"
        >
          <DialogTitle display={"flex"} justifyContent={"center"}>
            Edit Fuel Economy Data
          </DialogTitle>
          <DialogContent>
            <Stack>
              <Box className={classes.disFlexCenter}>
                <Typography className={classes.fontSize14}>Mpg</Typography>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className={classes.textinput}
                  sx={{ marginLeft: 3 }}
                  name="mpg"
                  value={editData?.mpg || 0}
                  onChange={handleEditChange}
                />
              </Box>
              <Box className={classes.disFlexCenter} marginTop={2}>
                <Typography className={classes.fontSize14}>Year</Typography>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className={classes.textinput}
                  sx={{ marginLeft: 3 }}
                  name="year"
                  value={editData?.year || ""}
                  onChange={handleEditChange}
                  disabled
                />
              </Box>
              <Box className={classes.disFlexCenter} marginTop={2}>
                <Typography className={classes.fontSize14}>Make</Typography>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className={classes.textinput}
                  sx={{ marginLeft: 3 }}
                  name="make"
                  value={editData?.make || ""}
                  onChange={handleEditChange}
                  disabled
                />
              </Box>
              <Box className={classes.disFlexCenter} marginTop={2}>
                <Typography className={classes.fontSize14}>Model</Typography>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className={classes.textinput}
                  sx={{ marginLeft: 3 }}
                  name="model"
                  value={editData?.model || ""}
                  onChange={handleEditChange}
                  disabled
                />
              </Box>
            </Stack>
            <Box display={"flex"} justifyContent={"center"} marginTop={5}>
              <Button
                className={classes.fleetListNewButton}
                onClick={() => setEditOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className={classes.fleetListNewButton}
                onClick={handleEditSave}
              >
                Save
              </Button>
            </Box>
          </DialogContent>
        </Drawer>
      )}
    </React.Fragment>
  );
};

export default ShowMpgData;
