import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Drive Train Api method declarations
 */
export const driveTrainListApi = {
  getDriveTrainList(request) {
    return postData(ApiUris.driveTrainListApi.getDriveTrainList, request);
  },
  getDriveTrainByid(id) {
    return getData(`${ApiUris.driveTrainListApi.getDriveTrainListById}/${id}`);
  },
//   deleteFleetListByid(id) {
//     return deleteData(`${ApiUris.fleetListApi.}?id=${id}`);
//   },
  createDriveTrainList(request) {
    return postData(ApiUris.driveTrainListApi.addDriveTrainList, request);
  },
  editDriveTrainList(request) {
    return postData(ApiUris.driveTrainListApi.updateDriveTraintList, request);
  },
};
