import CloseIcon from "@mui/icons-material/Close";
import { Drawer, Grid, InputLabel, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useYear } from "../../common/hooks";
import { layoutStyle } from "../../pages/layout-ui/style";

export default function AddYear({
  open,
  setOpen,
  title,
  data,
  setDataToEdit,
  handleClose,
}) {
  const classes = layoutStyle();
  //   const { createMakeApi, editMakeApi, makeById } = useMake();
  const { createYearApi, updateYearApi } = useYear();

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
    setValue,
  } = useForm({
    defaultValues: data && data.year ? { year: data?.year } : { year: "" },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    // resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data) {
      setValue("year", data?.year);
    } else {
      setValue("year", "");
    }
  }, [data]);

  const onSubmit = async (values) => {
    const payload = {
      ...values,
    };
    if (data?.id) {
      const editPayload = {
        ...values,
        id: data?.id,
      };

      updateYearApi(editPayload);
    } else {
      createYearApi(payload);
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <Drawer
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <DialogTitle
          className={classes.dailogTitle}
          id="customized-dialog-title"
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container className={classes.disFlexCenter}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid className={classes.companyForm}>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Year</InputLabel>
                  <TextField
                    error={errors?.name}
                    className={classes.fontSize12}
                    type="text"
                    year="year"
                    fullWidth
                    {...register("year")}
                  />
                </Grid>
                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.fleetListNewButton}
                  autoFocus
                  sx={{ mt: 3, height: "50px" }}
                >
                  {title}
                </Button>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
}
