import { Save } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useCompany,
  useFleetList,
  useFuelAnalysis,
  useFuelList,
  useMake,
  useModel,
  useReplacement,
  useStates,
  useTypeList,
  useYear,
} from "../../../common/hooks";
import { AdminPageStyle } from "../style";
import { useLocation } from "react-router-dom";
import { showToast } from "../../../components/Toast";
import { getCompanyId } from "../../../common/constants/commonFunction";
import { VehicleInfo } from "../../../components/Fuel-Analysis/VehicleInfo";
import { Paramter } from "../../../components/Fuel-Analysis/Parameter";
import { FuelInfo } from "../../../components/Fuel-Analysis/FuelInfo";
import { CycleYearInfo } from "../../../components/Fuel-Analysis/CycleyearInfo";
import { AnticipateAnnualFuelEconomy } from "../../../components/Fuel-Analysis/AnticipatedAnnuelFuelEconomy";
import { AnnualFuelCost } from "../../../components/Fuel-Analysis/AnnualFuelCost";
import { AnnualisedFuelSpend } from "../../../components/Fuel-Analysis/AnnualisedFuelSpend";

export const NewFleetListFuelAnalysis = ({ selectedContent, fleetNoValue }) => {
  const {
    fuelAnalysisList,
    createFuelAnalysisListApi,
    editFuelAnalysisApi,
    getFuelAnalysisApiList,
    fuelListFilter,
  } = useFuelAnalysis();

  const classes = AdminPageStyle();
  const { state } = useLocation();
  const { handleSubmit } = useForm();
  const { companyNameHeader, dealerNameHeader } = useCompany();
  const { fleetList } = useFleetList();
  const { getTypeListByIdApi } = useTypeList();
  const { getFuelListByIdApi } = useFuelList();
  const { typeStateById, getStateListByIdApi } = useStates();
  const { getYearByIdApi } = useYear();
  const { getMakeByIdApi } = useMake();
  const { getModelByIdApi } = useModel();
  const [fuelAnalysis, setFuelAnalysis] = useState();
  const [vehicleInfoValue, setVehicleInfoValue] = useState();
  const [currentYear, setCurrent] = useState(0);
  const [firstYear, setFirstYear] = useState(0);
  const [secondYear, setSecondYear] = useState(0);
  const [thirdYear, setThirdYear] = useState(0);
  const [fourthYear, setFourthYear] = useState(0);
  const [fifthYear, setFifthYear] = useState(0);
  const [yearData, setYearData] = useState();
  const [avgFuelPrice, setAvgFuelPrice] = useState(0);
  const [originalFuelEconomy, setOriginalFuelEconomy] = useState(0);
  const [cycleFuelEconomy, setCycleFuelEconomy] = useState(5);
  const [fuelSpendForecasted, setFuelSpendForecasted] = useState(0);
  let fleetDefaultValue = fleetNoValue
    ? fleetList?.find((item) => item?.fleetNo == fleetNoValue)
    : fleetList?.find((item) => item?.fleetNo == state?.data?.fleetNo);
  const [data, setData] = useState(fleetDefaultValue);
  const userType = localStorage.getItem("type");
  const companyId = getCompanyId(userType, companyNameHeader);
  const { getReplacementListApi, replacementList } = useReplacement();
  const [cycleYear, setCycleYear] = useState({
    year1: false,
    year2: false,
    year3: false,
    year4: false,
    year5: false,
  });

  //Annual Fuel Cost
  const [annualCostCurrent, setAnnualCostCurrent] = useState(0);
  const [currentSpendPrice, setCurrentSpendPrice] = useState(0);
  const [fuelYear1, setFuelYear1] = useState(0);
  const [fuelYear2, setFuelYear2] = useState(0);
  const [fuelYear3, setFuelYear3] = useState(0);
  const [fuelYear4, setFuelYear4] = useState(0);
  const [fuelYear5, setFuelYear5] = useState(0);
  const [editData, setEditData] = useState();
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (
      currentYear > 0 &&
      avgFuelPrice > 0 &&
      firstYear > 0 &&
      secondYear > 0 &&
      thirdYear > 0 &&
      fourthYear > 0 &&
      fifthYear > 0
    ) {
      let currentSpend =
        (vehicleInfoValue?.annualMileage / currentYear) * avgFuelPrice;
      setCurrentSpendPrice(currentSpend);
      let forecastedSpend =
        (vehicleInfoValue?.annualMileage / cycleFuelEconomy) * avgFuelPrice;
      setFuelSpendForecasted(forecastedSpend);

      let anualFuelCost =
        (vehicleInfoValue?.annualMileage / currentYear) * avgFuelPrice;
      setAnnualCostCurrent(anualFuelCost);
      let anualFuelCostY1 =
        (vehicleInfoValue?.annualMileage / firstYear) * avgFuelPrice;
      setFuelYear1(anualFuelCostY1);
      let anualFuelCostY2 =
        (vehicleInfoValue?.annualMileage / secondYear) * avgFuelPrice;
      setFuelYear2(anualFuelCostY2);
      let anualFuelCostY3 =
        (vehicleInfoValue?.annualMileage / thirdYear) * avgFuelPrice;
      setFuelYear3(anualFuelCostY3);
      let anualFuelCostY4 =
        (vehicleInfoValue?.annualMileage / fourthYear) * avgFuelPrice;
      setFuelYear4(anualFuelCostY4);
      let anualFuelCostY5 =
        (vehicleInfoValue?.annualMileage / fifthYear) * avgFuelPrice;
      setFuelYear5(anualFuelCostY5);
    } else {
      setFuelSpendForecasted(0);
      setAnnualCostCurrent(0);
      setFuelYear1(0);
      setFuelYear2(0);
      setFuelYear3(0);
      setFuelYear4(0);
      setFuelYear5(0);
    }
  }, [
    vehicleInfoValue,
    cycleFuelEconomy,
    avgFuelPrice,
    fuelAnalysisList,
    currentYear,
    firstYear,
    secondYear,
    thirdYear,
    fourthYear,
    fifthYear,
  ]);

  useEffect(() => {
    let replacementData = replacementList?.filter(
      (item) => item?.fleetNo === vehicleInfoValue?.fleetNo
    )?.[0];
    if (replacementData && vehicleInfoValue?.fleetNo) {
      setCycleYear(replacementData);
    }
  }, [replacementList, vehicleInfoValue?.fleetNo]);

  useEffect(() => {
    if (companyNameHeader) {
      if (fleetNoValue) {
        getFuelAnalysisApiList({
          companyId: companyNameHeader.id,
          fleetNo: fleetNoValue,
          dealerId: dealerNameHeader?.id,
        });
      } else {
        getFuelAnalysisApiList({
          companyId: companyNameHeader.id,
          dealerId: dealerNameHeader?.id,
        });
      }
    }
  }, [companyNameHeader, fleetNoValue, vehicleInfoValue]);

  useEffect(() => {
    if (fuelListFilter?.length > 0) {
      setFuelSpendForecasted(fuelListFilter[0]?.annualFuelSpendForecasted);
    } else if (vehicleInfoValue) {
      setFuelSpendForecasted(vehicleInfoValue.annualFuelSpendForecasted);
    }
    if (vehicleInfoValue) {
      getFuelListByIdApi(vehicleInfoValue.fuelId);
      getTypeListByIdApi(vehicleInfoValue.typeId);
      getMakeByIdApi(vehicleInfoValue.makeId);
      getModelByIdApi(vehicleInfoValue.modelId);
      getStateListByIdApi(vehicleInfoValue.stateId);
      getYearByIdApi(vehicleInfoValue.yearId);
      getFuelAnalysisApiList({
        companyId: companyId,
        fleetNo: vehicleInfoValue.fleetNo,
        dealerId: dealerNameHeader?.id,
      });
    }
  }, [vehicleInfoValue]);

  useEffect(() => {
    if (fuelListFilter?.length > 0) {
      setFuelAnalysis(fuelListFilter[0]);
    } else {
      setFuelAnalysis({});
    }
  }, [fuelListFilter]);

  useEffect(() => {
    if (data) {
      let fleetNo = fleetList?.filter((items) => items.fleetNo == data.fleetNo);
      if (fleetNo?.length > 0) {
        setVehicleInfoValue(data);
      }
      setAvgFuelPrice(editData?.averageFuelPrice);
    } else if (editData) {
      let fleetNo = fleetList?.filter(
        (items) => items.fleetNo == editData.fleetNo
      );
      if (fleetNo?.length > 0) {
        setVehicleInfoValue(fleetNo[0]);
      }
      setAvgFuelPrice(editData?.averageFuelPrice);
    }
  }, [fleetList, editData, data]);

  useEffect(() => {
    let original = originalFuelEconomy;
    let current = 0;
    if (original > 0) {
      current = original - vehicleInfoValue?.age * 0.5;
    }
    if (current > 0) {
      setCurrent(current);
    }
    if (cycleFuelEconomy > 0) {
      let year1 =
        !cycleYear.year1 && !yearData?.year1 && !editData?.cycleYear1
          ? current - 0.5
          : cycleFuelEconomy;
      let year2 =
        !cycleYear.year2 && !yearData?.year2 && !editData?.cycleYear2
          ? year1 - 0.5
          : cycleFuelEconomy;
      let year3 =
        !cycleYear.year3 && !yearData?.year3 && !editData?.cycleYear3
          ? year2 - 0.5
          : cycleFuelEconomy;
      let year4 =
        !cycleYear.year4 && !yearData?.year4 && !editData?.cycleYear4
          ? year3 - 0.5
          : cycleFuelEconomy;
      let year5 =
        !cycleYear.year5 && !yearData?.year5 && !editData?.cycleYear5
          ? year4 - 0.5
          : cycleFuelEconomy;
      setFirstYear(year1);
      setSecondYear(year2);
      setThirdYear(year3);
      setFourthYear(year4);
      setFifthYear(year5);
    } else {
      setFirstYear(0);
      setSecondYear(0);
      setThirdYear(0);
      setFourthYear(0);
      setFifthYear(0);
    }
  }, [
    vehicleInfoValue,
    fuelAnalysis,
    originalFuelEconomy,
    cycleFuelEconomy,
    yearData,
  ]);

  useEffect(() => {
    if (fuelListFilter?.length > 0) {
      if (fuelListFilter[0]?.cyclingFuelEconomy) {
        setCycleFuelEconomy(fuelListFilter[0]?.cyclingFuelEconomy);
      } else {
        setCycleFuelEconomy(0);
      }
      if (fuelListFilter[0]?.originalFuelEconomy) {
        setOriginalFuelEconomy(fuelListFilter[0]?.originalFuelEconomy);
      } else {
        setOriginalFuelEconomy(0);
      }
      setAvgFuelPrice(fuelListFilter[0]?.averageFuelPrice);
      // setCurrent()
    } else if (vehicleInfoValue) {
      setOriginalFuelEconomy(vehicleInfoValue?.originalFuelEconomy);
      setCycleFuelEconomy(vehicleInfoValue?.cyclingFuelEconomy);
    } else if (editData) {
      setOriginalFuelEconomy(editData?.originalFuelEconomy);
      setCycleFuelEconomy(editData?.cyclingFuelEconomy);
    } else {
      setOriginalFuelEconomy(0);
      setCycleFuelEconomy(0);
    }
  }, [editData, vehicleInfoValue, fuelListFilter]);

  const handleSaveFuelAnalysis = async (values) => {
    let payload = {
      fleetNo: vehicleInfoValue?.fleetNo,
      averageFuelPrice: avgFuelPrice
        ? Number(avgFuelPrice)
        : values?.averageFuelPrice
        ? values?.averageFuelPrice
        : fuelAnalysis?.averageFuelPrice,
      fuelCompanyName: values?.fuelCompanyName
        ? values?.fuelCompanyName
        : fuelAnalysis?.fuelCompanyName,
      originalFuelEconomy: originalFuelEconomy,
      cyclingFuelEconomy: cycleFuelEconomy,
      fuelPriceId: state?.data?.fuelPriceId,
      cycleYear1: editData?.cycleYear1
        ? editData?.cycleYear1
        : yearData?.year1
        ? yearData?.year1
        : cycleYear.year1,
      cycleYear2: editData?.cycleYear2
        ? editData?.cycleYear2
        : yearData?.year2
        ? yearData?.year2
        : cycleYear?.year2,
      cycleYear3: editData?.cycleYear3
        ? editData?.cycleYear3
        : yearData?.year3
        ? yearData?.year3
        : cycleYear?.year3,
      cycleYear4: editData?.cycleYear4
        ? editData?.cycleYear4
        : yearData?.year4
        ? yearData?.year4
        : cycleYear?.year4,
      cycleYear5: editData?.cycleYear5
        ? editData?.cycleYear5
        : yearData?.year5
        ? yearData?.year5
        : cycleYear?.year5,
      anticipatedFuelCostCurrent: currentYear,
      anticipatedFuelCostYear1: firstYear,
      anticipatedFuelCostYear2: secondYear,
      anticipatedFuelCostYear3: thirdYear,
      anticipatedFuelCostYear4: fourthYear,
      anticipatedFuelCostYear5: fifthYear,
      annualFuelCostCurrent: annualCostCurrent
        ? annualCostCurrent
        : editData?.annualFuelCostCurrent,
      annualFuelCostYear1: fuelYear1
        ? fuelYear1
        : editData?.annualFuelCostYear1,
      annualFuelCostYear2: fuelYear2
        ? fuelYear2
        : editData?.annualFuelCostYear2,
      annualFuelCostYear3: fuelYear3
        ? fuelYear3
        : editData?.annualFuelCostYear3,
      annualFuelCostYear4: fuelYear4
        ? fuelYear4
        : editData?.annualFuelCostYear4,
      annualFuelCostYear5: fuelYear5
        ? fuelYear5
        : editData?.annualFuelCostYear5,
      annualFuelSpendCurrent: parseInt(currentSpendPrice),
      annualFuelSpendForecasted: parseInt(fuelSpendForecasted),
    };
    if (editData) {
      payload = {
        ...payload,
        id: fuelAnalysis?.fuelAnalysisId,
        companyId: companyNameHeader.id,
        dealerId: dealerNameHeader.id,
      };
      await editFuelAnalysisApi(payload);
    } else if (vehicleInfoValue?.fleetNo) {
      await createFuelAnalysisListApi(payload);
    } else {
      showToast("Please select Fleet No!");
    }
  };

  useEffect(() => {
    setEditData(state?.data);
  }, [state?.data]);

  useEffect(() => {
    if (fleetNoValue) {
      getReplacementListApi({
        fleetNo: fleetNoValue,
        dealerId: dealerNameHeader?.id,
        companyId: companyNameHeader?.id,
      });
    }
  }, [fleetNoValue]);

  return (
    <Box className={classes.fleetListInformation}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.headerFleet}
          padding={"10px !important"}
        >
          {vehicleInfoValue ? `${selectedContent} : ` : selectedContent}
          {vehicleInfoValue?.fleetNo ? vehicleInfoValue?.fleetNo : ""}
          <Button
            className={classes.headerSaveButton}
            onClick={handleSubmit(handleSaveFuelAnalysis)}
            startIcon={<Save />}
            variant="contained"
          >
            Save
          </Button>
        </Grid>

        <VehicleInfo
          state={state}
          data={data}
          fleetList={fleetList}
          vehicleInfoValue={vehicleInfoValue}
          companyNameHeader={companyNameHeader}
          setVehicleInfoValue={setVehicleInfoValue}
          setData={setData}
        />
        <Paramter
          vehicleInfoValue={vehicleInfoValue}
          typeStateById={typeStateById}
          avgFuelPrice={avgFuelPrice}
          fuelAnalysis={fuelAnalysis}
          fuelListFilter={fuelListFilter?.length > 0 ? fuelListFilter[0] : null}
          setAvgFuelPrice={setAvgFuelPrice}
        />
        <FuelInfo
          timeoutId={timeoutId}
          setCycleFuelEconomy={setCycleFuelEconomy}
          setTimeoutId={setTimeoutId}
          originalFuelEconomy={originalFuelEconomy}
          setOriginalFuelEconomy={setOriginalFuelEconomy}
          cycleFuelEconomy={cycleFuelEconomy}
        />
        <CycleYearInfo
          yearData={yearData}
          setYearData={setYearData}
          cycleYear={cycleYear}
        />
        <AnticipateAnnualFuelEconomy
          currentYear={currentYear}
          firstYear={firstYear}
          secondYear={secondYear}
          thirdYear={thirdYear}
          fourthYear={fourthYear}
          fifthYear={fifthYear}
        />
        <AnnualFuelCost
          annualCostCurrent={annualCostCurrent}
          editData={editData}
          fuelYear1={fuelYear1}
          fuelYear2={fuelYear2}
          fuelYear3={fuelYear3}
          fuelYear4={fuelYear4}
          fuelYear5={fuelYear5}
        />
        <AnnualisedFuelSpend
          currentSpendPrice={currentSpendPrice}
          fuelSpendForecasted={fuelSpendForecasted}
        />
      </Grid>
    </Box>
  );
};
