import CloseIcon from "@mui/icons-material/Close";
import { Drawer, Grid, InputLabel, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { baseURL } from "../../common/api/apiUris";
import { useCompany, useDealerCompany } from "../../common/hooks";
import { layoutStyle } from "../../pages/layout-ui/style";

export default function AddCompany({
  open,
  setOpen,
  title,
  setRefreshData,
  editValue,
}) {
  const classes = layoutStyle();
  const [binaryImage, setBinaryImage] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [imageName, setImageName] = useState(null);
  const { getCompanyApi } = useCompany();
  const { addDealerCompanyApi } = useDealerCompany();
  const dealerId = parseInt(localStorage.getItem("cashflow_admin_user_id"));
  let data = new FormData();

  const handleClose = () => {
    setBinaryImage(null);
    setCompanyName(null);
    setOpen(false);
  };

  const defaultValue = {
    companyName: "",
    companyLogo: null,
  };
  const generateInitialValues = (props) => {
    if (editValue) {
      for (const key in defaultValue) {
        if (key in editValue && editValue[key] !== null) {
          if (Array.isArray(defaultValue[key])) {
            defaultValue[key] =
              Array.isArray(editValue[key]) && editValue[key].length > 0
                ? editValue[key]
                : defaultValue[key];
          } else {
            defaultValue[key] = editValue[key];
          }
        }
      }
    }

    return defaultValue;
  };

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: defaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    // resolver: yupResolver(schema),
  });
  const onSubmit = async () => {
    data.append("ImageFile", binaryImage);
    data.append("CompanyImage", binaryImage);
    data.append("CompanyImageName", imageName);
    data.append("CompanyName", companyName);
    data.append("Deleted", false);
    if (data && title === "Edit Company") {
      data.append("id", editValue?.id);
      axios({
        url: `${baseURL}/Company/Update`,
        data: data,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          setRefreshData(true);
        })
        .finally(async () => {
          await getCompanyApi();
        });
      // await addCompanyApi(data)
    } else {
      axios({
        url: `${baseURL}/Company/Create`,
        data: data,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          setRefreshData(true);
          // addDealerCompanyApi({
          //   dealerId: dealerId,
          //   companyId: res.data.data,
          // });
        })
        .finally(async () => {
          await getCompanyApi();
        });

      // await addCompanyApi(data)
      // setRefreshData(true);
    }
    handleClose();
  };

  useEffect(() => {
    data.delete("ImageFile");
    data.delete("CompanyName");
    data.delete("Deleted");
  }, [open]);

  useEffect(() => {
    if (editValue) {
      setCompanyName(editValue?.companyName);
    }
  }, [editValue]);

  const imageToBinary = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const binaryString = reader.result;
        resolve(binaryString);
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsBinaryString(file);
    });
  };

  const handleCompanyLogo = async (event) => {
    setImageName(event?.timeStamp);
    let file = event.target.files[0];

    try {
      const binaryData = await imageToBinary(file);
      setBinaryImage(file); // Output the binary data to console
    } catch (error) {
      console.error("Error converting image to binary:", error);
    }
  };

  const handleCompanyName = (event) => {
    setCompanyName(event.target.value);
  };

  return (
    <React.Fragment>
      <Drawer
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <DialogTitle
          className={classes.dailogTitle}
          id="customized-dialog-title"
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container className={classes.disFlexCenter}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid className={classes.companyForm}>
                <Grid className={classes.compnayFormField}>
                  <InputLabel className={classes.fontSize12}>
                    Company Name
                  </InputLabel>
                  <TextField
                    // error={errors?.companyName}
                    type="text"
                    fullWidth
                    value={companyName}
                    onChange={handleCompanyName}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel className={classes.fontSize12}>
                    Company Logo
                  </InputLabel>
                  <TextField
                    error={errors?.companyLogo}
                    type="file"
                    onChange={handleCompanyLogo}
                    fullWidth
                    inputProps={{
                      accept: "image/jpeg, image/png, image/gif, image/bmp",
                    }}
                    // {...register("companyLogo")}
                  />
                </Grid>

                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.fleetListNewButton}
                  autoFocus
                  sx={{ mt: 3, height: "50px" }}
                >
                  {title}
                </Button>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
}
