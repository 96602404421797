import { Box, Button, IconButton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { layoutStyle } from "../layout-ui/style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ManageUserColumnData } from "../../common/constants/gridRowColumn";
import { SearchBar } from "../../components/SearchBar";
import AddUserDealer from "./AddUserDealer";
import { useUser } from "../../common/hooks";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteDialog } from "./../../components/DeleteDialog";
import {
  actionColumnFunction,
  getDefaultColDef,
  userControl,
} from "../../common/constants/commonFunction";
import { USER_CONTROL } from "../../common/constants/popupMessage";
import { UserControlDialogue } from "../../components/UserControlDialog";

export const ManageUserDealer = () => {
  const classes = layoutStyle();
  const userType = localStorage.getItem("type");
  const userTypeId = localStorage.getItem("cashflow_admin_user_id");
  const [columnFinal, setColumnFinal] = useState([]);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const { getUser, user, deleteUser, loading, setUserData } = useUser();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState();
  const [userControlOpen, setUserControlOpen] = useState(false);
  const [userControlPayload, setUserControlPayload] = useState(null);

  const schema = yup.object().shape({
    name: yup.string().required("Name is required."),
    email: yup.string().required("Email is required."),
    userName: yup.string().required("Username is required."),
    phone: yup.string().required("Phone is required."),
    type: yup.string().required("Type is required."),
  });
  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const {
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleAddButton = () => {
    setOpen(true);
  };

  useEffect(() => {
    getUser({ filter: { type: userType, createdBy: userTypeId } });
  }, []);

  const handleEditButton = (data) => {
    setRowData(data);
    setOpen(true);
  };

  const handleDeleteButton = async (values) => {
    setDeleteOpen(true);
    setDeleteValue(values?.id);
  };

  const handleDeactivate = async (params) => {
    const payload = {
      id: params.id,
      status: USER_CONTROL.DEACTIVATE,
    };
    setUserControlOpen(true);
    setUserControlPayload(payload);
  };

  const handleReactivate = async (params) => {
    const payload = {
      id: params.id,
      status: USER_CONTROL.ACTIVATE,
    };
    setUserControlOpen(true);
    setUserControlPayload(payload);
  };

  const handleDelete = async () => {
    await deleteUser(deleteValue);
    setDeleteOpen(false);
  };

  useEffect(() => {
    if (ManageUserColumnData) {
      const actionColumn = actionColumnFunction({
        handleEditButton: handleEditButton,
        handleDeleteButton: handleDeleteButton,
        deleteButton: true,
      });
      const userControlColumn = userControl({
        handleDeactivate: handleDeactivate,
        handleReactivate: handleReactivate,
        classes: classes,
      });

      setColumnFinal([
        ...ManageUserColumnData,
        actionColumn,
        // userControlColumn,
      ]);
    }
  }, [ManageUserColumnData]);
  return (
    <Box className={classes.mainContent}>
      <Box className={classes.gridContantFleetList}>
        <Box className={classes.addEditGrid}>
          <Button
            variant="outlined"
            onClick={handleAddButton}
            className={classes.fleetListNewButton}
          >
            Add
          </Button>
          <SearchBar />
        </Box>
        <Box className={classes.gridContantFleetList}>
          <AgGridReact
            className="ag-theme-quartz"
            rowSelection="multiple"
            pagination={true}
            rowData={user}
            columnDefs={columnFinal}
            defaultColDef={defaultColDef}
            checkboxSelection
          />
        </Box>
        <AddUserDealer
          setOpen={setOpen}
          open={open}
          rowData={rowData}
          setRowData={setRowData}
        />
        {deleteOpen && (
          <DeleteDialog
            open={deleteOpen}
            setOpen={() => setDeleteOpen(false)}
            deleteApi={() => handleDelete()}
          />
        )}
        {userControlOpen && (
          <UserControlDialogue
            open={userControlOpen}
            setOpen={() => setUserControlOpen(false)}
            data={userControlPayload}
          />
        )}
      </Box>
    </Box>
  );
};
