import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getFileName } from "../../../common/constants/commonFunction";

export const LineChart = ({ seriesData, labels }) => {
  const [data, setData] = useState([
    seriesData?.Current,
    seriesData?.Year1,
    seriesData?.Year2,
    seriesData?.Year3,
    seriesData?.Year4,
    seriesData?.Year5,
  ]);
  const series = [
    {
      name: "",
      data: data,
    },
  ];

  useEffect(() => {
    if (seriesData) {
      setData([
        seriesData?.Current,
        seriesData?.Year1,
        seriesData?.Year2,
        seriesData?.Year3,
        seriesData?.Year4,
        seriesData?.Year5,
      ]);
    }
  }, [seriesData]);
  const getFileName = (prefix) => {
    const date = new Date();
    const timestamp = date.toISOString().replace(/[:.-]/g, "");
    return `${prefix}_${timestamp}`;
  };
  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: getFileName("Saving_chart"),
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: getFileName("Saving_chart"),
          },
          png: {
            filename: getFileName("Saving_chart"),
          },
        },
        autoSelected: "zoom",
      },
    },
    colors: ["#118DFF", "#118DFF"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },

    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Current", "Year1", "Year2", "Year3", "Year4", "Year5"],
      title: {
        text: "Fiscal Year",
      },
      axisBorder: {
        show: true,
        color: "#000",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      title: {
        text: "Savings",
      },
      // labels: {
      //   formatter: function (value) {
      //     return value;
      //   },
      // },
      axisBorder: {
        show: true,
        width: 1,
        color: "#000",
        offsetX: 0,
        offsetY: 0,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };
  return (
    data?.length > 0 && (
      <Chart options={options} series={series} type="line" height={180} />
    )
  );
};
