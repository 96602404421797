import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Switch,
} from "@mui/material";
import { AdminPageStyle } from "../../pages/admin/style";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const CycleYearInfo = ({ year1ValueData }) => {
  const classes = AdminPageStyle();

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          cycle year information
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>Year 1</span>
                <span className={classes.fleetListGenInfoTtext}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        size="small"
                        checked={year1ValueData?.year1}
                      />
                    }
                  />
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>Year 2</span>
                <span className={classes.fleetListGenInfoTtext}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        size="small"
                        checked={year1ValueData?.year2}
                      />
                    }
                  />
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>Year 3</span>
                <span className={classes.fleetListGenInfoTtext}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        size="small"
                        checked={year1ValueData?.year3}
                      />
                    }
                  />
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>Year 4</span>
                <span className={classes.fleetListGenInfoTtext}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        size="small"
                        checked={year1ValueData?.year4}
                      />
                    }
                  />
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>Year 5</span>
                <span className={classes.fleetListGenInfoTtext}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        size="small"
                        checked={year1ValueData?.year5}
                      />
                    }
                  />
                </span>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
