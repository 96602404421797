import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { AdminPageStyle } from "../../pages/admin/style";
import { addComma } from "../../common/services/number-service";

export const FleetInfo = ({
  state,
  fleetNo,
  vehicleInfoValue,
  fleetValue,
  companyNameHeader,
  setFleetNo,
  setVehicleInfoValue,
  setFleetValue,
  fleetList,
}) => {
  const classes = AdminPageStyle();

  const handleFleetChange = (event, value) => {
    setFleetValue(value?.fleetId);
    setVehicleInfoValue(value);
    setFleetNo(value?.fleetNo);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          fleet information
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Company</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {companyNameHeader?.companyName}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Fleet</span>
                <Autocomplete
                  id="combo-box-demo"
                  disablePortal
                  disabled={state?.data ? true : false}
                  className={classes.textinput}
                  sx={{ width: "148px !important" }}
                  onChange={handleFleetChange}
                  defaultValue={fleetValue}
                  options={fleetList}
                  getOptionLabel={(option) => option.fleetNo}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.fleetId}>
                        {option.fleetNo}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Make</span>
                <span>{fleetNo ? vehicleInfoValue?.make : ""}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Modal</span>
                <span>{fleetNo ? vehicleInfoValue?.model : ""}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Fuel</span>
                <span>{fleetNo ? vehicleInfoValue?.fuel : ""}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Type</span>
                <span>{fleetNo ? vehicleInfoValue?.type : ""}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  CPM Category
                </span>
                <span>{fleetNo ? vehicleInfoValue?.cpmCategory : ""}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>MPG</span>
                <span>{fleetNo ? vehicleInfoValue?.mpg : 0}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>VIN</span>
                <span>{fleetNo ? vehicleInfoValue?.vin : ""}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>State</span>
                <span>{fleetNo ? vehicleInfoValue?.state : ""}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Operational Locations
                </span>
                <span>
                  {fleetNo ? vehicleInfoValue?.operationalLocations : ""}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Vehicle Total Miles
                </span>
                <span>
                  {fleetNo
                    ? addComma(Math.round(vehicleInfoValue?.totalMiles))
                    : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Vehicle Annual Mileage
                </span>
                <span>
                  {fleetNo
                    ? addComma(Math.round(vehicleInfoValue?.annualMileage))
                    : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Vehicle Monthly Mileage
                </span>
                <span>
                  {fleetNo
                    ? addComma(Math.round(vehicleInfoValue?.monthlyMileage))
                    : 0}
                </span>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
