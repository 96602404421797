import React from "react";
import { Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { CommonStyle } from "./style";
export const DashboardCard = ({ description, image, redirect }) => {
  const classes = CommonStyle();
  const navigate = useNavigate();
  return (
    <Box
      className={`${classes.main} ${classes.mainBoxHover}`}
      onClick={() => navigate(redirect)}
    >
      <Paper className={classes.paper}>
        <Box className={classes.mainBox}>
          {image != null && (
            <Box className={classes.box1}>
              <img src={image} className={classes.svg} alt="svg" />
            </Box>
          )}
          <Typography className={classes.descSpan}>{description}</Typography>
        </Box>
      </Paper>
    </Box>
  );
};
