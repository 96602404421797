import React from "react";
import { layoutStyle } from "../layout-ui/style";
import { Box, Grid } from "@mui/material";
import { DashboardCard } from "../../components/DashboardCard";
import Chart from "../../assets/images/maintenance.png";
import Fuleimg from "../../assets/images/fuel-img.png";
import Dolorimg from "../../assets/images/funding.png";
import Replceimg from "../../assets/images/schedules.png";
import Forcastimg from "../../assets/images/analysis-img.png";
import Testimg from "../../assets/images/fleet.png";

const Dashboard = () => {
  const classes = layoutStyle();

  return (
    <>
      <Box className={classes.backgroundImage}>
        <Box className={classes.dashboardMainContent}>
          <Grid container spacing={3} mt={2}>
            <Grid item xs={12} sm={6} md={3} lg={4}>
              <DashboardCard
                description="Fleet List "
                image={Testimg}
                redirect="/fleet-list"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4}>
              <DashboardCard
                description="Replacement Schedules"
                image={Replceimg}
                redirect="/replacement-schedule"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4}>
              <DashboardCard
                description="Maintenance Analysis"
                image={Chart}
                redirect="/maintenance-analysis"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4}>
              <DashboardCard
                description="Fuel Analysis "
                image={Fuleimg}
                redirect="/fuel-analysis"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4}>
              <DashboardCard
                description="Funding/New Vehicle"
                image={Dolorimg}
                redirect="/funding-newvehicle"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4}>
              <DashboardCard
                description="Analysis Results"
                image={Forcastimg}
                redirect="/analysis-results"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
