import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Fuel List Api method declarations
 */
export const fuelListApi = {
  getFuelList(request) {
    return postData(ApiUris.fuelListApi.getFuelList, request);
  },
  getFuelListByid(id) {
    return getData(`${ApiUris.fuelListApi.getFuelListById}/${id}`);
  },
  createFuelList(request) {
    return postData(ApiUris.fuelListApi.addFuelList, request);
  },
  editFueltList(request) {
    return postData(ApiUris.fuelListApi.updateFueltList, request);
  },
  deleteFuelListByid(id) {
    return deleteData(`${ApiUris.fuelListApi.deleteFuelListById}?id=${id}`);
  },
};
