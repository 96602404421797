import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Stack } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dealerColumnData } from "../../common/constants/gridRowColumn";
import { useDealer } from "../../common/hooks/dealer-hook";
import { DeleteDialog } from "../../components/DeleteDialog";
import { SearchBar } from "../../components/SearchBar";
import { useDebounce } from "../../components/useDebounce";
import { layoutStyle } from "../layout-ui/style";
import AddDealer from "./AddDealer.";
import { TruckLoader } from "./truckLoader";
import {
  actionColumnFunction,
  checkbox,
  getDefaultColDef,
} from "../../common/constants/commonFunction";

export const ManageDealer = () => {
  const classes = layoutStyle();
  const [rowData, setRowData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnFinal, setColumnFinal] = useState([]);
  const [anchorElPrint, setAnchorElPrint] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElGroupBy, setAnchorElGroupBy] = useState(null);
  const [anchorElFavorites, setAnchorElFavorites] = useState(null);
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState({ pageSize: 10000, page: 0 });
  const [open, setOpen] = useState(false);
  const [openDealer, setOpenDealer] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState();

  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };

  const searchData = rowData?.filter((company) =>
    company?.dealerName?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const debouncedSearch = useDebounce(onSearchFunc, 500);
  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const navigation = useNavigate();
  const [refreshData, setRefreshData] = useState(false);

  const {
    // getDealerApi,
    setDealerById,
    dealer,
    deleteDealerByIdApi,
    getDealerApi,
    loading,
    dealerList,
  } = useDealer();

  const handleClose = () => {
    setAnchorElPrint(null);
    setAnchorElSettings(null);
    setAnchorElFilter(null);
    setAnchorElGroupBy(null);
    setAnchorElFavorites(null);
  };
  const handleModelClose = () => {
    setOpen(false);
    setEditData(null);
    setDealerById(null);
  };
  useEffect(() => {
    if (searchValue) {
      setRowData([...searchData]);
    } else if (dealer?.length > 0) {
      setRowData([...dealer]);
    }
  }, [searchValue, dealer]);

  useEffect(() => {
    getDealerApi({ limit: page.pageSize, offset: page.page });
    setEditData();
  }, [refreshData]);

  const handleEditButton = (data) => {
    setEditData(data);
    setOpen(true);
  };
  const handleDeleteButton = async (values) => {
    setDeleteOpen(true);
    setDeleteValue(values?.id);
  };

  const handleDelete = async () => {
    await deleteDealerByIdApi(deleteValue);
    setDeleteOpen(false);
  };

  useEffect(() => {
    if (dealerColumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        handleDeleteButton: handleDeleteButton,
        deleteButton: true,
      });
      if (dealerColumnData.length > 0) {
        dealerColumnData?.map((items) => {
          if (items.field == "companies") {
            items["cellRenderer"] = (params, index) => {
              if (params.data.companies?.length > 0) {
                return (
                  <>
                    <span key={index}>
                      {params.data.companies?.map((items, index) => {
                        if (
                          params.data.companies.length > 1 &&
                          index != params.data.companies.length - 1
                        ) {
                          return (
                            <span
                              style={{
                                border: "1px solid gray",
                                borderRadius: "20px",
                                padding: "10px",
                              }}
                            >
                              {items?.companyName}
                            </span>
                          );
                        } else {
                          return (
                            <span
                              style={{
                                border: "1px solid gray",
                                borderRadius: "20px",
                                padding: "10px",
                              }}
                            >
                              {items?.companyName}
                            </span>
                          );
                        }
                      })}
                    </span>
                  </>
                );
              }
            };
          }
        });
      }

      setColumnFinal([checkbox, ...dealerColumnData, actionColumn]);
    }
  }, [dealerColumnData, selectedRows]);
  const onDelete = (value) => {
    // deleteDealerByIdApi();
    handleClose();
  };
  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };
  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };

  const handleManageDealer = () => {
    setOpenDealer(false);
    navigation("/manage-company-dealer");
  };

  return (
    <Box className={classes.mainContentCompany}>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Box className={classes.headerNameText}>Dealers</Box>

        <Box className={classes.addEditGrid}>
          <Button
            variant="outlined"
            className={classes.fleetListNewButton}
            style={{ fontSize: "12px !important " }}
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
          <SearchBar onSearch={onSearch} />
        </Box>
      </Stack>
      {loading ? (
        <TruckLoader />
      ) : (
        <Box className={classes.gridContantDealer}>
          <AgGridReact
            className="ag-theme-quartz"
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids);
            }}
            rowSelection="multiple"
            suppressRowClickSelection
            pinnedColumns={{ right: ["action"] }}
            pagination={true}
            rowData={rowData}
            defaultColDef={defaultColDef}
            columnDefs={columnFinal}
            checkboxSelection
          />
        </Box>
      )}
      <AddDealer
        setOpen={setOpen}
        open={open}
        editValue={editData}
        handleClose={() => handleModelClose()}
      />
      {/* {openDealer && (
        <AddDealerCompany
          setRefreshData={setRefreshData}
          setOpenDealer={setOpenDealer}
          openDealer={openDealer}
        />
      )} */}
      {deleteOpen && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={() => setDeleteOpen(false)}
          deleteApi={() => handleDelete()}
        />
      )}
    </Box>
  );
};

{
  /* <Button
              variant="outlined"
              className={classes.fleetListNewButton}
              style={{ fontSize: "12px !important " }}
              onClick={() => setOpenDealer(true)}
            >
              Assign Company
            </Button>
            <Button
              variant="outlined"
              className={classes.fleetListNewButton}
              style={{ fontSize: "12px !important " }}
              onClick={() => handleManageDealer()}
            >
              Company/Dealer
            </Button> */
}
{
  /* <FileDownloadIcon
              style={{ cursor: "pointer", color: "#18333A" }}
              // onClick={() => handleEditButton(params.row)}
            /> */
}

{
  /* <Box className={classes.companyButtonGroup}>
          {selectedRows?.length > 0 && (
            <>
              <Box className={classes.companySubButton}>
                <Button
                  id="print-button"
                  aria-controls={anchorElPrint ? "print-menu" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickPrint}
                  style={{ color: "black" }}
                >
                  <PrintIcon />
                  <span>Print</span>
                </Button>
                <Menu
                  id="print-menu"
                  anchorEl={anchorElPrint}
                  open={Boolean(anchorElPrint)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    Preview Internal Report
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    Preview Internal Report
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    Report Layout Preview
                  </MenuItem>
                </Menu>
              </Box>
              <Box className={classes.companySubButton}>
                <Button
                  id="settings-button"
                  aria-controls={anchorElSettings ? "settings-menu" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickSettings}
                  style={{ color: "black" }}
                >
                  <SettingsIcon />
                  <span>Action</span>
                </Button>
                <Menu
                  id="settings-menu"
                  anchorEl={anchorElSettings}
                  open={Boolean(anchorElSettings)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Export</MenuItem>
                  <MenuItem onClick={handleClose}>Archive</MenuItem>
                  <MenuItem onClick={handleClose}>Unarchive</MenuItem>
                  <MenuItem onClick={onDelete}>Delete</MenuItem>
                </Menu>
              </Box>
            </>
          )}
        </Box> */
}
