import { Save } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useCompany,
  useFleetList,
  useMaintainance,
  useReplacement,
} from "../../../common/hooks";
import { AdminPageStyle } from "../style";
import { FleetInfo } from "../../../components/Maintenance-Analysis/FleetInfo";
import { CPMInfo } from "../../../components/Maintenance-Analysis/CPMInfo";
import { CycleYearInfo } from "../../../components/Maintenance-Analysis/CycleYearInfo";

export const NewFleetListMaintenanceAnalysis = ({ selectedContent }) => {
  const classes = AdminPageStyle();
  const { companyNameHeader, dealerNameHeader } = useCompany();
  const [vehicleInfoValue, setVehicleInfoValue] = useState();
  const { fleetList, getFleetListApi } = useFleetList();
  const [costPerMile, setCostPerMile] = useState(0);
  const [currentYearTotalCost, setCurrentYearTotalCost] = useState(0);
  const [firstYearMiles, setFirstYearMiles] = useState(0);
  const [secondYearMiles, setSecondYearMiles] = useState(0);
  const [thirdYearMiles, setThirdYearMiles] = useState(0);
  const [fourthYearMiles, setFourthYearMiles] = useState(0);
  const [fifthYearMiles, setFifthYearMiles] = useState(0);
  const [selectYear, setSelectYear] = useState();
  const { state } = useLocation();
  const [editData, setEditData] = useState(state?.data);
  const [fleetNo, setFleetNo] = useState(editData?.fleetNo);
  const [fleetValue, setFleetValue] = useState(state?.data);
  const [totalCostFirstYear, setTotalCostFirstYear] = useState(0);
  const [totalCostSecondYear, setTotalCostSecondYear] = useState(0);
  const [totalCostThirdYear, setTotalCostThirdYear] = useState(0);
  const [totalCostFourthYear, setTotalCostFourthYear] = useState(0);
  const [totalCostFifthYear, setTotalCostFifthYear] = useState(0);
  const [year1ValueData, setYear1ValueData] = useState({
    year1: editData?.year1 || false,
    year2: editData?.year2 || false,
    year3: editData?.year3 || false,
    year4: editData?.year4 || false,
    year5: editData?.year5 || false,
  });
  const { getReplacementListApi, replacementList } = useReplacement();

  useEffect(() => {
    if (companyNameHeader) {
      if (fleetNo) {
        getReplacementListApi({
          fleetNo: fleetNo,
          companyId: companyNameHeader.id,
          dealerId: dealerNameHeader?.id,
        });
      }
    }
  }, [companyNameHeader, fleetNo]);

  useEffect(() => {
    if (state?.data || replacementList) {
      if (replacementList?.length > 0) {
        setYear1ValueData({
          year1: replacementList[0]?.year1,
          year2: replacementList[0]?.year2,
          year3: replacementList[0]?.year3,
          year4: replacementList[0]?.year4,
          year5: replacementList[0]?.year5,
        });
      } else {
        setYear1ValueData({
          year1: state?.data?.year1,
          year2: state?.data?.year2,
          year3: state?.data?.year3,
          year4: state?.data?.year4,
          year5: state?.data?.year5,
        });
      }
    }
  }, [state, replacementList]);

  const {
    createMainrainanceApi,
    editMaintainanceApi,
    getMaintananceApi,
    maintanance,
  } = useMaintainance();
  useEffect(() => {
    if (companyNameHeader) {
      if (fleetNo) {
        getMaintananceApi({
          fleetNo: fleetNo,
          companyId: companyNameHeader.id,
          dealerId: dealerNameHeader?.id,
        });
      } else {
        getFleetListApi({
          companyId: companyNameHeader.id,
          dealerId: dealerNameHeader?.id,
        });
      }
    }
  }, [companyNameHeader, fleetNo]);

  useEffect(() => {
    if (maintanance?.length > 0) {
      setVehicleInfoValue(maintanance[0]);
      setFirstYearMiles(maintanance[0]?.year_1_CostPerMile);
      setSecondYearMiles(maintanance[0]?.year_2_CostPerMile);
      setThirdYearMiles(maintanance[0]?.year_3_CostPerMile);
      setFourthYearMiles(maintanance[0]?.year_4_CostPerMile);
      setFifthYearMiles(maintanance[0]?.year_5_CostPerMile);
      setTotalCostFirstYear(maintanance[0]?.year_1_TotalCost);
      setTotalCostSecondYear(maintanance[0]?.year_2_TotalCost);
      setTotalCostThirdYear(maintanance[0]?.year_3_TotalCost);
      setTotalCostFourthYear(maintanance[0]?.year_4_TotalCost);
      setTotalCostFifthYear(maintanance[0]?.year_5_TotalCost);
      setCostPerMile(maintanance[0]?.currentCostPerMile);
      setCurrentYearTotalCost(maintanance[0]?.currentYearTotalCost);
    } else if (editData) {
      let fleetNo = fleetList?.filter(
        (items) => items.fleetNo == editData?.fleetNo
      );
      if (fleetNo?.length > 0) {
        setVehicleInfoValue(fleetNo[0]);
      }

      setFirstYearMiles(editData?.year_1_CostPerMile);
      setSecondYearMiles(editData?.year_2_CostPerMile);
      setThirdYearMiles(editData?.year_3_CostPerMile);
      setFourthYearMiles(editData?.year_4_CostPerMile);
      setFifthYearMiles(editData?.year_5_CostPerMile);
      setTotalCostFirstYear(editData?.year_1_TotalCost);
      setTotalCostSecondYear(editData?.year_2_TotalCost);
      setTotalCostThirdYear(editData?.year_3_TotalCost);
      setTotalCostFourthYear(editData?.year_4_TotalCost);
      setTotalCostFifthYear(editData?.year_5_TotalCost);
      setCostPerMile(editData?.currentCostPerMile);
      setCurrentYearTotalCost(editData?.currentYearTotalCost);
    }
  }, [companyNameHeader, editData, maintanance]);

  const handleSaveMaintance = async () => {
    let costPerMileData = {
      companyId: companyNameHeader.id,
      ...vehicleInfoValue,
      ...selectYear,
      currentCostPerMile: costPerMile,
      currentYearTotalCost: currentYearTotalCost,
      year_1_CostPerMile: Number(firstYearMiles),
      year_2_CostPerMile: Number(secondYearMiles),
      year_3_CostPerMile: Number(thirdYearMiles),
      year_4_CostPerMile: Number(fourthYearMiles),
      year_5_CostPerMile: Number(fifthYearMiles),
      year_1_TotalCost: totalCostFirstYear,
      year_2_TotalCost: totalCostSecondYear,
      year_3_TotalCost: totalCostThirdYear,
      year_4_TotalCost: totalCostFourthYear,
      year_5_TotalCost: totalCostFifthYear,
    };
    if (editData) {
      let maintananceId =
        maintanance?.length > 0 ? maintanance[0]?.maintenanceId : 0;
      costPerMileData = {
        ...costPerMileData,
        id: maintananceId,
      };
      await editMaintainanceApi(costPerMileData);
    } else {
      await createMainrainanceApi(costPerMileData);
    }
  };

  useEffect(() => {
    if (vehicleInfoValue) {
      let yearCurrentTotal = Number(
        costPerMile * vehicleInfoValue?.annualMileage
      );
      let year1Total = Number(firstYearMiles * vehicleInfoValue?.annualMileage);
      let year2Total = Number(
        secondYearMiles * vehicleInfoValue?.annualMileage
      );
      let year3Total = Number(thirdYearMiles * vehicleInfoValue?.annualMileage);
      let year4Total = Number(
        fourthYearMiles * vehicleInfoValue?.annualMileage
      );
      let year5Total = Number(fifthYearMiles * vehicleInfoValue?.annualMileage);
      setCurrentYearTotalCost(yearCurrentTotal);
      setTotalCostFirstYear(year1Total);
      setTotalCostSecondYear(year2Total);
      setTotalCostThirdYear(year3Total);
      setTotalCostFourthYear(year4Total);
      setTotalCostFifthYear(year5Total);
    }
  }, [
    costPerMile,
    firstYearMiles,
    secondYearMiles,
    thirdYearMiles,
    fourthYearMiles,
    fifthYearMiles,
  ]);

  return (
    <Box>
      <Box className={classes.fleetListInformation}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.headerFleet}
            padding={"10px !important"}
          >
            {fleetNo ? `${selectedContent} : ` : selectedContent}
            {fleetNo ? vehicleInfoValue?.fleetNo : ""}
            <Button
              className={classes.headerSaveButton}
              onClick={handleSaveMaintance}
              startIcon={<Save />}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
          <FleetInfo
            state={state}
            fleetNo={fleetNo}
            vehicleInfoValue={vehicleInfoValue}
            fleetValue={fleetValue}
            companyNameHeader={companyNameHeader}
            setFleetNo={setFleetNo}
            setVehicleInfoValue={setVehicleInfoValue}
            setFleetValue={setFleetValue}
            fleetList={fleetList}
          />
          <CPMInfo
            fleetNo={fleetNo}
            costPerMile={costPerMile}
            currentYearTotalCost={currentYearTotalCost}
            setCostPerMile={setCostPerMile}
            setFirstYearMiles={setFirstYearMiles}
            setSecondYearMiles={setSecondYearMiles}
            setThirdYearMiles={setThirdYearMiles}
            setFourthYearMiles={setFourthYearMiles}
            setFifthYearMiles={setFifthYearMiles}
            firstYearMiles={firstYearMiles}
            totalCostFirstYear={totalCostFirstYear}
            secondYearMiles={secondYearMiles}
            totalCostSecondYear={totalCostSecondYear}
            thirdYearMiles={thirdYearMiles}
            totalCostThirdYear={totalCostThirdYear}
            fourthYearMiles={fourthYearMiles}
            totalCostFourthYear={totalCostFourthYear}
            fifthYearMiles={fifthYearMiles}
            totalCostFifthYear={totalCostFifthYear}
          />
          <CycleYearInfo
            state={state}
            setSelectYear={setSelectYear}
            selectYear={selectYear}
            fleetNo={fleetNo}
            year1ValueData={year1ValueData}
          />
        </Grid>
      </Box>
    </Box>
  );
};
