import React, { Fragment, useEffect } from "react";
import { ThemeProvider } from "../../common/contexts";
import { AppContextWrapper } from "./App-contextWrapper";
import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Starred from "./Starred";
import Dashboard from "../admin/Dashboard";
import { FleetList } from "../admin/Fleet-list";
import { FuelEconomy } from "../admin/Fuel-Economy";
import { FuelPrice } from "../admin/Fuel-price";
import { MaintenanceAnalysis } from "../admin/Maintance-analysis";
import { ManageCompanies } from "../admin/Manage-companies";
import { ReplacementSchedule } from "../admin/Replacement-schedule";
import { Reports } from "../admin/Reports";
import { FuelAnalysis } from "../admin/Fuel-analysis";
import { AnalysisResults } from "../admin/Analysis-results";
import { FundingNewVehicle } from "../admin/Funding-newvehicle";
import { EditFleetList } from "../admin/editFleetList";
import { AddFleetList } from "../admin/addFleetList";
import { ManageDealer } from "../admin/Manage-Dealer";
import { ManageVehicle } from "../admin/Manage-Vehicle";
import { ManageUser } from "../admin/Manage-User";
import { Login } from "../login/Login";
import { Profile } from "../admin/profile";
import AddUser from "../admin/AddUser";
import { AddDealer } from "../admin/AddDealer";
import { NewFleetList } from "../admin/FleetList/NewFleet-list";
import { ManageUserDealer } from "../admin/ManageUserDealer";
import { ManageCompanyDealer } from "../admin/company-dealer";
import { ManageDealerCompanies } from "../admin/ManageDealerCompanies";
import { PowerBiReport } from "../admin/PowerBiReport";
import { AnalysisResults1 } from "../admin/Analysis-results-1";
import NotFound from "../../components/NotFoundPage";
import { OtpScreen } from "../login/otpScreen";
import { showToast } from "../../components/Toast";
import { Vincentric } from "../admin/Vincentric";

export const App = () => {
  useEffect(() => {
    const local = localStorage.getItem("version");
    const interval = window.setInterval(() => {
      fetch("/version/version.json")
        .then((res) => res.json())
        .then((data) => {
          if (data?.version !== local && local !== null) {
            localStorage.setItem("version", data?.version);
            showToast("New Version Available, Site will reload", "info");
            window.location.reload();
          } else {
            localStorage.setItem("version", data?.version);
          }
        });
    }, 5000);
    return () => window.clearInterval(interval);
  }, []);
  return (
    <ThemeProvider>
      <AppContextWrapper>
        <Routes>
          <Fragment>
            <Route path="/" element={<Login />} />
            <Route path="otpScreen" element={<OtpScreen />} />
            <Route element={<Layout />}>
              <Route path="Dashboard" element={<Dashboard />} />
              <Route path="starred" element={<Starred />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="addFleetList" element={<AddFleetList />} />
              <Route path="editFleetList" element={<EditFleetList />} />
              <Route path="fuel-economy" element={<FuelEconomy />} />
              <Route path="fuel-price" element={<FuelPrice />} />
              <Route path="manage-companies" element={<ManageCompanies />} />
              <Route path="manage-dealer" element={<ManageDealer />} />
              <Route
                path="manage-company-dealer"
                element={<ManageCompanyDealer />}
              />
              <Route path="add-dealer" element={<AddDealer />} />
              <Route path="manage-user" element={<ManageUser />} />
              <Route path="manage-vehicle" element={<ManageVehicle />} />
              <Route path="reports" element={<Reports />} />
              <Route path="fuel-analysis" element={<FuelAnalysis />} />
              <Route path="analysis-results" element={<AnalysisResults />} />
              <Route path="analysisReport" element={<AnalysisResults1 />} />
              <Route path="profile" element={<Profile />} />
              <Route path="fleet-list" element={<FleetList />} />
              <Route path="new-fleetlist" element={<NewFleetList />} />
              <Route path="addUser" element={<AddUser />} />
              <Route path="manage-userDealer" element={<ManageUserDealer />} />
              <Route
                path="manage-dealer-companies"
                element={<ManageDealerCompanies />}
              />
              <Route
                path="funding-newvehicle"
                element={<FundingNewVehicle />}
              />
              <Route
                path="replacement-schedule"
                element={<ReplacementSchedule />}
              />
              <Route
                path="maintenance-analysis"
                element={<MaintenanceAnalysis />}
              />
              <Route path="powerBi-Report" element={<PowerBiReport />} />
              <Route path="*" element={<NotFound />} />
              <Route path="analysis-data" element={<Vincentric />} />
            </Route>
          </Fragment>
        </Routes>
      </AppContextWrapper>
    </ThemeProvider>
  );
};
