import { makeStyles } from "@mui/styles";

const drawerWidth = 250;
// const headerHeight = 65;
// const newWidth = 400;
export const layoutStyle = makeStyles((theme) => {
  return {
    appBar: {
      width: `calc(100% - ${drawerWidth + 20}px)!important`,
      // width: "calc(100% - 318px) !important",
      marginLeft: drawerWidth + 20,
      transition:
        "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
      flexDirection: "column !important",
      boxSizing: "border-box !important",
      flexShrink: 0,
      position: "relative",
      zIndex: 1100,
      left: "auto",
      right: "0px !important",
      boxShadow: "none !important",
      backdropFilter: "none !important",
      color: "#9BA323 !important",
      top: "0px",
      minHeight: "4.6875rem",
      display: "grid !important",
      alignItems: "center",
      borderRadius: "0.75rem",
      padding: "0.5rem 0.5rem 0.5rem 1rem",
      backgroundColor: "transparent !important",
      "&::after": {
        transition: "box-shadow 300ms cubic- bezier(0.4, 0, 0.2, 1) 0ms",
        flexDirection: "column",
        width: "100 %",
        boxSizing: "border-box",
        flexShrink: "0",
        position: "sticky",
        zIndex: "1100",
        left: "auto",
        right: "0px",
        boxhadow:
          "rgb(255 255 255 / 90 %) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 5 %) 0rem 1.25rem 1.6875rem 0rem",
        backdropFilter: "saturate(200 %) blur(1.975rem)",
        backgroundColor: "#9BA323 !important",
        color: "rgb(52, 71, 103)",
        top: "0.75rem",
        minHeight: "4.6875rem",
        display: "grid",
        webkitBoxAlign: "center",
        alignItems: "center",
        borderRadius: "0.75rem",
        padding: "0.5rem 0px",
      },
    },
    appBarShift: {
      //   [theme.breakpoints.down("md")]: {
      //     width: "calc(100% - 24px) !important",
      //     right: "12px !important",
      //   },
      //   width: "calc(100% - 261px) !important",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      flexDirection: "column !important",
      boxSizing: "border-box !important",
      flexShrink: 0,
      position: "fixed",
      zIndex: 1100,
      left: "auto !important",
      right: "-6px !important",
      boxShadow: "none !important",
      // boxShadow:
      //   "rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem !important",
      // backdropFilter: "saturate(200%) blur(1.875rem) !important",
      color: "white !important",
      top: "-10px !important",
      minHeight: "4.6875rem !important",
      display: "grid !important",
      alignItems: "center",
      // borderRadius: "0.75rem",
      padding: "0.5rem 0.5rem 0.5rem 1rem",
      // backgroundColor: "transparent !important",

      marginTop: 10,
      marginRight: 6,
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}) !important`,
        marginLeft: drawerWidth + 30,
      },
    },
    footerShift: {
      //   [theme.breakpoints.down("md")]: {
      //     width: "calc(100% - 24px) !important",
      //     right: "12px !important",
      //   },
      //   width: "calc(100% - 261px) !important",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      flexDirection: "column !important",
      boxSizing: "border-box !important",
      flexShrink: 0,
      position: "fixed",
      zIndex: 1100,
      left: "auto !important",
      right: "-6px !important",
      boxShadow: "none !important",
      // boxShadow:
      //   "rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem !important",
      // backdropFilter: "saturate(200%) blur(1.875rem) !important",
      color: "white !important",
      minHeight: "4.6875rem !important",
      display: "grid !important",
      alignItems: "center",
      // borderRadius: "0.75rem",
      padding: "0.5rem 0.5rem 0.5rem 1rem",
      // backgroundColor: "transparent !important",
      backgroundColor: "#18333A !important",
      marginTop: 30,
      marginRight: 6,
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}) !important`,
        marginLeft: drawerWidth + 30,
      },
    },
    drawerPaper: {
      // width: "15.625rem",
      flex: "0 0 auto",
      whiteSpace: "nowrap",
      border: "none",

      //   "& .css-4t3x6l-MuiPaper-root-MuiDrawer-paper": {
      //     marginTop: "100px !important",
      //   },
      "& .MuiDrawer-paper": {
        boxShadow: "rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem inherit",
        transform: "translateX(0px)",
        transition:
          "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
        // left: 0,
        marginBottom: "inherit",
        width: "calc(285px - 30px) !important",
        // background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
        // background: "linear-gradient(222deg, rgb(57, 57, 88), rgb(35, 38, 50))",
        backgroundColor: "white",
        border: "none",
        color: "rgba(0, 0, 0, 0.87)",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        flex: "1 0 auto",
        zIndex: 1200,
        position: "fixed",
        top: 15,
        outline: "0px",
        fontSize: "22px !important",
        left: "-14px",
        padding: "1px 0px 1px 0px",
        height: "calc(100vh - 70px)",
        // borderRadius: "0.75rem !important",
        margin: "0em 0em",
        // padding: "0em 10px",
        // marginTop: "64px !important",
      },
    },
    box: {
      padding: "0px 0px ",
      textAlign: "center",
      opacity: 1,
      background: "#fff",
      color: "#443E66",
      boxShadow: "none",
      cursor: "pointer",
      height: "90%",
      borderRight: "solid",
      borderColor: "#9CBF2E !important",
      borderWidth: "2px",
      fontSize: "22px !important",
      "& .css-cveggr-MuiListItemIcon-root": {
        color: "#443E66",
      },
      "& .css-10hburv-MuiTypography-root": {
        fontSize: "12px !important",
      },
    },
    divider: {
      flexShrink: 0,
      borderTop: "0px solid rgba(0, 0, 0, 0.08)",
      borderRight: "0px solid rgba(0, 0, 0, 0.08)",
      borderLeft: "0px solid rgba(0, 0, 0, 0.08)",
      height: "0.0625rem",
      margin: "1rem 0px",
      borderBottom: "none",
      opacity: 0.25,
      backgroundColor: "transparent",
      backgroundImage:
        "linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgba(255, 255, 255, 0)) !important",
    },
    mainContent: {
      // height: `calc(100vh - ${headerHeight}px)`,
      // width: `calc(100% - ${newWidth + 40}px)`,
      // marginLeft: newWidth + 30,
      // marginTop: 200,
      // backgroundColor: "red",
      height: `calc(100vh - 121px)`,
      marginTop: "60px",
      marginLeft: "240px",
      padding: 20,
      paddingLeft: 5,
      // background: "white",
    },
    dashboardMainContent: {
      height: `calc(100vh - 121px)`,
      marginTop: "60px",
      marginLeft: "240px",
      padding: 20,
      paddingLeft: 5,
      background: "#0000005c",
      // backgroundRepeat: "no-repeat",
      // backgroundImage: `url(${dashboard})`,
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "cover",
    },
    backgroundVideo: {
      width: "100%",
      position: "absolute",
      objectFit: "cover",
      transitionTimingFunction: "ease",
      backgroundImage: "Black",
      zIndex: "-111",
    },
    backgroundImage: {
      backgroundImage: `url("/bg-dashboard.jpg")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
      position: "relative",
    },
    mainContentCompany: {
      // height: `calc(100vh - ${headerHeight}px)`,
      // width: `calc(100% - ${newWidth + 40}px)`,
      // marginLeft: newWidth + 30,
      // marginTop: 200,
      // backgroundColor: "red",
      height: `calc(100vh - 215px)`,
      marginTop: "65px",
      marginLeft: "245px",
      padding: 20,
      paddingLeft: 5,
    },
    gridContant: {
      height: `calc(100vh - 180px)`,
      marginTop: "10px",
      fontSize: "12px !important",
    },
    dashButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "#645f5f !important",
      borderColor: "#645f5f !important",
    },
    mailColor: {
      color: "red",
    },
    headerIcon: {
      "&.css-1guk29": {
        color: "black",
      },
    },
    uploadButton: {
      // marginBottom: "5px",
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
    },
    importCsv: {
      marginBottom: "5px",
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      color: "white !important",
      background: "black !important",
      marginLeft: "2px !important",
    },
    gridChart: {
      marginTop: 20,
    },
    chartLabel: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px",
      fontWeight: 700,
      color: "black",
    },
    fleetListButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#645f5f !important",
      background: "black !important",
    },
    fleetListNewButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#18333A !important",
      background: "#18333A !important",
    },
    addEditGrid: {
      justifyContent: "end",
      display: "flex",
      alignItems: "center",
      marginBottom: "5px !important",
    },
    addFleetListButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#645f5f !important",
      background: "black !important",
      height: "40px",
    },
    buttonReactive: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#645f5f !important",
      background: "#8ea83e !important",
      height: "20px !important",
    },
    fontSize12: {
      fontSize: "12px !important",
    },
    fontSize14: {
      fontSize: "14px !important",
    },
    svg: {
      userSelect: "none",
      width: "80px !important",
      height: "80px !important",
      overflow: "hidden",
      display: "inline-block",
      textAlign: "center",
      flexShrink: 0,
      // fontAize: "1.5rem",
    },
    bottomNav: {
      backgroundColor: "#0c0e0d !important",
    },
    footerNav: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "white",
      fontSize: "12px",
      height: "100%",
    },
    urlDivFuelPrice: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "14px",
      margin: 10,
    },
    dealerAddButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      padding: 2,
      fontSize: 12,
      borderColor: "#645f5f !important",
      background: "black !important",
    },
    dealerAddButtonDiv: {
      // padding: "0px 0px",
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      width: "100%",
      marginBottom: "5px",
    },
    dealerAddDownload: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "12px !important",
    },
    dealerFilterIcon: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "12px",
    },
    dealerIconPadding: {
      paddingRight: 10,
    },
    gridContantDealer: {
      height: `calc(100vh - 240px)`,
      marginTop: "1px",
      fontSize: "12px !important",
    },
    gridContantFleetList: {
      height: `calc(100vh - 240px)`,
      marginTop: "10px",
      fontSize: "12px !important",
    },
    gridContantFleetListPage: {
      height: `calc(100vh - 280px)`,
      marginTop: "10px",
      fontSize: "12px !important",
    },
    companyHeader: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: "0px",
      marginTop: "0px",
      padding: "0px",
    },
    companyHeaderDealer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "0px",
      marginTop: "0px",
      padding: "0px",
    },
    companyAddBtn: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "10px",
    },
    companyMain: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    companyButtonGroup: {
      display: "flex",
      justifyContent: "space-between",
      gap: "5px",
      fontSize: "12px",
      maxHeight: "20px !important",
    },
    companySubButton: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
      cursor: "pointer",
    },
    companyForm: {
      display: "flex",
      flexDirection: "column !important",
      gap: "25px",
      margin: 15,
      width: "30vw",
      marginY: 1,
      paddingX: 2,
    },
    compnayFormField: {
      display: "flex",
      flexDirection: "column  !important",
      gap: "5px",
    },

    buttonGroup: {
      fontSize: "12px !important",
    },
    cardImage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    gridContantEconomy: {
      height: `calc(100vh - 240px)`,
      marginTop: "10px",
      fontSize: "12px !important",
    },
    MuiDataGrid: {
      styleOverrides: {
        sortIcon: {
          color: "red",
        },
      },
    },
    ModelForm: {
      width: "100% !important",
    },
    subMenuHover: {
      "&:hover": {
        color: "white !important",
      },
    },
    disFlexCenter: {
      display: "flex",
      justifyContent: "center !important",
      alignItems: "center",
    },
    dailogTitle: {
      m: 0,
      p: 2,
      justifyContent: "center",
      display: "flex",
      fontSize: 14,
      fontWeight: "bold",
    },
    dailogTitleInput: {
      m: 0,
      p: 2,
      justifyContent: "center",
      display: "flex",
      fontSize: 14,
      width: "25%",
      marginRight: "10px",
    },
    boxEnd: {
      display: "flex",
      justifyContent: "end",
    },
    deleteOkButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#18333A !important",
      background: "#18333A !important",
      fontSize: "12px !important",
      "&:hover": {
        background: "red !important",
        borderColor: "red !important",
      },
    },
    deleteCancelButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      fontSize: "12px !important",
      borderColor: "#18333A !important",
      background: "#18333A !important",
    },
    actionDialog: {
      display: "flex",
      width: "50%",
      margin: "0px auto",
      alignItems: "center",
      fontSize: "14px !important",
    },
    addFleetListButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#645f5f !important",
      background: "black !important",
      height: "20px",
    },
    headerAnalysisReport: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "12px !important",
      padding: 5,
    },
    headerAnalysisName: {
      fontWeight: 600,
    },
    reportButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#18333A !important",
      background: "#18333A !important",
    },
    headerNameText: {
      fontSize: "14px !important",
      fontWeight: 700,
      color: "#18333A",
    },
    actionButtonDialog: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "12px !important",
      margin: 10,
    },
    addActionButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#645f5f !important",
      background: "black !important",
      height: "30px",
    },
    textinput: {
      "& .MuiInputBase-root-MuiInput-root-MuiSelect-root": {
        padding: "0px !important",
        fontSize: "14px !important",
        color: "#18333A !important",
      },
      "& .MuiInput-root": {
        padding: "0px !important",
        fontSize: "14px !important",
        color: "#18333A !important",
      },
      "& .MuiInput-underline::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiSelect-standard::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      fontSize: "14px !important",
      color: "#18333A !important",
    },
    dealerLogo: {
      position: "absolute",
      left: "45%",
      width: "100%",
    },
    notFoundMainDiv: {
      height: "calc(100vh - 121px)",
      padding: "20px",
      marginTop: "60px",
      marginLeft: "240px",
      paddingLeft: "5px",
      display: "flex",
      justifyContent: "center",
      width: "100vh",
    },
    redbox: {
      height: "15px !important",
      width: "15px !important",
      background: "#F77479 !important",
      border: "1px solid gray !important",
    },
    yellowbox: {
      height: "15px !important",
      width: "15px !important",
      background: "#F4F5A2 !important",
      border: "1px solid gray !important",
      marginLeft: "10px",
    },
    noteContain: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    notediv: {
      display: "flex",
      justifyContent: "end",
      // marginTop: "15px",
      float: "right",
      width: "100%",
      alignItems: "center",
    },
    dealerTitle: {
      display: "flex",
      alignItems: "center",
      fontSize: "14px !important",
      fontWeight: 600,
    },
    titleHeader: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    dealerNameTitle: {
      fontSize: "14px !important",
    },
    reportHeader: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: "0px",
      marginTop: "0px",
      padding: "5px",
      fontSize: 24,
      color: "white",
      backgroundColor: "#18333a !important",
    },
    reportInnerDiv: {
      padding: "5px",
      backgroundColor: "#18333a !important",
    },
    reportDivThree: {
      borderRadius: "0px !important",
      backgroundColor: "#18333a",
      margin: "0px",
      width: "100%",
    },
    reportCardThree: {
      borderRadius: "0px !important",
      backgroundColor: "#52666B !important",
      border: "1px solid #fff",
      margin: "0px !important",
    },
    reportInnerDivTitle: {
      color: "white",
      fontSize: 14,
      padding: 5,
    },
    reportInnerDivTotal: {
      color: "white",
      fontSize: 24,
      padding: 5,
    },
    reportInnerDivIcon: {
      color: "white",
      fontSize: 12,
      padding: 4,
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    reportInnerDivSecTotal: {
      color: "white",
      fontSize: 34,
      padding: 5,
      paddingTop: 10,
      paddingBottom: 10,
      textAlign: "center",
    },
    reportInnerDivSecTitle: {
      color: "white",
      fontSize: 18,
      padding: 5,
      paddingTop: 10,
      paddingBottom: 17,
      textAlign: "center",
    },
    reportMainDiv: {
      backgroundColor: "#8B999C",
      // height: "100rem",
      marginTop: "60px",
      marginLeft: "240px",
      padding: 20,
      paddingLeft: 5,
    },
    reportInnerDivSec: {
      padding: "5px",
      borderRadius: "0px !important",
      backgroundColor: "#18333a !important",
      height: "141px",
    },
    reportCardSec: {
      borderRadius: "0px !important",
      backgroundColor: "#18333a !important",
      margin: "0px !important",
    },
    reportCardBorder: {
      borderRadius: "0px !important",
      border: "1px solid #fff !important",
      backgroundColor: "transparent !important",
      height: "100%",
    },
    cardChartHeader: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "0px",
      marginTop: "0px",
      padding: "5px",
      fontSize: 16,
      color: "white",
    },
    marginTopOne: {
      marginTop: "0px !important",
    },
    reportFleetMargin: {
      marginTop: "70px !important",
    },
    // reportDonutGrid: {
    //   height: "220px",
    // },
    reportHeaderTitle: {
      display: "flex",
      // justifyContent: "space-around !important",
      justifyContent: "space-between !important",
      alignItems: "center",
      width: "100%",
    },
    actionDialogCommon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px !important",
      marginTop: "8px !important",
    },
    textinput: {
      "& .css-1x51dt5-MuiInputBase-input-MuiInput-input": {
        // height: "1.4em !important",
        padding: "0px !important",
        fontSize: "12px !important",
        color: "#18333A !important",
      },
      "& .css-cnfv5a-MuiInputBase-root-MuiInput-root::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      width: 500,
      fontSize: "12px !important",
      color: "#18333A !important",
    },
    logoutButton: {
      marginTop: "10px !important",
      padding: "10px !important",
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#645f5f !important",
      background: "black !important",
    },
    profileHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    countReplacementYear: {
      display: "flex",
      alignItems: "center",
    },
    countReplacementYearSpan: {
      padding: "8px 10px",
      border: "1px solid #88a922",
      marginRight: "1px",
      background: "#88a922",
      color: "white",
      fontWeight: 600,
      borderRadius: 8,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    companyReportLogo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    footerVersion: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    footerVersionText: {},
    footerSupportText: {
      background: "white",
      color: "black",
      padding: "5px 25px",
      marginLeft: "20px",
      borderRadius: "8px",
    },
    footerVersionButton: {
      background: "white !important",
      color: "black !important",
      padding: "5px 15px !important",
      marginLeft: "12px !important",
      textTransform: "capitalize !important",
    },
    supportInput: {
      "& input": {
        padding: "20px !important",
      },
    },
    exportPowerBIButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "#000 !important",
      borderColor: "#645f5f !important",
      background: "white !important",
      height: "30px",
    },
    footerVersionDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    widthFooterVersion: {
      width: "400px",
    },
    fetchingMakeModel: {
      width: "20px !important",
      height: "20px !important",
    },
    speakToTextButton: {
      color: "white !important",
      background: "#18333A !important",
      fontWeight: "bold !important",
      borderColor: "#18333A !important",
      marginRight: "10px !important",
      padding: "4px 10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    requireFieldValue: {
      color: "red",
      fontSize: "14px !important",
      textTransform: "capitalize",
    },
    requireFieldValueLast: {
      color: "red",
      fontSize: "14px !important",
      textTransform: "capitalize",
      marginRight: 5,
    },
    headerContentFleet: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    columnRequired: {
      color: "red",
      fontSize: "12px !important",
      marginRight: 10,
    },
  };
});
