import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Input,
  InputAdornment,
} from "@mui/material";
import { AdminPageStyle } from "../../pages/admin/style";
import { useForm } from "react-hook-form";
import { getCurrencyValue } from "../../common/services/number-service";

export const CPMInfo = ({
  fleetNo,
  costPerMile,
  currentYearTotalCost,
  setCostPerMile,
  setFirstYearMiles,
  setSecondYearMiles,
  setThirdYearMiles,
  setFourthYearMiles,
  setFifthYearMiles,
  firstYearMiles,
  totalCostFirstYear,
  secondYearMiles,
  totalCostSecondYear,
  thirdYearMiles,
  totalCostThirdYear,
  fourthYearMiles,
  totalCostFourthYear,
  fifthYearMiles,
  totalCostFifthYear,
}) => {
  const classes = AdminPageStyle();
  const { register } = useForm({});

  const yearCpmChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    switch (name) {
      case "yearCurrentCpm":
        setCostPerMile(value);
        break;
      case "year1Cpm":
        setFirstYearMiles(value);
        break;
      case "year2Cpm":
        setSecondYearMiles(value);
        break;
      case "year3Cpm":
        setThirdYearMiles(value);
        break;
      case "year4Cpm":
        setFourthYearMiles(value);
        break;
      case "year5Cpm":
        setFifthYearMiles(value);
        break;
      default:
        break;
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          cost per mile information
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  Current Cost Per Mile
                </span>
                <Input
                  id="standard-basic"
                  variant="standard"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      className={classes.textinput}
                    >
                      <span style={{ fontSize: "8px !important" }}>
                        {fleetNo && `$`}
                      </span>
                    </InputAdornment>
                  }
                  className={classes.textinput}
                  value={fleetNo ? costPerMile : 0}
                  {...register("yearCurrentCpm", { valueAsNumber: true })}
                  onChange={yearCpmChange}
                />
                {/* <span className={classes.fleetListGenInfoTtext}>
                    {fleetNo
                      ? costPerMile > 0
                        ? getCurrencyValue(costPerMile)
                        : "0"
                      : 0}
                  </span> */}
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  Current Year Total Cost
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  {fleetNo
                    ? getCurrencyValue(currentYearTotalCost?.toFixed(2))
                    : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  1st Year Cost Per Mile
                </span>
                <Input
                  id="standard-basic"
                  variant="standard"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      className={classes.textinput}
                    >
                      <span style={{ fontSize: "8px !important" }}>
                        {fleetNo && `$`}
                      </span>
                    </InputAdornment>
                  }
                  className={classes.textinput}
                  value={fleetNo ? firstYearMiles : 0}
                  {...register("year1Cpm", { valueAsNumber: true })}
                  onChange={yearCpmChange}
                />
                {/* <span className={classes.fleetListGenInfoTtext}>
                    {fleetNo
                      ? firstYearMiles > 0
                        ? getCurrencyValue(firstYearMiles)
                        : "0"
                      : 0}
                  </span> */}
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  1st Year Total Cost
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  {fleetNo ? getCurrencyValue(totalCostFirstYear) : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  2nd Year Cost Per Mile
                </span>
                <Input
                  id="standard-basic"
                  variant="standard"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      className={classes.textinput}
                    >
                      <span style={{ fontSize: "8px !important" }}>
                        {fleetNo && `$`}
                      </span>
                    </InputAdornment>
                  }
                  className={classes.textinput}
                  value={fleetNo ? secondYearMiles : 0}
                  {...register("year2Cpm", { valueAsNumber: true })}
                  onChange={yearCpmChange}
                />
                {/* <span className={classes.fleetListGenInfoTtext}>
                    {fleetNo
                        ? secondYearMiles > 0
                          ? getCurrencyValue(secondYearMiles)
                          : "0"
                        : 0}
                  </span> */}
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  2nd Year Total Cost
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  {fleetNo ? getCurrencyValue(totalCostSecondYear) : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  3rd Year Cost Per Mile
                </span>
                <Input
                  id="standard-basic"
                  variant="standard"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      className={classes.textinput}
                    >
                      <span style={{ fontSize: "8px !important" }}>
                        {fleetNo && `$`}
                      </span>
                    </InputAdornment>
                  }
                  className={classes.textinput}
                  value={fleetNo ? thirdYearMiles : 0}
                  {...register("year3Cpm", { valueAsNumber: true })}
                  onChange={yearCpmChange}
                />
                {/* <span className={classes.fleetListGenInfoTtext}>
                    {fleetNo
                        ? thirdYearMiles > 0
                          ? getCurrencyValue(thirdYearMiles)
                          : "0"
                        : 0}
                  </span> */}
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  3rd Year Total Cost
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  {fleetNo ? getCurrencyValue(totalCostThirdYear) : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  4th Year Cost Per Mile
                </span>
                <Input
                  id="standard-basic"
                  variant="standard"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      className={classes.textinput}
                    >
                      <span style={{ fontSize: "8px !important" }}>
                        {fleetNo && `$`}
                      </span>
                    </InputAdornment>
                  }
                  className={classes.textinput}
                  value={fleetNo ? fourthYearMiles : 0}
                  {...register("year4Cpm", { valueAsNumber: true })}
                  onChange={yearCpmChange}
                />
                {/* <span className={classes.fleetListGenInfoTtext}>
                    {fleetNo
                        ? fourthYearMiles > 0
                          ? getCurrencyValue(fourthYearMiles)
                          : "0"
                        : 0}
                  </span> */}
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  4th Year Total Cost
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  {fleetNo ? getCurrencyValue(totalCostFourthYear) : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  5th Year Cost Per Mile
                </span>
                <Input
                  id="standard-basic"
                  variant="standard"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      className={classes.textinput}
                    >
                      <span style={{ fontSize: "8px !important" }}>
                        {fleetNo && `$`}
                      </span>
                    </InputAdornment>
                  }
                  className={classes.textinput}
                  value={fleetNo ? fifthYearMiles : 0}
                  {...register("year5Cpm", { valueAsNumber: true })}
                  onChange={yearCpmChange}
                />
                {/* <span className={classes.fleetListGenInfoTtext}>
                    {fleetNo
                        ? fifthYearMiles > 0
                          ? getCurrencyValue(fifthYearMiles)
                          : "0"
                        : 0}
                  </span> */}
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  5th Year Total Cost
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  {fleetNo ? getCurrencyValue(totalCostFifthYear) : 0}
                </span>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
