import React, { useState, createContext, useCallback } from "react";
import { analysisResultApi } from "../api";
import { showToast } from "../../components/Toast";

/**
 * Analysis Result context
 */
export const AnalysisResultContext = createContext();

/**
 * Analysis Result data provider
 */
export const AnalysisResultProvider = ({ children }) => {
  const [analysisResult, setAnalysisResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analysisResultId, setAnalysisResultId] = useState(null);
  const getAnalysisResultApi = async (values) => {
    setLoading(true);
    const response = await analysisResultApi.getAnalysisResult({
      limit: values?.limit ? values.limit : 200,
      offset: values?.offset ? values.offset : 0,
      ...values,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });

    if (response?.error) {
      // addMessage({ message: response.error.response.error, type: "error" });
      setLoading(false);
      return true;
    }

    if (response?.data?.data) {
      setAnalysisResult(response?.data?.data);
      setLoading(false);
    } else {
      setAnalysisResult([]);
      setLoading(false);
    }

    return response.data;
  };

  const getAnalysisResultByIdApi = async (id) => {
    setLoading(true);
    const response = await analysisResultApi.getAnalysisResultByid(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setAnalysisResultId(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const addAnalysisResultApi = async (values) => {
    setLoading(true);
    const response = await analysisResultApi.createAnalysisResult({
      ...values,
    });

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      // await getAnalysisResultApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const updateAnalysisResult = async (values) => {
    let payload = {
      filter: { companyId: values?.companyId },
      limit: 10000,
      offset: 0,
    };
    setLoading(true);
    const response = await analysisResultApi.editAnalysisResult({ ...values });
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      showToast("Analysis result updated successfully");
      await getAnalysisResultApi(payload);
      await getAnalysisResultByIdApi(values?.id);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const updateViewAnalysisResult = async (values) => {
    let payload = {
      filter: { companyId: values?.companyId },
      limit: 10000,
      offset: 0,
    };
    setLoading(true);
    const response = await analysisResultApi.editAnalysisResult({ ...values });
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      await getAnalysisResultApi(payload);
      await getAnalysisResultByIdApi(values?.id);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const deleteAnalysisResult = async (id) => {
    setLoading(true);
    const response = await analysisResultApi.deleteAnalysisResultByid(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      getAnalysisResultApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    analysisResult,
    loading,
    analysisResultId,
    setLoading,
    setAnalysisResult,
    setAnalysisResultId,
    getAnalysisResultApi: useCallback((request) => {
      getAnalysisResultApi(request);
    }, []),
    getAnalysisResultByIdApi: useCallback((request) => {
      getAnalysisResultByIdApi(request);
    }, []),
    addAnalysisResultApi: useCallback((request) => {
      addAnalysisResultApi(request);
    }, []),
    updateAnalysisResult: useCallback((request) => {
      updateAnalysisResult(request);
    }, []),
    deleteAnalysisResult: useCallback((request) => {
      deleteAnalysisResult(request);
    }, []),
    updateViewAnalysisResult: useCallback((request) => {
      updateViewAnalysisResult(request);
    }, []),
  };

  return (
    <AnalysisResultContext.Provider value={contextValue}>
      {children}
    </AnalysisResultContext.Provider>
  );
};
