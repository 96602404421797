import React from "react";
import { ThemeProvider } from "@mui/material";
import { CookiesProvider } from "react-cookie";
import Compose from "./compose";
import { useTheme } from "../../common/hooks";
import { ColorHeaderProvider } from "../../common/contexts";
import {
  AuthProvider,
  CompanyProvider,
  DealerCompanyProvider,
  DealerProvider,
  LoaderProvider,
  ModelProvider,
  MakeProvider,
  UserProvider,
  YearProvider,
  FleetListProvider,
  FuelListProvider,
  TypeListProvider,
  DriveTrainListProvider,
  ReplacementScheduleProvider,
  FuelAnalysisProvider,
  StateListProvider,
  MaintananceProvider,
  FundListProvider,
  NewVehicleProvider,
  AnalysisResultProvider,
  FuelEconomyProvider,
  SupportProvider,
} from "../../common/context";

export const AppContextWrapper = ({ children }) => {
  const { currentTheme } = useTheme();
  const providers = [
    CookiesProvider,
    ColorHeaderProvider,
    AuthProvider,
    UserProvider,
    CompanyProvider,
    DealerProvider,
    LoaderProvider,
    ModelProvider,
    MakeProvider,
    YearProvider,
    DealerCompanyProvider,
    FleetListProvider,
    FuelListProvider,
    TypeListProvider,
    DriveTrainListProvider,
    ReplacementScheduleProvider,
    FuelAnalysisProvider,
    StateListProvider,
    MaintananceProvider,
    FundListProvider,
    NewVehicleProvider,
    AnalysisResultProvider,
    FuelEconomyProvider,
    SupportProvider,
  ];
  return (
    <ThemeProvider theme={currentTheme}>
      <Compose components={providers}>{children}</Compose>
    </ThemeProvider>
  );
};
