import React, { useEffect, useState } from "react";
import { useAuth, useColor } from "../../common/hooks";
import { layoutStyle } from "../layout-ui/style";
import { MuiColorInput } from "mui-color-input";
import { Box, Button, Grid } from "@mui/material";

export const Profile = () => {
  const { colorHeader, setColorHeader } = useColor();
  const classes = layoutStyle();
  const { onLogout } = useAuth();
  const [location, setLocation] = useState();

  const handleChange = (newValue) => {
    setColorHeader(newValue);
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (location) {
      setLocation(location.coords);
    });
  }, []);
  const handleLogout = async () => {
    await onLogout();
  };
  return (
    <Box className={classes.mainContent}>
      <div className={classes.profileHeader}>
        <h1>Profile</h1>
        <Button className={classes.logoutButton} onClick={handleLogout}>
          Logout from all devices
        </Button>
      </div>
      <Box style={{ margin: "0px auto" }}>
        <Box style={{ fontWeight: "bold", marginRight: 10 }}>
          Choose header color:{" "}
        </Box>
        <Grid container>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <MuiColorInput
              format="hex"
              value={colorHeader}
              style={{ background: "rgb(24, 51, 58)" }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
