import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { AdminPageStyle } from "../../pages/admin/style";

export const CycleYearInfo = ({ yearData, setYearData, cycleYear }) => {
  const classes = AdminPageStyle();

  const handleYearChange = (e, data) => {
    if (e.target.checked) {
      let obj = {
        ...yearData,
        [`year${data}`]: true,
      };

      setYearData(obj);
    } else {
      let obj = {
        ...yearData,
        [`year${data}`]: false,
      };
      setYearData(obj);
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          cycle year information
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Year 1
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={cycleYear?.year1}
                        disabled={cycleYear ? true : false}
                        onChange={(e) => handleYearChange(e, 1)}
                      />
                    }
                  />
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Year 2
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={cycleYear?.year2}
                        disabled={cycleYear ? true : false}
                        onChange={(e) => handleYearChange(e, 2)}
                      />
                    }
                  />
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Year 3
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={cycleYear?.year3}
                        disabled={cycleYear ? true : false}
                        onChange={(e) => handleYearChange(e, 3)}
                      />
                    }
                  />
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Year 4
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={cycleYear?.year4}
                        disabled={cycleYear ? true : false}
                        onChange={(e) => handleYearChange(e, 4)}
                      />
                    }
                  />
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Year 5
                </span>
                <span className={classes.fleetListGenInfoTtext}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={cycleYear?.year5}
                        disabled={cycleYear ? true : false}
                        onChange={(e) => handleYearChange(e, 5)}
                      />
                    }
                  />
                </span>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
