import React, { useState,createContext } from "react";

export const ColorHeaderContext = createContext();

export const ColorHeaderProvider = ({ children }) => {
  const [colorHeader, setColorHeader] = useState('');

  const contextValue = {
    colorHeader, 
    setColorHeader
  };

  return (
    <ColorHeaderContext.Provider value={contextValue}>
      {children}
    </ColorHeaderContext.Provider>
  );
};
