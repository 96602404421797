import { Edit } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import readXlsxFile from "read-excel-file";
import { companiesColumnData } from "../../common/constants/gridRowColumn";
import { useAuth, useCompany, useDealerCompany } from "../../common/hooks";
import { DeleteDialog } from "../../components/DeleteDialog";
import { SearchBar } from "../../components/SearchBar";
import { useDebounce } from "../../components/useDebounce";
import { layoutStyle } from "../layout-ui/style";
import AddCompany from "./AddCompany";
import { TruckLoader } from "./truckLoader";
import {
  actionColumnFunction,
  checkbox,
  getDefaultColDef,
} from "../../common/constants/commonFunction";

export const ManageCompanies = () => {
  const classes = layoutStyle();
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Add Company");
  const [searchValue, setSearchValue] = useState("");
  const [columnFinal, setColumnFinal] = useState([]);
  const [anchorElPrint, setAnchorElPrint] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElGroupBy, setAnchorElGroupBy] = useState(null);
  const [anchorElFavorites, setAnchorElFavorites] = useState(null);
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [rowDataValue, setRowDataValue] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [page, setPage] = useState({ pageSize: 10000, page: 0 });
  const [editValue, setEditValue] = useState();
  const [mainData, setMainData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState("");
  const {
    company,
    getCompanyApi,
    loading,
    companyName,
    addCompanyApi,
    deleteCompanyApi,
  } = useCompany();
  const { getDealerCompanyApi } = useDealerCompany();
  const userType = localStorage.getItem("type");
  const { userId } = useAuth();
  const [deleteValue, setDeleteValue] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);

  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };

  const handleClickPrint = (event) => {
    setAnchorElPrint(event.currentTarget);
  };

  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };
  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };

  const handleClose = () => {
    setAnchorElPrint(null);
    setAnchorElSettings(null);
    setAnchorElFilter(null);
    setAnchorElGroupBy(null);
    setAnchorElFavorites(null);
  };

  const onAddBtnClick = () => {
    setOpen(true);
    setTitle("Add Company");
    setEditValue(null);
  };
  const handleEditButton = (value) => {
    setOpen(true);
    setTitle("Edit Company");
    setEditValue(value);
  };

  const handleImageClick = (imageUrl) => {
    setClickedImageUrl(imageUrl);
    setOpenModal(true);
  };
  const handleCloseImageModal = () => {
    setOpenModal(false);
  };

  const handleDeleteButton = async (values) => {
    setDeleteOpen(true);
    setDeleteValue(values?.id);
    // deleteCompanyApi(values);
  };

  const handleDelete = async () => {
    await deleteCompanyApi(deleteValue);
    setDeleteOpen(false);
  };

  useEffect(() => {
    if (companiesColumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        handleDeleteButton: handleDeleteButton,
        deleteButton: true,
      });
      // let imageColumn = {
      //   field: "companyImage",
      //   headerName: "Company Logo",
      //   sortable: false,
      //   resizable: false,
      //   width: 140,
      //   headerClassName: "super-app-theme--header",
      //   disableClickEventBubbling: true,
      //   cellRenderer: (params) => (
      //     <img
      //       src={`data:image/png;base64,${params?.data?.companyImage}`}
      //       style={{ margin: 4 }}
      //       height={30}
      //       width={30}
      //       onClick={() => handleImageClick(params?.data?.companyImage)}
      //     />
      //   ),
      // };
      setColumnFinal([checkbox, ...companiesColumnData, actionColumn]);
    }
  }, [companiesColumnData, selectedRows]);

  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };
  const debouncedSearch = useDebounce(onSearchFunc, 500);
  const searchData = rowDataValue?.filter((company) =>
    userType === "dealer"
      ? company?.data?.data?.companyName
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase())
      : company?.companyName?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  useEffect(() => {
    if (companyName?.length > 0 && userType === "dealer") {
      setRowDataValue([...companyName]);
    } else if (company?.length > 0 && userType === "admin") {
      setRowDataValue([...company]);
    }
  }, [companyName, company]);

  useEffect(() => {
    if (searchValue) {
      setMainData([...searchData]);
    } else if (companyName?.length > 0) {
      setMainData([...companyName]);
    } else if (company?.length > 0) {
      company?.map((item) => {
        if (item.field == "companyImage") {
          item["cellRenderer"] = (item) => (
            <img
              alt="image"
              src={`data:image/jpg;base64, ${item}`}
              height="200"
              width="200"
            />
          );
        }
      });
      setMainData([...company]);
      // setMainData(
      //   userType === "admin"
      //     ? searchData
      //     : searchData.map((item) => item?.data?.data)
      // );
    } else if (companyName?.length > 0 && userType === "dealer") {
      setMainData(companyName.map((item) => item?.data?.data));
    } else if (company?.length > 0 && userType === "admin") {
      setMainData(company);
    }
  }, [searchValue, companyName, company]);

  useEffect(() => {
    if (userType?.toLowerCase() === "admin") {
      getCompanyApi({ limit: page.pageSize, offset: page.page });
    } else if (userType?.toLowerCase() === "dealer") {
      getDealerCompanyApi({
        userId: userId,
        limit: page.pageSize,
        offset: page.page,
      });
    }
  }, [refreshData]);
  const ImageModal = () => {
    return (
      <Modal open={openModal} onClose={handleCloseImageModal}>
        <Box
          sx={{
            position: "absolute",
            outline: "none",
            overflow: "hidden",
            margin: "20px auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              bgcolor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 24,
              borderRadius: 4,
              maxHeight: "400px",
              maxWidth: "400px",
              padding: 10,
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "rgba(0, 0, 0, 0.54)",
              }}
              onClick={handleCloseImageModal}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={`data:image/png;base64,${clickedImageUrl}`}
              alt="Company Logo"
              width={400}
              height={400}
            />
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <Box className={classes.mainContentCompany}>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Box className={classes.headerNameText}>Companies</Box>
        <Box className={classes.addEditGrid}>
          <Button
            variant="outlined"
            className={[classes.fleetListNewButton, classes.fontSize12]}
            onClick={onAddBtnClick}
          >
            Add
          </Button>
          <SearchBar onSearch={onSearch} />
        </Box>
      </Stack>

      {loading ? (
        <TruckLoader />
      ) : (
        <Box className={classes.gridContantDealer}>
          <AgGridReact
            className="ag-theme-quartz"
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids);
            }}
            rowSelection="multiple"
            suppressRowClickSelection
            pagination={true}
            rowData={mainData}
            defaultColDef={defaultColDef}
            columnDefs={columnFinal}
            checkboxSelection
          />
        </Box>
      )}
      {open && (
        <AddCompany
          setOpen={setOpen}
          open={open}
          title={title}
          setRefreshData={setRefreshData}
          editValue={editValue}
        />
      )}
      <ImageModal />

      {deleteOpen && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={() => setDeleteOpen(false)}
          deleteApi={() => handleDelete()}
        />
      )}
    </Box>
  );
};
