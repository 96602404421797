import { MenuItem, Select } from "@mui/material";
import React from "react";

export const CommonSelect = ({
  option,
  className,
  value,
  menuProp,
  handleChange,
  width,
}) => {
  return (
    <Select
      size="small"
      variant="standard"
      className={className}
      sx={{ width: width ? width : "148px !important" }}
      onChange={handleChange}
      value={value}
      MenuProps={menuProp}
    >
      {option &&
        option?.length > 0 &&
        option?.map((item) => {
          return (
            <MenuItem key={item.id} value={item?.id}>
              {item.dealerName
                ? item.dealerName
                : item.name
                ? item.name
                : item.fundingType}
            </MenuItem>
          );
        })}
    </Select>
  );
};
