import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { localStorageItemNames } from "../../common/constants";
import { navDealerItems, navItems } from "../../common/constants/navItems";
import { useAuth, useUser } from "../../common/hooks";
import { layoutStyle } from "./style";

const drawerWidth = 280;

export const Sidebar = () => {
  const classes = layoutStyle();
  const navigate = useNavigate();
  const { userLoggin } = useAuth();
  const { userData, getUserById, selectedNav, setSelectedNav } = useUser();
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [openChildMenu, setOpenChildMenu] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [selectedSubItemIndex, setSelectedSubItemIndex] = useState(null);
  const [userType, setUserType] = useState();
  const location = useLocation();
  const [activeMenuItem, setActiveMenuItem] = useState("");

  useEffect(() => {
    let userId = localStorage.getItem("type");
    if (userId) {
      setUserType(userId);
    } else {
      let local = localStorage.getItem(localStorageItemNames.userID);
      getUserById(local);
    }
  }, [userLoggin]);

  useEffect(() => {
    const { pathname } = location;
    const activeItem = findActiveItem(navItems, pathname);
    setActiveMenuItem(activeItem?.name || "");
  }, [location]);

  const findActiveItem = (items, pathname) => {
    for (let item of items) {
      if (item.path === pathname) {
        return item;
      }
      if (item.subMenu) {
        const activeSubItem = findActiveItem(item.subMenu, pathname);
        if (activeSubItem) {
          return activeSubItem;
        }
      }
    }
    return null;
  };
  const handleSubMenuClick = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  const onMenuItemClick = (nav, index) => {
    setOpenChildMenu(openChildMenu === index ? null : index);
    const slectItem = navItems?.filter((item) => item?.name === nav?.name);
    setSelectedSubItemIndex(null);
    setSelectedItemIndex(index);
    if (slectItem?.length > 0) {
      setSelectedNav(slectItem[0]?.name);
    }
    navigate(nav?.path);
  };

  const onSubItemClick = (nav, index, array) => {
    setSelectedItemIndex(null);
    setSelectedSubItemIndex(index);
    const slectItem = array?.filter((item) => item?.name === nav?.name);
    if (slectItem?.length > 0) {
      setSelectedNav(slectItem[0]?.name);
    }
    navigate(nav?.path);
  };

  return (
    <>
      <Drawer
        className={classes.drawerPaper}
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }} className={classes.box}>
          <List style={{ paddingTop: 1 }}>
            {userType === "admin" || userData?.type?.toLowerCase() === "admin"
              ? navItems?.map((nav, index) => (
                  <React.Fragment key={nav?.name}>
                    {nav?.subMenu ? (
                      <>
                        <ListItemButton
                          onClick={() => handleSubMenuClick(index)}
                          sx={{
                            "&:hover": {
                              bgcolor: "#9CBF2E !important",
                              color: "white !important",
                            },
                            "&:hover .MuiListItemIcon-root": {
                              color: "white !important",
                            },
                          }}
                        >
                          <ListItemIcon style={{ padding: "5px" }}>
                            {nav?.icon}
                          </ListItemIcon>
                          <span
                            className={classes.subMenuHover}
                            style={{
                              width: "100%",
                              color: "black",
                            }}
                          >
                            {nav?.name}
                          </span>
                          {openSubMenu === index ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Collapse
                          in={openSubMenu === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {nav?.subMenu?.map((subNavItem, subIndex) => (
                              <ListItem
                                key={subIndex}
                                disablePadding
                                onClick={() =>
                                  onSubItemClick(
                                    subNavItem,
                                    subIndex,
                                    nav?.subMenu
                                  )
                                }
                                sx={{
                                  "&:hover": {
                                    bgcolor: "#9CBF2E !important",
                                    color: "white !important",
                                  },
                                  "&:hover .MuiListItemIcon-root": {
                                    color: "white !important",
                                  },
                                  ".MuiListItemIcon-root": {
                                    color:
                                      selectedNav === subNavItem.name
                                        ? "white !important"
                                        : "black !important",
                                  },
                                }}
                                style={{
                                  backgroundColor:
                                    selectedNav === subNavItem.name
                                      ? "#18333A"
                                      : "white",
                                  color:
                                    selectedNav === subNavItem.name
                                      ? "white"
                                      : "black",
                                }}
                              >
                                <ListItemButton
                                  sx={{ pl: 4, fontWeight: "bold" }}
                                >
                                  <ListItemIcon>
                                    {subNavItem?.icon}
                                  </ListItemIcon>
                                  <span
                                    style={{ fontWeight: 500, width: "100%" }}
                                  >
                                    {subNavItem?.name}
                                  </span>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    ) : (
                      <ListItem
                        disablePadding
                        onClick={() => onMenuItemClick(nav, index)}
                        sx={{
                          "&:hover": {
                            bgcolor: "#9CBF2E !important",
                            color: "white !important",
                          },
                          "&:hover .MuiListItemIcon-root": {
                            color: "white !important",
                          },
                          ".MuiListItemIcon-root": {
                            color:
                              selectedNav === nav?.name &&
                              selectedItemIndex === index
                                ? "white"
                                : "black",
                          },
                        }}
                        style={{
                          backgroundColor:
                            selectedNav === nav?.name &&
                            selectedItemIndex === index
                              ? "#18333A"
                              : "white",
                          color:
                            selectedNav === nav?.name &&
                            selectedItemIndex === index
                              ? "white"
                              : "black",
                        }}
                      >
                        <ListItemButton sx={{ fontWeight: "bold" }}>
                          <ListItemIcon style={{ padding: "5px" }}>
                            {nav?.icon}
                          </ListItemIcon>
                          <span style={{ fontWeight: 500 }}>{nav?.name}</span>
                        </ListItemButton>
                      </ListItem>
                    )}
                  </React.Fragment>
                ))
              : navDealerItems?.map((nav, index) => (
                  <React.Fragment key={nav?.name}>
                    {nav?.subMenu ? (
                      <>
                        <ListItemButton
                          onClick={() => handleSubMenuClick(index)}
                          sx={{
                            fontWeight: "bold",
                            "&:hover": {
                              bgcolor: "#9CBF2E !important",
                              color: "white !important",
                            },
                            "&:hover .MuiListItemIcon-root": {
                              color: "white !important",
                            },
                          }}
                        >
                          <ListItemIcon style={{ color: "black" }}>
                            {nav?.icon}
                          </ListItemIcon>
                          <span
                            className={classes.subMenuHover}
                            style={{
                              fontWeight: 600,
                              width: "100%",
                              color: "black",
                            }}
                          >
                            {nav?.name}
                          </span>
                          {openSubMenu === index ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Collapse
                          in={openSubMenu === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {nav?.subMenu?.map((subNavItem, subIndex) => (
                              <ListItem
                                key={subIndex}
                                disablePadding
                                onClick={() =>
                                  onSubItemClick(
                                    subNavItem,
                                    subIndex,
                                    nav?.subMenu
                                  )
                                }
                                sx={{
                                  "&:hover": {
                                    bgcolor: "#9CBF2E !important",
                                    color: "white !important",
                                  },
                                  "&:hover .MuiListItemIcon-root": {
                                    color: "white !important",
                                  },
                                  ".MuiListItemIcon-root": {
                                    color:
                                      selectedNav === subNavItem.name
                                        ? "white !important"
                                        : "black !important",
                                  },
                                }}
                                style={{
                                  backgroundColor:
                                    selectedNav === subNavItem.name
                                      ? "#18333A"
                                      : "white",
                                  color:
                                    selectedNav === subNavItem.name
                                      ? "white"
                                      : "black",
                                }}
                              >
                                <ListItemButton
                                  sx={{ pl: 4, fontWeight: "bold" }}
                                >
                                  <ListItemIcon>
                                    {subNavItem?.icon}
                                  </ListItemIcon>
                                  <span style={{ fontWeight: 500 }}>
                                    {subNavItem?.name}
                                  </span>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    ) : (
                      <ListItem
                        disablePadding
                        onClick={() => onMenuItemClick(nav, index)}
                        sx={{
                          "&:hover": {
                            bgcolor: "#9CBF2E !important",
                            color: "white !important",
                          },
                          "&:hover .MuiListItemIcon-root": {
                            color: "white !important",
                          },
                          ".MuiListItemIcon-root": {
                            color:
                              selectedNav === nav?.name &&
                              selectedItemIndex === index
                                ? "white"
                                : "black",
                          },
                        }}
                        style={{
                          backgroundColor:
                            selectedNav === nav?.name &&
                            selectedItemIndex === index
                              ? "#18333A"
                              : "white",
                          color:
                            selectedNav === nav?.name &&
                            selectedItemIndex === index
                              ? "white"
                              : "black",
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon>{nav?.icon}</ListItemIcon>
                          <span style={{ fontWeight: 500 }}>{nav?.name}</span>
                        </ListItemButton>
                      </ListItem>
                    )}
                  </React.Fragment>
                ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};
