import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Delete } from '@mui/icons-material';
import { layoutStyle } from '../pages/layout-ui/style';

export const DeleteDialog = ({open,close,deleteApi,setOpen})=> {
const classes = layoutStyle()

  const handleClick = async() => {
    await deleteApi()
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        <DialogActions>
        <Button
              variant="outlined"
              type="submit"
              className={classes.deleteCancelButton}
              autoFocus
              onClick={handleClose}
            >
              Cancel
            </Button>
          <Button
              variant="outlined"
              type="submit"
              className={classes.deleteOkButton}
              onClick={handleClick}
            >
              Yes
            </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}