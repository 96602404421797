import { deleteData, getData, postData } from "./base-api";
import ApiUris, { baseURL } from "./apiUris";

/**
 * Model Api method declarations
 */
export const modelApi = {
  getMakeById(request) {
    return getData(`${baseURL}/Make/${request}`, request);
  },
  getModel(request) {
    return postData(ApiUris.ModelApi.getModel, request);
  },
  getModelByid(id) {
    return getData(`${ApiUris.ModelApi.getModelById}/${id}`);
  },
  deleteModelByid(id) {
    return deleteData(`${ApiUris.ModelApi.deleteModelById}?id=${id}`);
  },
  createModel(request) {
    return postData(ApiUris.ModelApi.createModel, request);
  },
  editModel(request) {
    return postData(ApiUris.ModelApi.editModel, request);
  },
  modelList(request) {
    return postData(ApiUris.ModelApi.modelList, request);
  },
};
