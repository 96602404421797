import React, { useState } from "react";
import { useAuth } from "../../common/hooks";
import BouncingDotsLoader from "../../components/DotLoading";
import { LoginStyle } from "./style";
import { WebVersion } from "../../common/api/apiUris";
export const Login = () => {
  const classes = LoginStyle();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { onLoggedIn, loading } = useAuth();
  const login = async () => {
    const payload = {
      userName: userName,
      password: password,
      email: "",
    };
    await onLoggedIn({ ...payload });
  };
  return (
    <>
      <video autoPlay loop muted className={classes.backgroundVideo}>
        <source src="/login-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={classes.loginPage}>
        <div className={classes.FormDiv}>
          <div className={classes.form}>
            <form class="login-form ">
              <img
                src="/whitelogo.png"
                width={"160px"}
                alt="logo"
                style={{ padding: 2 }}
              />
              <p className={classes.paragraph}>Login into your account</p>
              <input
                className={classes.formInput}
                type="text"
                required
                placeholder="Username"
                id="user"
                autocomplete="off"
                onChange={(e) => setUserName(e.target.value)}
              />
              <input
                className={classes.formInput}
                oninput="return formvalid()"
                type="password"
                required
                placeholder="Password"
                id="pass"
                autocomplete="off"
                onChange={(e) => setPassword(e.target.value)}
              />
              <span id="vaild-pass" className={classes.span}></span>
              <button
                type="button"
                onClick={login}
                className={classes.formButton}
              >
                {loading ? <BouncingDotsLoader /> : "Login"}
              </button>
              <p className={classes.versionLogin}>{WebVersion}</p>
              {/* <p class="message" className={classes.message}>
              <a href="/forget" className={classes.formMessageA}>
                Forgot your password?
              </a>
            </p> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
