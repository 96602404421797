import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * NewVehicle Api method declarations
 */
export const newVehicleApi = {
  getNewVehicle(request) {
    return postData(ApiUris.newVehicleApi.getNewVehicle, request);
  },
  getNewVehicleByid(id) {
    return getData(`${ApiUris.newVehicleApi.getNewVehicleById}/${id}`);
  },
  deleteNewVehicleByid(id) {
    return deleteData(`${ApiUris.newVehicleApi.deleteNewVehicleById}?id=${id}`);
  },
  createNewVehicle(request) {
    return postData(ApiUris.newVehicleApi.createNewVehicle, request);
  },
  editNewVehicle(request) {
    return postData(ApiUris.newVehicleApi.editNewVehicle, request);
  },
  multipleEditNewVehicle(request) {
    return postData(ApiUris.newVehicleApi.multipleNewVehicle, request);
  },
};
