import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { AdminPageStyle } from "../../pages/admin/style";

export const CycleYearInfo = ({ setYear1ValueData, year1ValueData }) => {
  const classes = AdminPageStyle();

  const changeYear = (event, data) => {
    const { checked } = event.target;
    setYear1ValueData((prevState) => ({
      ...prevState,
      [`year${data}`]: checked,
    }));
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          cycle year information
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box>
              <Box className={classes.divMt10}>
                <Box className={classes.fleetListGenInfoDiv}>
                  <span className={classes.fleetListTotalMileageText}>
                    Year 1
                  </span>
                  <span className={classes.fleetListGenInfoTtext}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={year1ValueData?.year1 ? true : false}
                          onChange={(e) => changeYear(e, 1)}
                        />
                      }
                    />
                  </span>
                </Box>
              </Box>
              <Box className={classes.divMt10}>
                <Box className={classes.fleetListGenInfoDiv}>
                  <span className={classes.fleetListTotalMileageText}>
                    Year 2
                  </span>
                  <span className={classes.fleetListGenInfoTtext}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={year1ValueData?.year2 ? true : false}
                          onChange={(e) => changeYear(e, 2)}
                        />
                      }
                    />
                  </span>
                </Box>
              </Box>
              <Box className={classes.divMt10}>
                <Box className={classes.fleetListGenInfoDiv}>
                  <span className={classes.fleetListTotalMileageText}>
                    Year 3
                  </span>
                  <span className={classes.fleetListGenInfoTtext}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={year1ValueData?.year3 ? true : false}
                          onChange={(e) => changeYear(e, 3)}
                        />
                      }
                    />
                  </span>
                </Box>
              </Box>
              <Box className={classes.divMt10}>
                <Box className={classes.fleetListGenInfoDiv}>
                  <span className={classes.fleetListTotalMileageText}>
                    Year 4
                  </span>
                  <span className={classes.fleetListGenInfoTtext}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={year1ValueData?.year4 ? true : false}
                          onChange={(e) => changeYear(e, 4)}
                        />
                      }
                    />
                  </span>
                </Box>
              </Box>
              <Box className={classes.divMt10}>
                <Box className={classes.fleetListGenInfoDiv}>
                  <span className={classes.fleetListTotalMileageText}>
                    Year 5
                  </span>
                  <span className={classes.fleetListGenInfoTtext}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={year1ValueData?.year5 ? true : false}
                          onChange={(e) => changeYear(e, 5)}
                        />
                      }
                    />
                  </span>
                </Box>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
