import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
import { AdminPageStyle } from "../../pages/admin/style";
import { getCurrencyValue } from "../../common/services/number-service";

export const AnnualisedFuelSpend = ({
  currentSpendPrice,
  fuelSpendForecasted,
}) => {
  const classes = AdminPageStyle();

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          annualised fuel spend
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Current spend
                </span>
                <span className={classes.fleetListGenInfoInputText}>
                  {currentSpendPrice ? getCurrencyValue(currentSpendPrice) : 0}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Forecasted spend
                </span>
                <span className={classes.fleetListGenInfoInputText}>
                  {fuelSpendForecasted
                    ? getCurrencyValue(fuelSpendForecasted)
                    : 0}
                </span>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
