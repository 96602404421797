import { Edit } from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PrintIcon from "@mui/icons-material/Print";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  dealerColumnData,
  dealerCompanyColumnData,
} from "../../common/constants/gridRowColumn";
import { useDealer } from "../../common/hooks/dealer-hook";
import { SearchBar } from "../../components/SearchBar";
import { layoutStyle } from "../layout-ui/style";
import AddDealer from "./AddDealer.";
import { AddDealerCompany } from "./AddDealerCompany";
import { TruckLoader } from "./truckLoader";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDebounce } from "../../components/useDebounce";
import { useDealerCompany } from "../../common/hooks";
import {
  actionColumnFunction,
  checkbox,
  getDefaultColDef,
} from "../../common/constants/commonFunction";

export const ManageCompanyDealer = () => {
  const classes = layoutStyle();
  const [loader, setLoader] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnFinal, setColumnFinal] = useState([]);
  const [anchorElPrint, setAnchorElPrint] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElGroupBy, setAnchorElGroupBy] = useState(null);
  const [anchorElFavorites, setAnchorElFavorites] = useState(null);
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState({ pageSize: 10000, page: 0 });
  const [open, setOpen] = useState(false);
  const [openDealer, setOpenDealer] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [anchorElSearch, setAnchorElSearch] = useState(null);

  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };

  const searchData = rowData?.filter((company) =>
    company?.dealerName?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const debouncedSearch = useDebounce(onSearchFunc, 500);
  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const navigation = useNavigate();
  const [refreshData, setRefreshData] = useState(false);

  const { getDealerApi, setDealerById, dealer, deleteDealerByIdApi } =
    useDealer();
  const { getDealerCompanyListApi, dealerCompanyData, deleteCompanyDealer } =
    useDealerCompany();

  const handleClickPrint = (event) => {
    setAnchorElPrint(event.currentTarget);
  };

  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElPrint(null);
    setAnchorElSettings(null);
    setAnchorElFilter(null);
    setAnchorElGroupBy(null);
    setAnchorElFavorites(null);
  };
  const handleModelClose = () => {
    setOpen(false);
    setEditData(null);
    setDealerById(null);
  };

  useEffect(() => {
    if (searchValue) {
      setRowData([...searchData]);
    } else if (dealerCompanyData?.length > 0) {
      // dealer?.map((items, index) => {
      //   items["id"] = index + 1;
      //   return true;
      // });
      setRowData([...dealerCompanyData]);
    }
  }, [searchValue, dealerCompanyData]);

  useEffect(() => {
    getDealerCompanyListApi({ limit: page.pageSize, offset: page.page });
    setEditData(null);
  }, [refreshData]);

  const onEditBtnClick = (data) => {
    setEditData(data);
    setOpenDealer(true);
  };
  const handleDeleteDealer = async (values) => {
    // await deleteCompanyDealer(values);
  };

  useEffect(() => {
    if (dealerColumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: onEditBtnClick,
        handleDeleteButton: handleDeleteDealer,
        deleteButton: true,
      });

      setColumnFinal([checkbox, ...dealerCompanyColumnData, actionColumn]);
    }
  }, [dealerCompanyColumnData, selectedRows]);
  const onDelete = (value) => {
    // deleteDealerByIdApi();
    handleClose();
  };
  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };
  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };

  return (
    <Box className={classes.mainContentCompany}>
      <Box className={classes.companyHeader}>
        <Box className={classes.companyMain}>
          <h3 className={classes.dealerHeader}>Assigned Company list</h3>
        </Box>
        <Box className={classes.dealerAddButtonDiv}>
          <Box className={classes.dealerAddDownload}>
            <Button
              variant="outlined"
              className={classes.fleetListNewButton}
              style={{ fontSize: "12px !important " }}
              onClick={() => navigation("/manage-dealer")}
            >
              Dealers
            </Button>
            {/* <FileDownloadIcon
              style={{ cursor: "pointer", color: "#18333A" }}
              // onClick={() => handleEditButton(params.row)}
            /> */}
          </Box>
          <Box className={classes.companyButtonGroup}>
            {selectedRows?.length > 0 && (
              <>
                <Box className={classes.companySubButton}>
                  <Button
                    id="print-button"
                    aria-controls={anchorElPrint ? "print-menu" : undefined}
                    aria-haspopup="true"
                    onClick={handleClickPrint}
                    style={{ color: "black" }}
                  >
                    <PrintIcon />
                    <span>Print</span>
                  </Button>
                  <Menu
                    id="print-menu"
                    anchorEl={anchorElPrint}
                    open={Boolean(anchorElPrint)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      Preview Internal Report
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      Preview Internal Report
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      Report Layout Preview
                    </MenuItem>
                  </Menu>
                </Box>
                <Box className={classes.companySubButton}>
                  <Button
                    id="settings-button"
                    aria-controls={
                      anchorElSettings ? "settings-menu" : undefined
                    }
                    aria-haspopup="true"
                    onClick={handleClickSettings}
                    style={{ color: "black" }}
                  >
                    <SettingsIcon />
                    <span>Action</span>
                  </Button>
                  <Menu
                    id="settings-menu"
                    anchorEl={anchorElSettings}
                    open={Boolean(anchorElSettings)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>Export</MenuItem>
                    <MenuItem onClick={handleClose}>Archive</MenuItem>
                    <MenuItem onClick={handleClose}>Unarchive</MenuItem>
                    <MenuItem onClick={onDelete}>Delete</MenuItem>
                  </Menu>
                </Box>
              </>
            )}
          </Box>
          <SearchBar onSearch={onSearch} />
        </Box>
      </Box>
      {loader ? (
        <TruckLoader />
      ) : (
        <Box className={classes.gridContantDealer}>
          <AgGridReact
            className="ag-theme-quartz"
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids);
            }}
            rowSelection="multiple"
            suppressRowClickSelection
            pinnedColumns={{ right: ["action"] }}
            pagination={true}
            rowData={rowData}
            defaultColDef={defaultColDef}
            columnDefs={columnFinal}
            checkboxSelection
          />
        </Box>
      )}
      {openDealer && (
        <AddDealerCompany
          setRefreshData={setRefreshData}
          setOpenDealer={setOpenDealer}
          openDealer={openDealer}
          editData={editData}
        />
      )}
    </Box>
  );
};
