export const validEmail = (value) => {
    let errors;
    const validEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    const hasCapitalLetters = /[A-Z]/.test(value);
  
    if (!validEmail.test(value)) {
      return "Please enter a valid email";
    } else if (hasCapitalLetters) {
      return "Email cannot contain capital letters";
    }
  
    return errors;
  };

  export const validPhone = (value) => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    let errors;
    if (value) {
      if (!pattern.test(value)) {
        return "Please enter a valid contact number";
      } 
      // else if (value.length > 10 || value.length < 10) {
      //   return "Mobile number must have at least 10 characters";
      // }
    }
    return errors;
  };