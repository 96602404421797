export const PopupMessage = {
  makeSelect: "Please select same make!",
  error: "error",
  warning: "warning",
  info: "info",
  selectFile: "Please select a file!",
  uploadFile: "File is uploading Please wait!",
};

export const FUNDING_NEW_VEHICLE_CONSTANT = {
  SET_PAYMENT_AMOUNT: "Set Payment Amount",
  SET_FUNDING_TYPE: "Set Funding Type",
  SET_NEW_VEHICLE_MAKE: "Set New Vehicle Make",
  SET_NEW_VEHICLE_MODEL: "Set New Vehicle Model",
  SET_NEW_VEHICLE_YEAR: "Set New Vehicle Year",
  SET_NEW_VEHICLE_COST: "Set New Vehicle Cost",
  SET_NEW_VEHICLE_MPG: "Set New Vehicle MPG",
  SET_NEW_VEHICLE_FUEL: "Set New Fuel",
  SET_RESIDUAL: "Set Residual %",
  SET_NEW_ESTIMATES_RESALE_VALUE: "Set New Estimated Resale Value",
  REPLACE_EXISTING_VEHICLE: "Replace Existing Vehicle",
  SET_ALL: "Set All",
  APR: "APR",
  CASH: "Cash",
  LEASE: "Lease",
};

export const TAB_VALUE = {
  FLEET_LIST: 5,
  REPLACEMENT_SCHEDULE: 1,
  MAINTENANCE_ANALYSIS: 2,
  FUEL_ANALYSIS: 3,
  FUNDING_NEW_VEHICLE: 4,
};

export const TAB_CONTENT = {
  FLEET_LIST: "Fleet List",
  REPLACEMENT_SCHEDULE: "Replacement Schedule",
  MAINTENANCE_ANALYSIS: "Maintenance Analysis",
  FUEL_ANALYSIS: "Fuel Analysis",
  FUNDING_NEW_VEHICLE: "Funding/New Vehicle",
};

export const DISPLAY_MESSAGES = {
  ERROR: "Something went wrong please retry !",
  REPLACE_EXISTING_VEHICLE:
    "This will replace new vehicle of selected fleet with current vehicle, are you sure you want to proceed with this ?",
  USER_CONTROL_ACTIVATE:
    "This will Reactivate the selected user, are you sure you want to proceed with this ?",
  USER_CONTROL_DEACTIVATE:
    "This will Deactivate the selected user, are you sure you want to proceed with this ?",
};

export const USER_CONTROL = {
  ACTIVATE: "Activate",
  DEACTIVATE: "DeActivate",
};
