export const ErrorFields = {
  newYear: "newYear",
  year: "year",
  newModel: "newModel",
  newMake: "newMake",
  newMpg: "newMpg",
  paymentAmount: "paymentAmount",
  fundingType: "fundingType",
  newVehicleCost: "newVehicleCost",
  newEstimatedResaleValue: "newEstimatedResaleValue",
  newEstimatedResaleValue: "newEstimatedResaleValue",
  residualPercentage: "residualPercentage",
  equityGain: "equityGain",
  make: "make",
  model: "model",
  year: "year",
  fuel: "fuel",
  driveTrain: "driveTrain",
  type: "type",
};
