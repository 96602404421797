import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Input,
  InputAdornment,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { AdminPageStyle } from "../../pages/admin/style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCurrencyValue } from "../../common/services/number-service";

export const VehicleEquity = ({
  year1Value,
  setYear1Value,
  year2Value,
  setYear2Value,
  year3Value,
  setYear3Value,
  year4Value,
  setYear4Value,
  year5Value,
  setYear5Value,
  residualValue,
  equityGain,
  setYearFalse,
  setResidualValue,
}) => {
  const classes = AdminPageStyle();
  const { register, handleSubmit, reset, setValue } = useForm({});

  const handleResidual = (event) => {
    setYearFalse(false);
    setResidualValue(Number(event.target.value));
    setValue("residualPercentage", event.target.value);
  };

  const yearValueChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    switch (name) {
      case "year1":
        setYear1Value(value ? Number(value) : 0);
        setValue("year1", value ? Number(value) : 0);
        break;
      case "year2":
        setYear2Value(value ? Number(value) : 0);
        setValue("year2", value ? Number(value) : 0);
        break;
      case "year3":
        setYear3Value(value ? Number(value) : 0);
        setValue("year3", value ? Number(value) : 0);
        break;
      case "year4":
        setYear4Value(value ? Number(value) : 0);
        setValue("year4", value ? Number(value) : 0);
        break;
      case "year5":
        setYear5Value(value ? Number(value) : 0);
        setValue("year5", value ? Number(value) : 0);
        break;
      default:
        break;
    }
    setYearFalse(false);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          vehicle equity
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Year 1
                </span>
                <Input
                  id="standard-basic"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ fontSize: "10px important" }}
                    >
                      $
                    </InputAdornment>
                  }
                  variant="standard"
                  className={classes.textinput}
                  value={year1Value}
                  {...register("year1", { valueAsNumber: true })}
                  onChange={yearValueChange}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Year 2
                </span>
                <Input
                  id="standard-basic"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ fontSize: "10px important" }}
                    >
                      $
                    </InputAdornment>
                  }
                  variant="standard"
                  className={classes.textinput}
                  value={year2Value}
                  {...register("year2", { valueAsNumber: true })}
                  onChange={yearValueChange}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Year 3
                </span>
                <Input
                  id="standard-basic"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ fontSize: "10px important" }}
                    >
                      $
                    </InputAdornment>
                  }
                  variant="standard"
                  className={classes.textinput}
                  value={year3Value}
                  {...register("year3", { valueAsNumber: true })}
                  onChange={yearValueChange}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Year 4
                </span>
                <Input
                  id="standard-basic"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ fontSize: "10px important" }}
                    >
                      $
                    </InputAdornment>
                  }
                  variant="standard"
                  className={classes.textinput}
                  value={year4Value}
                  {...register("year4", { valueAsNumber: true })}
                  onChange={yearValueChange}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Year 5
                </span>
                <Input
                  id="standard-basic"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ fontSize: "10px important" }}
                    >
                      $
                    </InputAdornment>
                  }
                  variant="standard"
                  className={classes.textinput}
                  value={year5Value}
                  {...register("year5", { valueAsNumber: true })}
                  onChange={yearValueChange}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Residual %
                </span>
                <Input
                  id="standard-basic"
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ fontSize: "10px important" }}
                    >
                      %
                    </InputAdornment>
                  }
                  variant="standard"
                  value={residualValue}
                  onChange={handleResidual}
                  className={classes.textinput}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListMileageText}>
                  Equity Gain
                </span>
                <span className={classes.fleetListTotalMileageText}>
                  {getCurrencyValue(equityGain)}
                </span>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
