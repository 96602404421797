import React from "react";
import Header from "./Header";
import { Sidebar } from "./Sidebar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Box } from "@mui/material";

const Layout = ({ children }) => {
  return (
    <Box>
      <Header />
      <Sidebar />
      {/* <main>{children}</main> */}
      <Footer />
      <Outlet />
    </Box>
  );
};

export default Layout;
