import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Fuel List Api method declarations
 */
export const fuelAnalysisListApi = {
  getFuelAnalysisList(request) {
    return postData(ApiUris.fuelAnalysisListApi.getFuelAnalysisList, request);
  },
  getFuelAnalysisListByid(id) {
    return getData(`${ApiUris.fuelAnalysisListApi.getFuelAnalysisById}/${id}`);
  },
  deleteFuelAnalysisListByid(id) {
    return deleteData(`${ApiUris.fuelAnalysisListApi.deleteFuelAnalysisList}?id=${id}`);
  },
  createFuelAnalysisList(request) {
    return postData(ApiUris.fuelAnalysisListApi.addFuelAnalysisList, request);
  },
  editFuelAnalysisList(request) {
    return postData(ApiUris.fuelAnalysisListApi.updateFuelAnalysisList, request);
  },
  getListFuelAnalysisList(request) {
    return postData(ApiUris.fuelAnalysisListApi.getListFuelAnalysisList, request);
  },
};
