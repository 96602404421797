import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Make Api method declarations
 */
export const makeApi = {
  getMake(request) {
    return postData(ApiUris.makeApi.getmake, request);
  },
  getMakeByid(id) {
    return getData(`${ApiUris.makeApi.getMakeById}/${id}`);
  },
  deleteMakeByid(id) {
    return deleteData(`${ApiUris.makeApi.deleteMakeById}?id=${id}`);
  },
  createMake(request) {
    return postData(ApiUris.makeApi.createMake, request);
  },
  editMake(request) {
    return postData(ApiUris.makeApi.editMake, request);
  },
};
