import React, { useState, createContext, useCallback } from "react";
import { typeListApi } from "../api";


/**
 * Type list context
 */
export const TypeListContext = createContext();

/**
 * Type list provider
 */
export const TypeListProvider = ({ children }) => {
  const [typeList, setTypeList] = useState([]);
  const [typeListById, setTypeListById] = useState(null);
  const [loading, setLoading] = useState(false);

  const getTypeListApi = async (values) => {
    setLoading(true);
    const response = await typeListApi.geTypeList({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setTypeList(response?.data?.data);
      setLoading(false);
    } else {
      setTypeList([]);
      setLoading(false);
    }

    return response.data;
  };
  const getTypeListByIdApi = async (id) => {
    setLoading(true);
    const response = await typeListApi.getTypeListByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setTypeListById(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
    const createTypeListApi = async (values) => {
    setLoading(true);
    const response = await typeListApi.createTypeList(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
        setTypeList(response?.data?.data);
      getTypeListApi();
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const editTypeListApi = async (values) => {
    setLoading(true);
    const response = await typeListApi.editTypeList(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      getTypeListApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const contextValue = {
    typeList,
    setTypeList,
    loading,
    setLoading,
    setTypeListById,
    typeListById,
    getTypeListApi: useCallback((request) => {
      getTypeListApi(request);
    }, []),
    createTypeListApi: useCallback((request) => {
      createTypeListApi(request);
    }, []),
    editTypeListApi: useCallback((request) => {
        editTypeListApi(request);
    }, []),
    getTypeListByIdApi: useCallback((request) => {
      getTypeListByIdApi(request);
    }, []),
    // deleteDealerByIdApi: useCallback((request) => {
    //   deleteDealerByIdApi(request);
    // }, []),
  };

  return (
    <TypeListContext.Provider value={contextValue}>
      {children}
    </TypeListContext.Provider>
  );
};
