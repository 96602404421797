import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Fleet List Api method declarations
 */
export const fleetListApi = {
  getFleetList(request) {
    return postData(ApiUris.fleetListApi.getFleetList, request);
  },
  getFleetListByid(id) {
    return getData(`${ApiUris.fleetListApi.getFleetListById}/${id}`);
  },
  deleteFleetListByid(id) {
    return deleteData(`${ApiUris.fleetListApi.deleteFleetById}?id=${id}`);
  },
  createFleetList(request) {
    return postData(ApiUris.fleetListApi.addFleetList, request);
  },
  editFleetList(request) {
    return postData(ApiUris.fleetListApi.updateFleetList, request);
  },
  fleetListVehicleInfo(request) {
    return postData(ApiUris.fleetListApi.fleetListVehicleInfo, request);
  },
  multipleFleetList(request) {
    return postData(ApiUris.fleetListApi.multipleFleet, request);
  },
  multipleFleetDelete(request) {
    return postData(ApiUris.fleetListApi.multipleFleetDelete, request);
  },
};
