import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Drawer, Tab, Tabs, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import moment from "moment/moment";
import Papa from "papaparse";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom/dist";
import sampleCSV from "../../assets/csv/SampleMPGList.csv";
import { fuelEconomyColumn } from "../../common/constants/gridRowColumn";
import { useFuelEconomy } from "../../common/hooks/fueleconomy-hook";
import { SearchBar } from "../../components/SearchBar";
import { showToast } from "../../components/Toast";
import { useDebounce } from "../../components/useDebounce";
import { layoutStyle } from "../layout-ui/style";
import ShowMpgData from "./../../components/ShowMpgData";
import { TruckLoader } from "./truckLoader";
import YourStaticFile from "./vehicles.csv";
import {
  csvFileToArray,
  xlsxFileToArray,
} from "../../common/utils/excelUpload";
import { getDefaultColDef } from "../../common/constants/commonFunction";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const FuelEconomy = (props) => {
  const classes = layoutStyle();
  const [file, setFile] = useState();
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openMpg, setOpenMpg] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsMpg, setRowsMpg] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { fuelEconomyCreateMultiple, fuelEconomy } = useFuelEconomy();
  const { state } = useLocation();
  const [rowData, setRowData] = useState([]);
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const userType = localStorage.getItem("type");
  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };
  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };
  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };
  const searchData = rowData?.filter((fuelEconomy) =>
    fuelEconomy?.make?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const handleClose = () => {
    setValue(0);
    setOpen(false);
    setRows([]);
    setColumns([]);
  };

  const debouncedSearch = useDebounce(onSearchFunc, 500);

  useEffect(() => {
    if (searchValue) {
      setRows([...searchData]);
    } else if (rowData?.length > 0) {
      setRows([...rowData]);
    } else {
      setRows([]);
    }
  }, [searchValue, rowData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      setOpen(true);
    }
  };

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (state?.data) {
      // setRowDataFinal([...state.data]);
      setRows([...state.data]);
    } else {
      // setRowDataFinal([...rowData]);
    }
  }, [state]);

  // Wrapper function to handle file parsing based on type
  const fileToArray = async (file, type) => {
    if (type === "csv") {
      const text = await file.text();
      return csvFileToArray(text, "mpg");
    } else if (["xlsx", "xls"].includes(type)) {
      return xlsxFileToArray(file, "mpg");
    } else {
      throw new Error("Unsupported file type");
    }
  };

  useEffect(() => {
    if (columns?.length > 0) {
      columns?.map((item) => {
        item["width"] = 160;
        return true;
      });
    }
  }, [columns]);

  useEffect(() => {
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      let type = "";
      if (fileType === "csv") {
        type = "csv";
      } else if (["xlsx", "xls"].includes(fileType)) {
        type = "xlsx";
        const fileType = file.name.split(".").pop().toLowerCase();
        let type = "";
        if (fileType === "csv") {
          type = "csv";
        } else if (["xlsx", "xls"].includes(fileType)) {
          type = "xlsx";
        }

        fileToArray(file, type)
          .then(({ data, headers }) => {
            setRows(data);
            setColumns(
              headers.map((header) => ({ field: header, headerName: header }))
            );
            setColumns(
              headers.map((header) => ({ field: header, headerName: header }))
            );
          })
          .catch((error) => {
            console.error("Error parsing file:", error);
          });
      } else {
        setRows([]);
        setColumns([]);
      }
    }
  }, [file]);

  const [data, setData] = useState([]);
  let date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  useEffect(() => {
    handleParse();
  }, []);

  const handleParse = async () => {
    try {
      setLoader(true);
      const response = await fetch(YourStaticFile);
      const csvText = await response.text();
      const csv = Papa.parse(csvText, { header: true });
      const parsedData = csv?.data;
      let dataPa = [];
      parsedData?.map((items, index) => {
        dataPa.push(items);
        return true;
      });
      setData(parsedData);
      setLoader(false);
    } catch (error) {
      console.error("Error parsing CSV:", error);
    }
  };

  useEffect(() => {
    if (data?.length > 0) {
      data?.map((items, index) => {
        items["id"] = index + 1;
        return true;
      });
      setRowData([...data]);
    }
  }, [data]);

  useEffect(() => {
    if (rows?.length > 0) {
      rows?.map((items) => {
        items["year"] = Number(items.year);
        items["make"] = items.make;
        items["mpg"] = Number(items.comb08) || Number(items.mpg);
        items["mpg"] = Number(items.comb08) || Number(items.mpg);
        items["model"] = items.model;
        items["id"] = items.id;
      });
    }
    setRowsMpg([...fuelEconomy, ...rows]);
  }, [fuelEconomy, rows]);
  // const handleDownload = () => {
  //   const blob = new Blob([sampleCSV], { type: "text/csv" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "Sample MPG List.csv";
  //   document.body.appendChild(link);
  //   link.click();
  //   URL.revokeObjectURL(url);
  //   document.body.removeChild(link);
  // };

  const handleRowSelection = (event) => {
    setSelectedRows(event.api.getSelectedNodes().map((node) => node.data));
  };

  const downloadCsv = () => {
    const link = document.createElement("a");
    link.href = sampleCSV;
    link.setAttribute("download", "Sample MPG List.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAddButton = async () => {
    if (!file) {
      showToast("Please select a file.");
      return;
    }
    let payload = [];
    rows?.map((items) => {
      let values = {
        year: items.year,
        mpg: items.mpg,
        governmentId: Number(items.governmentid),
        make: items.make,
        model: items.model,
        id: items.id,
        id: items.id,
      };
      payload.push(values);
    });
    await fuelEconomyCreateMultiple(payload);
    handleClose();
    setRows([]);
  };
  useEffect(() => {
    setRows(rows);
  }, [rows]);
  return (
    <Box className={classes.mainContent}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Fuel Economy"
              {...a11yProps(0)}
              sx={{ textTransform: "capitalize", fontWeight: 600 }}
            />

            <Tab
              label="Upload MPG CSV / XLSX"
              {...a11yProps(1)}
              sx={{ textTransform: "capitalize", fontWeight: 600 }}
            />
          </Tabs>
        </Box>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Box className={classes.urlDivFuelPrice}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box>
              {userType === "admin" && (
                <>
                  <span style={{ fontWeight: "bold", marginRight: 5 }}>
                    URL:
                  </span>
                  <a
                    href="https://www.fueleconomy.gov/feg/epadata/vehicles.csv"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none", color: "gray" }}
                  >
                    https://www.fueleconomy.gov/feg/epadata/vehicles.csv
                  </a>
                </>
              )}
            </Box>
            <Box className={classes.disFlexCenter}>
              <Button
                className={classes.fleetListNewButton}
                onClick={() => setOpenMpg(true)}
              >
                Manual Data
              </Button>
              <SearchBar onSearch={onSearch} />
            </Box>
          </Box>
        </Box>

        {loader ? (
          <TruckLoader />
        ) : (
          <Box className={classes.gridContantEconomy}>
            <AgGridReact
              className="ag-theme-quartz"
              suppressRowClickSelection
              pagination={true}
              rowData={rowsMpg}
              defaultColDef={defaultColDef}
              columnDefs={fuelEconomyColumn}
              onSelectionChanged={handleRowSelection}
            />
          </Box>
        )}
      </CustomTabPanel>

      <Drawer
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <DialogTitle
          className={classes.dailogTitle}
          id="customized-dialog-title"
        >
          Upload MPG CSV / XLSX
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography
              sx={{ fontWeight: "bold", mr: 2, mt: 0, color: "#18333A" }}
            >
              CSV / XLSX File
            </Typography>
            <Box className={classes.uploadButton}>
              <form style={{ position: "relative" }}>
                <input
                  id="filePicker"
                  accept=".csv"
                  onChange={handleOnChange}
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    bottom: "0",
                  }}
                  type={"file"}
                />
                <label
                  htmlFor="filePicker"
                  style={{
                    background: "#18333A",
                    color: "white",
                    padding: "8px 25px",
                    borderRadius: "5px",
                  }}
                >
                  Upload
                </label>
              </form>
            </Box>
          </Box>

          <Box
            sx={{
              height: "calc(100vh - 335px)",
              width: "100%",
            }}
          >
            <AgGridReact
              className="ag-theme-quartz"
              rowSelection="multiple"
              pagination={true}
              paginationPageSize={5}
              paginationPageSizeSelector={[5, 10, 20]}
              rowData={rows}
              defaultColDef={defaultColDef}
              columnDefs={columns}
              checkboxSelection
              onRowSelected={handleRowSelection}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "20px !important" }}>
          <Button
            variant="contained"
            className={classes.fleetListNewButton}
            onClick={handleAddButton}
          >
            Add
          </Button>
          <Button
            href="#"
            variant="outlined"
            style={{
              color: "#18333A",
              fontWeight: "1000",
              border: "2px solid red",
            }}
            style={{
              color: "#18333A",
              fontWeight: "1000",
              border: "2px solid red",
            }}
            onClick={downloadCsv}
          >
            Download Sample File
          </Button>
          <Button
            variant="contained"
            className={classes.fleetListNewButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Drawer>
      {openMpg && <ShowMpgData open={openMpg} setOpen={setOpenMpg} />}
    </Box>
  );
};
