import React, { createContext, useCallback, useState } from "react";
import { showToast } from "../../components/Toast";
import { fuelAnalysisListApi } from "../api";

/**
 * FuelAnalysis list context
 */
export const FuelAnalysisContext = createContext();

/**
 * FuelAnalysis list provider
 */
export const FuelAnalysisProvider = ({ children }) => {
  const [fuelAnalysisList, setFluelAnalysisList] = useState();
  const [fuelListFilter, setFuelListFilter] = useState();
  const [fuelAnalysisById, setAnalysisById] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState();

  const getFuelAnalysisApi = async (values) => {
    setLoading(true);
    var filterReq =
      values?.filter && Object.keys(values?.filter)?.length > 0
        ? { ...values?.filter }
        : null;
    const response = await fuelAnalysisListApi.getFuelAnalysisList({
      ...values,
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: filterReq,
      sortColumn: "FleetNo",
      sortType: "desc",
      sorting: {
        fieldName: "FleetNo",
        direction: "desc",
      },
    });
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data?.length > 0) {
      setFluelAnalysisList(response?.data?.data);
      setLoading(false);
    } else {
      setFluelAnalysisList([]);
      setLoading(false);
    }

    return response.data;
  };
  const getFuelAnalysisByIdApi = async (id) => {
    setLoading(true);
    const response = await fuelAnalysisListApi.getFuelAnalysisListByid(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setAnalysisById(response?.data?.data);
      setLoading(false);
    } else {
      setAnalysisById([]);
      setLoading(false);
    }

    return response.data;
  };
  const getFuelAnalysisApiList = async (values) => {
    setLoading(true);
    const response = await fuelAnalysisListApi.getListFuelAnalysisList({
      ...values,
      companyId: values?.companyId,
      sortColumn: "FleetNo",
      sortType: "desc",
      sorting: {
        fieldName: "FleetNo",
        direction: "desc",
      },
    });
    if (response?.error) {
      setLoading(false);
    }
    if (response?.data?.data?.length > 0) {
      setFuelListFilter(response?.data?.data);
      setLoading(false);
    } else {
      setFuelListFilter([]);
      setLoading(false);
    }

    return response.data;
  };
  const createFuelAnalysisListApi = async (values) => {
    setLoading(true);
    const response = await fuelAnalysisListApi.createFuelAnalysisList(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      getFuelAnalysisApiList({ companyId: values?.companyId });
      setLoading(false);
      showToast("Fuel analysis created successfully!");
    } else {
      showToast("Something went wrong Try again!");
      setLoading(false);
    }

    return response.data;
  };
  const editFuelAnalysisApi = async (values) => {
    setLoading(true);
    const response = await fuelAnalysisListApi.editFuelAnalysisList(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      let payload = {
        companyId: values?.companyId,
        dealerId: values?.dealerId,
        fleetNo: values?.fleetNo ? values?.fleetNo : "",
      };
      getFuelAnalysisApiList(payload);
      setLoading(false);
      showToast("Fuel analysis updated successfully!");
    } else {
      showToast("Something went wrong Try again!");
      setLoading(false);
    }
    return response.data;
  };

  const deleteFuelAnalysisListByIdApi = async (value) => {
    setLoading(true);
    const response = await fuelAnalysisListApi.deleteFuelAnalysisListByid(
      value?.id
    );

    if (response?.error) {
      setLoading(false);
    }
    if (response?.data) {
      getFuelAnalysisApiList({ companyId: value?.companyId });
      showToast("Fuel analysis deleted successfully!");
      setLoading(false);
    } else {
      setLoading(false);
      showToast("Something went wrong Try again!");
    }

    return response.data;
  };

  const contextValue = {
    fuelAnalysisList,
    setFluelAnalysisList,
    loading,
    setLoading,
    fuelAnalysisById,
    setAnalysisById,
    editData,
    setEditData,
    fuelListFilter,
    setFuelListFilter,
    getFuelAnalysisApi: useCallback((request) => {
      getFuelAnalysisApi(request);
    }, []),
    createFuelAnalysisListApi: useCallback((request) => {
      createFuelAnalysisListApi(request);
    }, []),
    editFuelAnalysisApi: useCallback((request) => {
      editFuelAnalysisApi(request);
    }, []),
    getFuelAnalysisByIdApi: useCallback((request) => {
      getFuelAnalysisByIdApi(request);
    }, []),
    getFuelAnalysisApiList: useCallback((request) => {
      getFuelAnalysisApiList(request);
    }, []),
    deleteFuelAnalysisListByIdApi: useCallback((request) => {
      deleteFuelAnalysisListByIdApi(request);
    }, []),
  };

  return (
    <FuelAnalysisContext.Provider value={contextValue}>
      {children}
    </FuelAnalysisContext.Provider>
  );
};
