import { Box, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { fuelPriceColumnData } from "../../common/constants/gridRowColumn";
import { useUser } from "../../common/hooks";
import { SearchBar } from "../../components/SearchBar";
import { useDebounce } from "../../components/useDebounce";
import { layoutStyle } from "../layout-ui/style";
import { TruckLoader } from "./truckLoader";
import { getDefaultColDef } from "../../common/constants/commonFunction";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const FuelPrice = () => {
  const classes = layoutStyle();
  const [rows, setRows] = useState([]);
  console.log("rows", rows);
  const [sheetData, setSheetData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { userData } = useUser();
  const [searchValue, setSearchValue] = useState("");
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  // const [columns, setColumns] = useState([]);

  const userType = localStorage.getItem("type");

  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  let date = `${new Date().toLocaleString()}${new Date().getTime()}`;

  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };

  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };

  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };

  const searchData = rows?.filter((fuel) =>
    fuel?.State?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const debouncedSearch = useDebounce(onSearchFunc, 500);

  useEffect(() => {
    if (searchValue) {
      setRows([...searchData]);
    } else if (sheetData?.length > 0) {
      setRows([...sheetData]);
    }
  }, [searchValue, sheetData]);

  useEffect(() => {
    const getSheetAPI = async () => {
      try {
        setLoader(true);
        fetch("https://sheetdb.io/api/v1/1ptqgpcsze04g")
          .then((response) => response.json())
          .then((data) => {
            setSheetData(data);
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
      }
    };
    getSheetAPI();
  }, []);
  useEffect(() => {
    if (sheetData?.length > 0) {
      sheetData?.map((item, index) => {
        item["id"] = index + 1;
        return true;
      });
      setRows(sheetData);
    }
  }, [sheetData]);

  // useEffect(() => {
  //   if (columns?.length > 0) {
  //     columns?.map((item) => {
  //       item["width"] = 160;
  //       return true;
  //     });
  //   }
  // }, [columns]);
  // useEffect(()=>{
  //   let getRowClass =document.querySelectorAll('.MuiDataGrid-row')
  //   if(getRowClass){
  //     getRowClass.forEach((item, index) => {
  //       (index % 2 === 0) ? item.classList.add('oddClassRow'):item.classList.add('evenClassRow')
  //   })
  //   }
  // },[])
  return (
    <Box className={classes.mainContent}>
      <Box className={classes.gridContant}>
        <Box className={classes.urlDivFuelPrice}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box>
              {userType === "admin" && (
                <>
                  <span style={{ fontWeight: "bold", marginRight: 5 }}>
                    URL:
                  </span>
                  <a
                    href="https://gasprices.aaa.com/state-gas-price-averages/"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none", color: "gray" }}
                  >
                    https://gasprices.aaa.com/state-gas-price-averages/
                  </a>
                </>
              )}
            </Box>
            <SearchBar onSearch={onSearch} />
          </Box>
          {/* <Box>Date: {date} </Box> */}
        </Box>
        {loader ? (
          <TruckLoader />
        ) : (
          <Box className={classes.gridContantDealer}>
            <AgGridReact
              className="ag-theme-quartz"
              suppressRowClickSelection
              rowHeight={35}
              pagination={true}
              rowData={rows}
              defaultColDef={defaultColDef}
              columnDefs={fuelPriceColumnData}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
