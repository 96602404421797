import React, { createContext, useCallback, useState } from "react";
import { showToast } from "../../components/Toast";
import { modelApi, newVehicleApi } from "../api";

/**
 * New Vehicle context
 */
export const NewVehicleContext = createContext();

/**
 * New Vehicle provider
 */
export const NewVehicleProvider = ({ children }) => {
  const [newVehicle, setNewVehicle] = useState();
  const [newVehicleById, setNewVehicleById] = useState(null);
  const [loading, setLoading] = useState(false);
  const [requireNewvehicleFields, setRequireNewVehicleFields] = useState([]);
  const [toastClose, setToastClose] = useState(true);

  const getNewVehicleApi = async (values) => {
    setLoading(true);
    const response = await newVehicleApi.getNewVehicle({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      ...values,
      filter: {
        ...values?.filter,
      },
      sortColumn: "FleetNo",
      sortType: "desc",
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      setLoading(false);
      setToastClose(false);
    }

    if (response?.data?.data) {
      setToastClose(true);
      setNewVehicle(response?.data?.data);
      setLoading(false);
    } else {
      setToastClose(false);
      setNewVehicle([]);
      setLoading(false);
    }

    return response.data;
  };
  const getNewVehicleByIdApi = async (id) => {
    setLoading(true);
    const response = await newVehicleApi.getNewVehicleByid(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setNewVehicleById(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const deleteNewVehicleByIdApi = async (id) => {
    setLoading(true);
    const response = await newVehicleApi.deleteNewVehicleByid(id?.id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      getNewVehicleApi({ companyId: id?.companyId });
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const createNewVehicleApi = async (values) => {
    setLoading(true);
    const response = await newVehicleApi.createNewVehicle(values);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setNewVehicle(response?.data?.data);
      getNewVehicleApi({
        companyId: values?.companyId,
        dealerId: values?.dealerId,
      });
      setLoading(false);
      showToast("Funding/New vehicle created successfully!");
    } else {
      showToast("Something went wrong Try again!");
      setLoading(false);
    }
    return response.data;
  };
  const editNewVehicleApi = async ({ values, newAdd }) => {
    setLoading(true);
    if (newAdd == "Add") {
      await modelApi.createModel({
        id: values?.modelId,
        name: values?.model,
        makeId: values?.makeId,
        yearId: values?.yearId,
      });
    }
    const response = await newVehicleApi.editNewVehicle(values);
    if (response?.error) {
      setLoading(false);
    }
    if (response?.data?.data) {
      let payload = {
        companyId: values?.companyId,
        fleetNo: values?.fleetNo,
        dealerId: values?.dealerId,
      };
      getNewVehicleApi(payload);
      setLoading(false);
      showToast("Funding/New vehicle updated successfully!");
    } else {
      showToast("Something went wrong Try again!");
      setLoading(false);
    }

    return response.data;
  };
  const editEstimatedNewVehicleApi = async (values) => {
    const response = await newVehicleApi.editNewVehicle(values);
    if (response?.error) {
    }
    if (response?.data?.data) {
      showToast("Estimated Value updated successfully!");
    } else {
      showToast("Something went wrong Try again!");
    }

    return response.data;
  };
  const multipleNewVehicleApi = async (values) => {
    setLoading(true);
    const response = await newVehicleApi.multipleEditNewVehicle(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      getNewVehicleApi({
        companyId: values?.companyId,
        dealerId: values?.dealerId,
      });
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    newVehicle,
    setNewVehicle,
    loading,
    setLoading,
    setNewVehicleById,
    newVehicleById,
    requireNewvehicleFields,
    setRequireNewVehicleFields,
    toastClose,
    setToastClose,
    getNewVehicleApi: useCallback((request) => {
      getNewVehicleApi(request);
    }, []),
    createNewVehicleApi: useCallback((request) => {
      createNewVehicleApi(request);
    }, []),
    editNewVehicleApi: useCallback((request, newAdd) => {
      editNewVehicleApi(request, newAdd);
    }, []),
    getNewVehicleByIdApi: useCallback((request) => {
      getNewVehicleByIdApi(request);
    }, []),
    multipleNewVehicleApi: useCallback((request) => {
      multipleNewVehicleApi(request);
    }, []),
    deleteNewVehicleByIdApi: useCallback((id) => {
      deleteNewVehicleByIdApi(id);
    }, []),
    editEstimatedNewVehicleApi: useCallback((request) => {
      editEstimatedNewVehicleApi(request);
    }),
  };

  return (
    <NewVehicleContext.Provider value={contextValue}>
      {children}
    </NewVehicleContext.Provider>
  );
};
