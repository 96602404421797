import { deleteData, getData, postData } from "./base-api";
import ApiUris, { baseURL } from "./apiUris";

/**
 * Year Api method declarations
 */
export const yearApi = {
  getYear(request) {
    return postData(ApiUris.yearApi.getYear, request);
  },
  getYearById(request) {
    return getData(`${ApiUris.yearApi.getYearById}/${request}`, request);
  },
  createYear(request) {
    return postData(ApiUris.yearApi.createYear, request);
  },
  updateYear(request) {
    return postData(ApiUris.yearApi.editYear, request);
  },
  deleteYearByid(id) {
    return deleteData(`${ApiUris.yearApi.deleteYearById}?id=${id}`);
  },
};
