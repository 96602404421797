import { makeStyles } from "@mui/styles";

export const AdminPageStyle = makeStyles((theme) => {
  return {
    container: {
      backgroundImage: `url("/bgimg.jpg")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
      position: "relative",
    },

    backgroundVideo: {
      position: "absolute",
      width: "100% !important",
      objectFit: "cover",
      transitionTimingFunction: "ease",
    },

    formContainer: {
      padding: "20px",
      position: "absolute",
      background: "red",
      width: "500px",
      height: "250px",
      float: "right",
      margin: "10px",
      [theme.breakpoints.only("sm")]: {
        "@media screen and (min-width: 520px)": {
          width: "250px ",
          height: "220px ",
          background: "green",
        },
      },
      [theme.breakpoints.only("xs")]: {
        "@media screen and (min-width: 320px)": {
          width: "150px !important",
          height: "120px !important",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        },
      },
    },

    // container: {
    //   backgroundImage: `url("/bgimg.jpg")`,
    //   backgroundSize: "cover",
    //   backgroundPosition: "center",
    //   height: "100vh",
    //   position: "relative",
    // },

    // formContainer: {
    //   padding: "20px",
    //   position: "absolute",
    //   background: "red",
    //   width: "500px",
    //   height: "250px",
    //   float: "right",
    //   margin: "10px",
    //   [theme.breakpoints.only("sm")]: {
    //     "@media screen and (min-width: 520px)": {
    //       width: "250px ",
    //       height: "220px ",
    //       background: "green",
    //     },
    //   },
    //   [theme.breakpoints.only("xs")]: {
    //     "@media screen and (min-width: 320px)": {
    //       width: "150px !important",
    //       height: "120px !important",
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       flexDirection: "column",
    //     },
    //   },
    // },
    mainContent: {
      height: `calc(100vh - 170px)`,
      marginTop: "60px",
      marginLeft: "240px",
      padding: 20,
      background: "white",
      marginBottom: "60px",
    },
    mainAnalysisContent: {
      marginTop: "65px",
      marginLeft: "240px",
      padding: 20,
      background: "white",
      marginBottom: "60px",
    },
    fleetPaper: {
      margin: 10,
      padding: 10,
      border: "1px solid lightgray",
      borderRadius: 5,
    },
    fleetPaperMainDiv: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      // height: "auto",
      [theme.breakpoints.only("sm")]: {
        "@media screen and (max-width: 768px)": {
          overflowX: "scroll",
        },
      },
    },
    textFleetList: {
      fontSize: 14,
      fontWeight: "bold",
      marginLeft: 12,
    },
    fleetListSecDiv: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      width: "100%",
      margin: 2,

      ".MuiButtonBase-root": {
        width: "25%",
      },
    },
    divMt10: {
      marginTop: 5,
    },
    bgGraycolor: {
      color: "white !important",
      backgroundColor: "#18333A !important",
      boxShadow: "1px 0px 5px 1px gray",
      padding: "0px !important",
      marginRight: "11px !important",
      width: "25%",

      // width: "20%",
    },

    fleetListButton: {
      fontSize: 12,
      display: "flex",
      justifyContent: "left",
    },
    fleetListButtonText: {
      fontSize: 12,
      textTransform: "capitalize",
      textAlign: "left",
      fontWeight: "500 !important",
    },
    fleetListInformation: {
      margin: 10,
    },
    textGeneralInfo: {
      fontSize: 14,
      fontWeight: "700 !important",
      textTransform: "capitalize !important",
    },
    textGeneralInfoResult: {
      fontSize: 14,
      fontWeight: "700 !important",
      textTransform: "capitalize !important",
      borderBottom: "1px solid gray",
      paddingTop: "0px !important",
    },
    textinput: {
      "& .MuiFilledInput-underline::before": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiFilledInput-underline::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiInputBase-root-MuiInput-root-MuiSelect-root": {
        padding: "0px !important",
        fontSize: "12px !important",
        color: "#18333A !important",
      },
      "& .MuiInput-root": {
        padding: "0px !important",
        fontSize: "12px !important",
        color: "#18333A !important",
      },
      "& .MuiInput-underline::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiSelect-standard::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      fontSize: "12px !important",
      color: "#18333A !important",
    },
    fleetListGenInfoTtext: {
      width: "160px",
      marginRight: 20,
      fontSize: "12px !important",
      color: "#18333A !important",
      fontWeight: "500 !important",
    },
    fleetListMileageText: {
      width: "200px",
      fontSize: "12px !important",
      color: "gray !important",
      fontWeight: "500 !important",
    },
    fleetListTotalMileageText: {
      width: "200px",
      fontSize: "12px !important",
      color: "96B82C !important",
      fontWeight: "500 !important",
    },
    fleetListGenInfoInputText: {
      width: "200px",
      fontSize: "12px !important",
      color: "#18333A !important",
      "@media screen and (max-width: 1356px)": {
        fontSize: "11px !important",
        marginRight: "0px !important",
      },
    },
    fleetListGenInfoDiv: {
      display: "flex",
      alignItems: "center",
    },
    fleetListDefultText: {
      width: "120px",
      fontSize: "12px !important",
      color: "gray !important",
      fontWeight: "500 !important",
    },
    mainContentFleet: {
      height: `auto`,
      marginTop: "60px",
      marginLeft: "240px",
      padding: 20,
      background: "white",
      marginBottom: "60px",
    },
    btn1: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      "&:focus": {
        background: "red !important",
      },
    },
    btn2: {
      background: "white !important",
      color: "#18333A",
      padding: "5px",
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      width: "25px",
      "&:focus": {
        background: "red !important",
      },
    },
    btn3: {
      background: "#96B82C",
      padding: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderTopRightRadius: "4px !important",
      borderBottomRightRadius: "4px !important",
      width: "calc(100% - 25px)",
      "&:focus": {
        background: "red !important",
      },
    },
    focusVisible: {
      background: "red !important",
      "&:focus": {
        background: "red !important",
      },
    },
    saveButtonFleet: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerFleet: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 5,
      marginLeft: "17px !important",
      background: "#18333A",
      marginTop: "20px !important",
      color: "white",
      fontWeight: 600,
    },
    reportButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#18333A !important",
      background: "#18333A !important",
    },
    headerAnalysisReport: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "12px !important",
    },
    headerAnalysisName: {
      fontWeight: 600,
      padding: "5px 30px",
      background: "#96B82C",
      color: "white",
    },
    gridContantReport: {
      height: `calc(100vh - 465px)`,
      width: "100%",
      marginLeft: 5,
      fontSize: "12px !important",
      // "@media screen and (max-width: 1439px)": {
      //   height: `calc(100vh - 160px)`,
      // },
      "@media screen and (max-width: 1385px)": {
        height: `calc(100vh - 470px)`,
      },
    },
    analysisDivMix: {
      padding: 10,
      fontSize: 12,
      fontWeight: 600,
    },
    headerButtonNext: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    analysisSaveBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerSaveButton: {
      marginLeft: "10px",
      color: "#18333A !important",
      fontWeight: "800 !important",
      backgroundColor: "#fff !important  ",
    },
    totalSavingText: {
      backgroundColor: "#96B82C !important",
      borderRadius: "4px",
      padding: "7px 5px",
      fontSize: "14px",
      fontWeight: 600,
      color: "white",
    },
    estimatedValueBox: {
      justifyContent: "end",
      display: "flex",
      marginTop: 10,
    },
    estimatedValueButton: {
      justifyContent: "end",
      display: "flex",
      alignItems: "center",
      background: "#949300 !important",
      color: "white !important",
    },
    aggridAnalysisReport: {
      width: "100%",
    },
    analysisListGenInfoTtext: {
      width: "460px",
      marginRight: 20,
      fontSize: "12px !important",
      color: "#18333A !important",
      fontWeight: "500 !important",
      "@media screen and (max-width: 1356px)": {
        fontSize: "11px !important",
        marginRight: "0px !important",
      },
    },
    reportAnalysisButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#18333A !important",
      background: "#18333A !important",
      padding: "2px 7px !important",
      fontSize: "10px !important",
    },
    reportAnalysisSaveButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#18333A !important",
      background: "#18333A !important",
      padding: "5px 8px !important",
      fontSize: "12px !important",
    },
    analysisResultTextDiv: {
      display: "flex",
      alignItems: "center",
    },
    accordinAnalysisResult: {
      height: "135px !important",
      "@media screen and (max-width: 1356px)": {
        height: "120px !important",
        padding: "8px 5px 16px !important",
      },
      "@media screen and (max-width: 1200px)": {
        height: "150px !important",
        padding: "8px 5px 16px !important",
      },
      "@media screen and (max-width: 995px)": {
        height: "170px !important",
        padding: "8px 5px 16px !important",
      },
    },
    analysisTotalSavingButton: {
      display: "flex",
      alignItems: "center",
    },
    exportPowerBIButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#18333A !important",
      background: "#18333A !important",
      padding: "3px 15px !important",
      fontSize: "10px !important",
    },
    loadingCircular: {
      width: "20px",
      height: "20px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    fuelPriceSign: {
      marginRight: 2,
    },
  };
});
