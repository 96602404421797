export const removeCurrency = (currencyValue) => {
  if (!currencyValue) {
    return 0;
  } else {
    var returnValue = currencyValue.replace("$", "");
    if (returnValue) {
      var amt = Number(returnValue.trim());
      return amt;
    } else {
      return 0;
    }
  }
};

export const getCurrencyValue = (currencyValue) => {
  var numberValue = 0;
  if (currencyValue) {
    numberValue = Number(currencyValue).toFixed(2);
  }

  const formattedNumber = addCommas(numberValue);
  return "$ " + formattedNumber;
};

function addCommas(number) {
  if (number) {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "0";
  }
}

function removeCommas(number) {
  return number?.toString()?.replace(/,/g, "");
}

export const removeCommaAndRemoveCurrency = (currencyValue) => {
  if (!currencyValue) {
    return 0;
  } else {
    let removeComma = removeCommas(currencyValue);
    let removeCurrency1 = removeCurrency(removeComma);
    return removeCurrency1;
  }
};

export const addComma = (number) => {
  return number
    ? number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "0";
};

export const removeComma = (number) => {
  let numberValue = Number(number);
  return numberValue ? numberValue?.toString()?.replace(/,/g, "") : "0";
};

export const commaTextField = (number) => {
  let numverValue = number ? number : "0";
  if (!isNaN(numverValue)) {
    numverValue = numverValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return numverValue;
};
