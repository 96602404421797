import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { AdminPageStyle } from "../../pages/admin/style";
import { addComma } from "../../common/services/number-service";

export const FleetInfo = ({
  companyNameHeader,
  state,
  fleetNo,
  fleetList,
  vehicleInfoValue,
  fuelListById,
  typeListById,
  setChecked,
  cycleYear,
  setVehicleInfoValue,
  setFleetNo,
}) => {
  const classes = AdminPageStyle();
  const handleFleetChange = (values, event) => {
    setChecked(new Array(cycleYear?.length).fill(false));
    let data = event;
    setFleetNo(data?.fleetId);
    let fleetData = fleetList?.find((item) => item?.fleetId == data?.fleetId);
    setVehicleInfoValue(fleetData);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          fleet information
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Company</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {companyNameHeader?.companyName}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Fleet</span>

                <Autocomplete
                  id="combo-box-demo"
                  disablePortal
                  disabled={state?.data ? true : false}
                  defaultValue={fleetNo}
                  className={classes.textinput}
                  sx={{ width: "148px !important" }}
                  onChange={handleFleetChange}
                  options={fleetList}
                  getOptionLabel={(option) => option.fleetNo}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Age</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {vehicleInfoValue?.age}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Fuel</span>
                <span>{vehicleInfoValue ? fuelListById?.name : ""}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>Type</span>
                <span>{vehicleInfoValue ? typeListById?.name : ""}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  CPM Category
                </span>
                <span>{vehicleInfoValue?.cpmCategory}</span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Vehicle Total Miles
                </span>
                <span>
                  {addComma(Math.round(vehicleInfoValue?.totalMiles))}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListGenInfoTtext}>
                  Vehicle Annual Mileage
                </span>
                <span>
                  {addComma(Math.round(vehicleInfoValue?.annualMileage))}
                </span>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
