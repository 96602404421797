import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AdminPageStyle } from "./style";
import { useLocation } from "react-router-dom";
import { useCompany } from "../../common/hooks";

export const PowerBiReport = () => {
  const classes = AdminPageStyle();
  const { state } = useLocation();
  const { companyNameHeader } = useCompany();
  const [companyName, setCompanyName] = useState();
  const userId = localStorage.getItem(`cashflow_admin_user_id`);

  useEffect(() => {
    if (companyNameHeader) {
      setCompanyName(companyNameHeader.companyName);
    }
  }, [companyNameHeader]);

  return (
    <Box className={classes.mainAnalysisContent}>
      <div>
        <a
          target="_blank"
          href={`https://app.powerbi.com/reportEmbed?reportId=3152b473-d927-4c9f-8af2-07d95c0ed29a&autoAuth=true&ctid=8dcaf237-a1c0-4a77-871c-e36fe00d5d29&filter=CyclingComparisons/Id 
eq ${state?.id} and Fleet_Mix/Id eq ${state?.id} and FuelCost/Id eq ${state?.id} and MaintenanceCost/Id eq ${state?.id} and View_Fleet/Id eq ${state?.id}`}
        >
          Go To Power BI Report
        </a>
      </div>
      <div>
        <a href="/dashboard">Go to Dashboard</a>
      </div>
    </Box>
  );
};
