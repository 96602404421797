import React from "react";
import { AdminPageStyle } from "../../pages/admin/style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { StyledPopper } from "../../pages/admin/FleetList/styledrop";

export const CurrentVehicleInfo = ({
  vehicleInfoValue,
  fleetList,
  vehicleInfoData,
  loadingEstimatedValue,
  state,
  setVehicleInfoValue,
  currentResaleValue,
  setCurrentResaleValue,
  handleEstimatedValue,
}) => {
  const classes = AdminPageStyle();
  const { register, setValue } = useForm({});
  const handleFleetChange = (values, event) => {
    if (event) {
      let data = event;
      setVehicleInfoValue(data?.fleetId);
    }
  };
  const handleCurrentResale = (event) => {
    setCurrentResaleValue(event.target.value);
    setValue("currentEstimatedResaleValue", event.target.value);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.textGeneralInfo}
        >
          current vehicle information
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>Fleet</span>
                <Autocomplete
                  id="combo-box-demo"
                  disablePortal
                  disabled={state?.data ? true : false}
                  defaultValue={vehicleInfoValue}
                  className={classes.textinput}
                  sx={{ width: "148px !important" }}
                  onChange={handleFleetChange}
                  options={fleetList}
                  getOptionLabel={(option) => option?.fleetNo}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                  PopperComponent={(props) => <StyledPopper {...props} />}
                />
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>Year</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {vehicleInfoData?.year}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>Make</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {vehicleInfoData?.make}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>Model</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {vehicleInfoData?.model}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>VIN</span>
                <span className={classes.fleetListGenInfoInputText}>
                  {vehicleInfoData?.vin}
                </span>
              </Box>
            </Box>
            <Box className={classes.divMt10}>
              <Box className={classes.fleetListGenInfoDiv}>
                <span className={classes.fleetListTotalMileageText}>
                  Current Vehicle Estimated Resale Value
                </span>
                {loadingEstimatedValue ? (
                  <CircularProgress
                    color="inherit"
                    style={{ width: 20, height: 20 }}
                  />
                ) : (
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value={currentResaleValue}
                    className={classes.textinput}
                    {...register("currentEstimatedResaleValue", {
                      valueAsNumber: true,
                    })}
                    onChange={handleCurrentResale}
                  />
                )}
              </Box>
              <Box className={classes.estimatedValueBox}>
                <Button
                  className={classes.estimatedValueButton}
                  onClick={handleEstimatedValue}
                >
                  Estimate Value
                </Button>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
