import { styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";

export const StyledPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-listbox": {
    backgroundColor: "white",
    padding: "8px",
    borderRadius: "4px",
    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
  },
  "& .MuiAutocomplete-option": {
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

export const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: "calc(100vh - 430px)",
      maxHeight: 30 * 4,
    },
  },
  MenuListProps: {
    style: {
      padding: 0,
    },
  },
};
