import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Drawer,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useCompany } from "../../common/hooks";
import { useDealer } from "../../common/hooks/dealer-hook";
import { layoutStyle } from "../../pages/layout-ui/style";
import { MenuProps } from "./FleetList/styledrop";
import { showToast } from "../../components/Toast";

export default function AddDealer({ open, editValue, handleClose }) {
  const classes = layoutStyle();
  const {
    createDealerApi,
    setDealerById,
    editDealerApi,
    errorMessage,
    setErrorMessage,
  } = useDealer();
  const [selectedCompany, setSelectedCompany] = useState([]);
  const { company, getCompanyApi } = useCompany();
  const [page, setPage] = useState({ pageSize: 10000, page: 0 });
  const defaultValue = {
    dealerName: "",
    region: "",
    address: "",
    location: "",
  };
  const generateInitialValues = (props) => {
    if (editValue) {
      for (const key in defaultValue) {
        if (key in editValue && editValue[key] !== null) {
          if (Array.isArray(defaultValue[key])) {
            defaultValue[key] =
              Array.isArray(editValue[key]) && editValue[key].length > 0
                ? editValue[key]
                : defaultValue[key];
          } else {
            defaultValue[key] = editValue[key];
          }
        }
      }
    }
    return defaultValue;
  };
  const schema = yup.object().shape({
    dealerName: yup.string().required("Dealer Name is required."),
    region: yup.string().required("Region is required."),
    address: yup.string().required("Address is required."),
  });

  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       // maxHeight: "calc(100vh - 430px)",
  //       maxHeight: 30 * 4,
  //     },
  //   },
  //   MenuListProps: {
  //     style: {
  //       padding: 0,
  //     },
  //   },
  // };

  useEffect(() => {
    reset(generateInitialValues());
  }, [editValue]);

  useEffect(() => {
    getCompanyApi({ limit: page.pageSize, offset: page.page });
  }, []);

  useEffect(() => {
    if (editValue) {
      if (editValue?.companies?.length > 0) {
        let companyId = [];
        editValue?.companies?.map((items) => companyId.push(items.id));
        setSelectedCompany(companyId);
      }
    } else {
      setSelectedCompany([]);
    }
  }, [editValue]);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm({
    defaultValues: generateInitialValues(),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };
  const onSubmit = async (values) => {
    let dataCompany = selectedCompany?.filter((items) => items !== undefined);
    const payload = {
      ...values,
      status: true,
      allowCompanies: dataCompany,
      // allowCompanies: true,
      users: "User",
      deleted: false,
      userCreated: true,
    };
    if (editValue) {
      const editPayload = {
        ...payload,
        id: editValue?.id,
      };
      await editDealerApi(editPayload);
    } else {
      await createDealerApi(payload);
    }
    handleClose();
  };

  useEffect(() => {
    if (errorMessage) {
      showToast(errorMessage);
      setErrorMessage("");
    } else {
      setErrorMessage("");
      handleClose();
      reset();
    }
  }, [errorMessage]);

  return (
    <React.Fragment>
      <Drawer
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <DialogTitle
          className={classes.dailogTitle}
          id="customized-dialog-title"
        >
          {editValue ? "Edit Dealer" : "Add Dealer"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container className={classes.disFlexCenter}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid className={classes.companyForm}>
                <Grid className={classes.compnayFormField}>
                  <InputLabel className={classes.fontSize12}>
                    Dealer Name
                  </InputLabel>
                  <TextField
                    error={errors?.dealerName}
                    name="dealerName"
                    type="text"
                    fullWidth
                    {...register("dealerName")}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel className={classes.fontSize12}>
                    Dealer Address
                  </InputLabel>
                  <TextField
                    error={errors?.address}
                    type="text"
                    fullWidth
                    name="address"
                    {...register("address")}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel className={classes.fontSize12}>
                    Dealer Region
                  </InputLabel>
                  <TextField
                    error={errors?.region}
                    type="text"
                    fullWidth
                    name="region"
                    {...register("region")}
                  />
                </Grid>
                <Grid>
                  <InputLabel className={classes.fontSize12}>
                    Company Name
                  </InputLabel>
                  <Select
                    value={selectedCompany}
                    onChange={handleCompanyChange}
                    fullWidth
                    multiple
                    sx={{ fontSize: 12 }}
                    MenuProps={MenuProps}
                  >
                    {company?.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company?.companyName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.fleetListNewButton}
                  autoFocus
                  sx={{ mt: 3, height: "50px" }}
                >
                  {editValue ? "Edit Dealer" : "Add Dealer"}
                </Button>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
}
