import React from "react";
import Chart from "react-apexcharts";
import { getFileName } from "../../../common/constants/commonFunction";

export const MixChart = ({
  fleetMixLabel,
  vehicleEquityData,
  anualNeedsData,
  cashOutlayData,
  currentPaymentData,
  cyclingPaymentData,
  fleetSizeData,
  fleetSpeedData,
  savingsData,
  maintenanceFuel,
}) => {
  const series = [
    {
      name: "Vehicle Equity",
      data: vehicleEquityData,
    },
    // {
    //   name: "Anual Needs",
    //   data: anualNeedsData,
    // },
    // {
    //   name: "Cash Outlay",
    //   data: cashOutlayData,
    // },
    {
      name: "Current Payment",
      data: currentPaymentData,
    },
    {
      name: "Cycling Payment",
      data: cyclingPaymentData,
    },
    {
      name: "Fleet Spend",
      data: fleetSpeedData,
    },
    // {
    //   name: "Fleet Size",
    //   data: fleetSizeData,
    // },
    {
      name: "Maintenance & Fuel Spend",
      data: maintenanceFuel,
    },
    {
      name: "Savings",
      data: savingsData,
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: getFileName("5YearPlan_chart"),
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: getFileName("5YearPlan_chart"),
          },
          png: {
            filename: getFileName("5YearPlan_chart"),
          },
        },
        autoSelected: "zoom",
      },
    },
    colors: ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#FF8C33", "#dfc7a7"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "text",
      categories: fleetMixLabel?.sort(),
      axisBorder: {
        show: true,
        color: "#000",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Math.round(value);
        },
      },
      axisBorder: {
        show: true,
        width: 1,
        color: "#000",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  return (
    savingsData.length > 0 &&
    currentPaymentData.length > 0 &&
    vehicleEquityData.length > 0 &&
    cyclingPaymentData.length > 0 &&
    fleetSpeedData.length > 0 && (
      <Chart options={options} series={series} type="area" height={300} />
    )
  );
};
