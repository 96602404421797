import React, { useEffect, useState } from "react";
import { useMake } from "../common/hooks";
import { AdminPageStyle } from "../pages/admin/style";
import { Autocomplete, TextField } from "@mui/material";

export const MakeSelect = ({
  editData,
  setMakeValue,
  setMakeName,
  width,
  setModelValueData,
  setMpgData,
}) => {
  const { make, getMakeApi } = useMake();
  const [makeValueData, setMakeValueData] = useState(null);
  const classes = AdminPageStyle();
  const handleMakeChange = (event, values) => {
    const id = values?.id;
    if (id) {
      setMakeValue(id);
      setMakeValueData(values);
      setMakeName(values?.name);
      setModelValueData(null);
    } else {
      setMakeValue();
      setMakeValueData();
      setMakeName();
      setMpgData(0);
      setModelValueData();
    }
  };
  useEffect(() => {
    getMakeApi();
  }, []);

  useEffect(() => {
    if (editData?.makeId) {
      setMakeValue(editData?.makeId);
      if (editData?.makeId) {
        let data =
          editData &&
          make?.filter((item) => item?.id === editData?.makeId)?.[0];
        setMakeValueData(data);
      }
    }
  }, [make, editData]);

  return (
    <Autocomplete
      id="combo-box-demo"
      disablePortal
      value={makeValueData}
      className={classes.textinput}
      sx={{ width: width ? width : "148px !important" }}
      onChange={handleMakeChange}
      options={make}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.makeId}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params, option) => (
        <TextField {...params} variant="standard" />
      )}
    />
  );
};
