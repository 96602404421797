import { Flip, toast, ToastContainer } from "react-toastify";

export const showToast = (message) => {
  toast(message, {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Flip,
  });
};

const Toast = () => {
  return (
    <ToastContainer
      position="top-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default Toast;

export const showErrorToast = (message, close) => {
  if (message && !close) {
    toast(message, {
      position: "top-center",
      autoClose: close ? 1 : false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
      className: "custom-toast",
      onClose: toast.dismiss(),
    });
  } else {
    toast.dismiss();
  }
};
