import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { layoutStyle } from "../pages/layout-ui/style";
import { DISPLAY_MESSAGES } from "../common/constants/popupMessage";
import { useUser } from "../common/hooks";

export const UserControlDialogue = ({ open, setOpen, data }) => {
  const { updateUser } = useUser();
  const classes = layoutStyle();

  const handleClick = async () => {
    await updateUser(data);

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {data?.status === "Activate"
            ? DISPLAY_MESSAGES.USER_CONTROL_ACTIVATE
            : data?.status === "DeActivate"
            ? DISPLAY_MESSAGES.USER_CONTROL_DEACTIVATE
            : DISPLAY_MESSAGES.ERROR}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            type="submit"
            className={classes.deleteCancelButton}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            type="submit"
            className={classes.deleteOkButton}
            onClick={handleClick}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
