import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { Drawer, Grid, InputLabel, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useUser } from "../../common/hooks/user";
import { layoutStyle } from "../layout-ui/style";
import { USER_CONTROL } from "../../common/constants/popupMessage";

const AddUserDealer = ({ open, setOpen, rowData, setRowData }) => {
  const classes = layoutStyle();
  const navigate = useNavigate();
  const { createUser, getUser, userData, setUserData, updateUser } = useUser();
  const userType = localStorage.getItem("type");
  const userTypeId = localStorage.getItem("cashflow_admin_user_id");
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    setUserData(null);
    reset();
    setRowData(null);
  };

  const initialValues = {
    name: "",
    email: "",
    userName: "",
    password: "",
    phone: "",
    type: "",
  };

  const schema = yup.object().shape({
    name: yup.string().required("Name is required."),
    email: yup.string().required("Email is required."),
    userName: yup.string().required("Username is required."),
    phone: yup.string().required("Phone is required."),
    type: yup.string().required("Type is required."),
    password: yup.string().required("Password is required."),
  });
  const generateInitialValues = (props) => {
    if (rowData) {
      for (const key in initialValues) {
        if (key in rowData && rowData[key] !== null) {
          if (Array.isArray(initialValues[key])) {
            initialValues[key] =
              Array.isArray(rowData[key]) && rowData[key].length > 0
                ? rowData[key]
                : initialValues[key];
          } else {
            initialValues[key] = rowData[key];
          }
        }
      }
    }

    return initialValues;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: generateInitialValues(),
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    reset(generateInitialValues());
  }, [rowData]);

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      type: userType,
      typeId: userTypeId,
      // status: USER_CONTROL.ACTIVATE,
      createdBy: Number(userTypeId),
      updatedBy: Number(userTypeId),
    };

    if (rowData) {
      const editPayload = {
        ...payload,
        id: rowData?.id,
      };
      const newData = { ...editPayload };
      // delete newData.password;

      await updateUser(newData);
      // await getUser();
    } else {
      await createUser(payload);
      // navigate("/manage-userDealer");
    }
    handleClose();
  };

  useEffect(() => {
    if (userType === "dealer") {
      setValue("type", userType);
    }
  }, [rowData]);
  return (
    <React.Fragment>
      <Drawer
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <DialogTitle
          className={classes.dailogTitle}
          id="customized-dialog-title"
        >
          {rowData ? " Edit Dealer User" : " Add Dealer User"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container className={classes.disFlexCenter}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid className={classes.companyForm}>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Name</InputLabel>
                  <TextField
                    error={errors?.name}
                    type="text"
                    fullWidth
                    color="success"
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("name")}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Email</InputLabel>
                  <TextField
                    error={errors?.email}
                    type="text"
                    fullWidth
                    color="success"
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("email")}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Username</InputLabel>
                  <TextField
                    error={errors?.userName}
                    type="text"
                    fullWidth
                    color="success"
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("userName")}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Password</InputLabel>
                  <TextField
                    error={errors?.password}
                    type="text"
                    fullWidth
                    color="success"
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("password")}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Phone</InputLabel>
                  <TextField
                    error={errors?.phone}
                    type="text"
                    fullWidth
                    color="success"
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("phone")}
                  />
                </Grid>
                <Grid className={classes.compnayFormField}>
                  <InputLabel style={{ fontSize: "12px" }}>Type</InputLabel>
                  <TextField
                    error={errors?.type}
                    type="text"
                    fullWidth
                    color="success"
                    disabled={userType === "dealer"}
                    InputProps={{ style: { fontSize: "12px", height: "35px" } }}
                    {...register("type")}
                  />
                </Grid>

                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.fleetListNewButton}
                  autoFocus
                  fullWidth
                  sx={{ mt: 3 }}
                >
                  {rowData ? "Edit Dealer" : "Add Dealer"}
                </Button>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
};

export default AddUserDealer;
