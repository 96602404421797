import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Company Api method declarations
 */
export const analysisResultApi = {
  getAnalysisResult(request) {
    return postData(ApiUris.analysisResultListApi.getAnalysisResultList, request);
  },
  getAnalysisResultByid(id) {
    return getData(`${ApiUris.analysisResultListApi.getAnalysisResultById}/${id}`);
  },
  deleteAnalysisResultByid(id) {
    return deleteData(`${ApiUris.analysisResultListApi.deleteAnalysisResultList}?id=${id}`);
  },
  createAnalysisResult(request) {
    return postData(ApiUris.analysisResultListApi.addAnalysisResultList, request);
  },
  editAnalysisResult(request) {
    return postData(ApiUris.analysisResultListApi.updateAnalysisResultList, request);
  },
};
