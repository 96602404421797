export const useDebounce = (func, delay) => {
  let timerId;

  return (...args) => {
    // If there is existing timer, clear itm
    if (timerId) {
      clearTimeout(timerId);
    }

    // start a new timer
    timerId = setTimeout(() => {
      func(args);
    }, delay);
  };
};
