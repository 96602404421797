import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SearchBar } from "./SearchBar";
import { sidebarMenu } from "../common/constants/commonFunction";
import { layoutStyle } from "../pages/layout-ui/style";
import { TAB_CONTENT, TAB_VALUE } from "../common/constants/popupMessage";
import { ReplacementYearCount } from "./ReplacementYearCount";

export const ReplacementHeaderButton = ({
  onSearch,
  setSelectedNav,
  countYear,
}) => {
  const navigation = useNavigate();
  const classes = layoutStyle();

  const handleNext = (event) => {
    setSelectedNav(sidebarMenu.maintenance);
    navigation("/maintenance-analysis");
  };
  const handleBack = () => {
    setSelectedNav(sidebarMenu.fleetList);
    navigation("/fleet-list");
  };

  const handleAddButton = () => {
    navigation("/new-fleetlist", {
      state: {
        tabValue: TAB_VALUE.FLEET_LIST,
        selectedContent: TAB_CONTENT.FLEET_LIST,
      },
    });
  };
  return (
    <Stack
      direction="row"
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <Box className={classes.headerNameText}>Replacement Schedule</Box>
      <ReplacementYearCount classes={classes} countYear={countYear} />
      <Box className={classes.addEditGrid}>
        <Button
          variant="outlined"
          className={classes.fleetListNewButton}
          onClick={handleBack}
        >
          <span style={{ fontSize: 12 }}>Back</span>
        </Button>
        <Button
          variant="outlined"
          className={classes.fleetListNewButton}
          onClick={handleNext}
        >
          <span style={{ fontSize: 12 }}>Next</span>
        </Button>
        <Button
          variant="outlined"
          className={classes.fleetListNewButton}
          onClick={handleAddButton}
        >
          NEW
        </Button>
        <SearchBar onSearch={onSearch} />
      </Box>
    </Stack>
  );
};
