import React from "react";
import { layoutStyle } from "../layout-ui/style";
import { Grid, TextField, Button, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { rowData } from "../../common/constants/gridRowColumn";
import { useForm } from "react-hook-form";

export const EditFleetList = () => {
  const classes = layoutStyle();
  const navigation = useNavigate();
  const { state } = useLocation();
  const { register, handleSubmit } = useForm();

  const handleSave = (e) => {
    let row = [...rowData];
    let payload = {
      ...e,
      id: rowData.length + 1,
    };
    row.push(payload);

    navigation("/fleet-list", { state: { data: row } });
  };
  return (
    <Box className={classes.mainContent}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <h3>Edit Fleet List</h3>
        <Button
          className={classes.fleetListNewButton}
          onClick={handleSubmit(handleSave)}
        >
          Save
        </Button>
      </Box>
      <Grid container>
        <form>
          <Grid
            container
            lg={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item lg={4}>
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Name"
                variant="outlined"
                defaultValue={state?.fleetNo}
                {...register("name")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Age"
                variant="outlined"
                defaultValue={state?.age}
                {...register("age")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Year"
                variant="outlined"
                defaultValue={state?.year}
                {...register("year")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Make"
                variant="outlined"
                defaultValue={state?.make}
                {...register("make")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Model"
                variant="outlined"
                defaultValue={state?.model}
                {...register("model")}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Fuel"
                variant="outlined"
                defaultValue={state?.fuel}
                {...register("fuel")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Type"
                variant="outlined"
                defaultValue={state?.type}
                {...register("type")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Drive Train"
                variant="outlined"
                defaultValue={state?.drivetrain}
                {...register("drivetrain")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="CPM Category"
                variant="outlined"
                defaultValue={state?.cpmcategory}
                {...register("cpmCategory")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="MPG"
                variant="outlined"
                defaultValue={state?.mpg}
                {...register("mpg")}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="VIN"
                variant="outlined"
                defaultValue={state?.vin}
                {...register("vin")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Driver Name"
                variant="outlined"
                defaultValue={state?.driverName}
                {...register("driverName")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Total Miles"
                variant="outlined"
                defaultValue={state?.totalMiles}
                {...register("totalMiles")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Annual Miles"
                variant="outlined"
                defaultValue={state?.annualMilege}
                {...register("annualMilege")}
              />
              <TextField
                style={{ width: "90%", margin: "5px" }}
                type="text"
                label="Monthly Miles"
                variant="outlined"
                defaultValue={state?.monthlyMilege}
                {...register("monthlyMilege")}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Box>
  );
};
