export function sumYearValues(replacementList) {
  let year1Value = 0;
  let year2Value = 0;
  let year3Value = 0;
  let year4Value = 0;
  let year5Value = 0;

  replacementList?.forEach((item) => {
    year1Value += Number(item["year1"] || 0);
    year2Value += Number(item["year2"] || 0);
    year3Value += Number(item["year3"] || 0);
    year4Value += Number(item["year4"] || 0);
    year5Value += Number(item["year5"] || 0);
  });

  return { year1Value, year2Value, year3Value, year4Value, year5Value };
}

export function sumEquityGains(newVehicle) {
  let equity1Gain = 0;
  let equity2Gain = 0;
  let equity3Gain = 0;
  let equity4Gain = 0;
  let equity5Gain = 0;

  newVehicle?.forEach((item) => {
    equity1Gain += Number(item["year1"] || 0);
    equity2Gain += Number(item["year2"] || 0);
    equity3Gain += Number(item["year3"] || 0);
    equity4Gain += Number(item["year4"] || 0);
    equity5Gain += Number(item["year5"] || 0);
  });

  return { equity1Gain, equity2Gain, equity3Gain, equity4Gain, equity5Gain };
}

export function sumMaintenanceCosts(maintanance) {
  let sumMaintenance = 0;
  let cycleYear1 = 0;
  let cycleYear2 = 0;
  let cycleYear3 = 0;
  let cycleYear4 = 0;
  let cycleYear5 = 0;

  maintanance?.forEach((item) => {
    sumMaintenance += Number(item["currentYearTotalCost"] || 0);
    cycleYear1 += Number(item["year_1_TotalCost"] || 0);
    cycleYear2 += Number(item["year_2_TotalCost"] || 0);
    cycleYear3 += Number(item["year_3_TotalCost"] || 0);
    cycleYear4 += Number(item["year_4_TotalCost"] || 0);
    cycleYear5 += Number(item["year_5_TotalCost"] || 0);
  });

  return {
    sumMaintenance,
    cycleYear1,
    cycleYear2,
    cycleYear3,
    cycleYear4,
    cycleYear5,
  };
}

export function sumFuelCosts(fuelListFilter) {
  let fuelYear1 = 0;
  let fuelYear2 = 0;
  let fuelYear3 = 0;
  let fuelYear4 = 0;
  let fuelYear5 = 0;

  fuelListFilter?.forEach((item) => {
    fuelYear1 += Number(item["annualFuelCostYear1"] || 0);
    fuelYear2 += Number(item["annualFuelCostYear2"] || 0);
    fuelYear3 += Number(item["annualFuelCostYear3"] || 0);
    fuelYear4 += Number(item["annualFuelCostYear4"] || 0);
    fuelYear5 += Number(item["annualFuelCostYear5"] || 0);
  });

  return { fuelYear1, fuelYear2, fuelYear3, fuelYear4, fuelYear5 };
}
