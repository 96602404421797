import { postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Account Api method declarations
 */
export const AccountApi = {
  login(request) {
    return postData(ApiUris.authenticate.loginUser, request);
  },
  verifyOtp(request) {
    return postData(ApiUris.authenticate.verifyOtp, request);
  },
};
