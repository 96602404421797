import { useState, useEffect } from "react";

const useNewVehicleStatistics = (newVehicle, fleetList) => {
  const [averageNewVehicleCost, setAverageNewVehicleCost] = useState(0);
  const [paymentFundingAmount, setPaymentFundingAmount] = useState(0);
  const [monthlyLeaseCost, setMonthlyLeaseCost] = useState(0);
  const [monthlyPaymentAmount, setMonthlyPaymentAmount] = useState(0);

  useEffect(() => {
    if (newVehicle?.length > 0) {
      let sum = 0;
      let averageFuel = 0;
      let year1Equity = 0;
      let year2Equity = 0;
      let year3Equity = 0;
      let year4Equity = 0;
      let year5Equity = 0;

      newVehicle.forEach((item) => {
        sum += Number(item["paymentAmount"] || 0);
        averageFuel += Number(item["newVehicleCost"] || 0);
        year1Equity += Number(item["year1"] || 0);
        year2Equity += Number(item["year2"] || 0);
        year3Equity += Number(item["year3"] || 0);
        year4Equity += Number(item["year4"] || 0);
        year5Equity += Number(item["year5"] || 0);
      });

      let averageNewVehicleCost = averageFuel / (fleetList?.length || 1);
      setAverageNewVehicleCost(averageNewVehicleCost);

      setPaymentFundingAmount(sum);
      let monthlyPayment = sum / 12;
      setMonthlyLeaseCost(sum);
      setMonthlyPaymentAmount(monthlyPayment);
    }
  }, [newVehicle, fleetList]);

  return {
    averageNewVehicleCost,
    paymentFundingAmount,
    monthlyLeaseCost,
    monthlyPaymentAmount,
  };
};

export default useNewVehicleStatistics;
