import React, { useEffect, useState } from "react";
import { Box, Button, Drawer, IconButton, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { AgGridReact } from "ag-grid-react";
import { UploadCsvDataColumn } from "../common/constants/gridRowColumn";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import sampleCSV from "../assets/csv/SampleFleetList.csv";
import {
  useDealer,
  useDriveTrain,
  useFuelList,
  useTypeList,
} from "../common/hooks";
import { typeConstant } from "../common/constants/commonFunction";
import { PopupMessage } from "../common/constants/popupMessage";
import { CsvUploadErrors } from "../common/constants/csvUploadError";
import { showToast } from "./Toast";
import { csvFileToArray, xlsxFileToArray } from "../common/utils/excelUpload";
export const FleetCsvUpload = ({
  handleClose,
  open,
  classes,
  defaultColDef,
  handleRowSelection,
  addButtonDisabled,
  setRowDataCsv,
  columnFinal,
  setAddButtonDisabled,
  rows,
  setRows,
  file,
  setFile,
}) => {
  const { loadingFleetFile, uploadCSVDealerApi } = useDealer();
  const { fuelList, getFuelListApi } = useFuelList();
  const { driveTrainList, getDriveTrainListApi } = useDriveTrain();
  const { typeList, getTypeListApi } = useTypeList();
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (columns?.length > 0) {
      columns?.map((item) => {
        item["width"] = 160;
        return true;
      });
    }
  }, [columns]);
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };
  const downloadCsv = () => {
    const link = document.createElement("a");
    link.href = sampleCSV;
    link.setAttribute("download", "Sample Fleet List.csv");

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const handleCellValueChanged = (event) => {
    const newValue = event.newValue;
    const rowData = event.data;
    const field = event.colDef.field;
  };

  useEffect(() => {
    getFuelListApi();
    getDriveTrainListApi();
    getTypeListApi();
  }, []);

  const handleAddButton = async (e) => {
    e.stopPropagation();
    setAddButtonDisabled(true);
    if (!file) {
      showToast(PopupMessage.selectFile);
      return;
    }
    let { rowData, error } = await CsvUploadErrors(rows, columnFinal);
    rowData?.map((items, index) => {
      fuelList?.map((item) => {
        if (item?.name === items?.fuel) {
          rowData[index].fuelId = item?.id;
        }
      });
    });
    rowData?.map((items, index) => {
      driveTrainList?.map((item) => {
        if (item?.name === items?.driveTrain) {
          rowData[index].driveTrainId = item?.id;
        }
      });
    });
    rowData?.map((items, index) => {
      typeList?.map((item) => {
        if (item?.name === items?.type) {
          rowData[index].typeId = item?.id;
        }
      });
    });
    setRowDataCsv(rowData);
    if (error?.length == 0) {
      let typeId;
      let type = localStorage.getItem("type");
      if (type === typeConstant.dealer) {
        typeId = localStorage.getItem("typeId");
      }
      let payload;
      if (typeId) {
        payload = {
          dealerId: typeId,
          data: rows,
        };
      } else {
        payload = {
          dealerId: null,
          data: rows,
        };
      }
      // let payload = {
      //   ...rows,
      //   companyId: companyId,
      // };
      showToast(PopupMessage.uploadFile);
      await uploadCSVDealerApi(payload);
      setRowDataCsv([]);
      // navigation("/fleet-list");
    }
  };
  const fileToArray = async (file, type) => {
    if (type === "csv") {
      const text = await file.text();
      return csvFileToArray(text);
    } else if (type === "xlsx") {
      return xlsxFileToArray(file);
    } else {
      throw new Error("Unsupported file type");
    }
  };
  useEffect(() => {
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      let type = "";
      if (fileType === "csv") {
        type = "csv";
      } else if (["xlsx", "xls"].includes(fileType)) {
        type = "xlsx";
      }

      fileToArray(file, type)
        .then(({ data, headers }) => {
          setRows(data);
          setColumns(
            headers.map((header) => ({ field: header, headerName: header }))
          );
        })
        .catch((error) => console.error("Error processing file:", error));
    } else {
      setRows([]);
      setColumns([]);
    }
  }, [file]);

  return (
    <Drawer
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
      anchor="bottom"
      fullHeight
      style={{ top: "100px ", bottom: "56px !important" }}
      className="DrawerName"
    >
      <DialogTitle className={classes.dailogTitle} id="customized-dialog-title">
        Upload Fleet CSV / XLSX
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography
            sx={{ fontWeight: "bold", mr: 2, mt: 0, color: "#18333A" }}
          >
            CSV / XLSX File
          </Typography>
          <Box className={classes.uploadButton}>
            <form style={{ position: "relative" }}>
              <input
                id="filePicker"
                accept=".csv"
                onChange={handleOnChange}
                style={{
                  visibility: "hidden",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0",
                  bottom: "0",
                }}
                type={"file"}
              />
              <label
                htmlFor="filePicker"
                style={{
                  background: "#18333A",
                  color: "white",
                  padding: "8px 25px",
                  borderRadius: "5px",
                }}
              >
                Upload
              </label>
            </form>
          </Box>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 347px)",
            width: "100%",
          }}
        >
          <AgGridReact
            className="ag-theme-quartz"
            rowSelection="multiple"
            pagination={true}
            rowData={rows}
            defaultColDef={defaultColDef}
            columnDefs={UploadCsvDataColumn}
            checkboxSelection
            onRowSelected={handleRowSelection}
            onCellValueChanged={(event) => handleCellValueChanged(event)}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "20px !important" }}>
        <Button
          variant="contained"
          className={classes.fleetListNewButton}
          disabled={addButtonDisabled}
          onClick={handleAddButton}
        >
          {loadingFleetFile ? "Uploading..." : "Add"}
        </Button>
        <Button
          href="#"
          variant="outlined"
          style={{
            color: "#18333A",
            fontWeight: "1000",
            border: "2px solid red",
          }}
          onClick={downloadCsv}
        >
          Download Sample File
        </Button>
        <Button
          variant="contained"
          className={classes.fleetListNewButton}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Drawer>
  );
};
