import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  formatChartTooltipValue,
  getFileName,
} from "../../../common/constants/commonFunction";

export const MaintananceLineChart = ({ seriesData, labels }) => {
  const [data, setData] = useState([
    seriesData?.Current,
    seriesData?.Year1,
    seriesData?.Year2,
    seriesData?.Year3,
    seriesData?.Year4,
    seriesData?.Year5,
  ]);

  useEffect(() => {
    if (seriesData) {
      setData([
        seriesData?.Current,
        seriesData?.Year1,
        seriesData?.Year2,
        seriesData?.Year3,
        seriesData?.Year4,
        seriesData?.Year5,
      ]);
    }
  }, [seriesData]);

  const series = [
    {
      name: "",
      data: data,
    },
  ];
  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: getFileName("Maintenance_chart"),
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: getFileName("Maintenance_chart"),
          },
          png: {
            filename: getFileName("Maintenance_chart"),
          },
        },
        autoSelected: "zoom",
      },
    },
    colors: ["#118DFF", "#118DFF"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },

    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Current", "Year1", "Year2", "Year3", "Year4", "Year5"],
      title: {
        text: "Maintenance Year",
      },
      axisBorder: {
        show: true,
        color: "#000",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      title: {
        text: "Maintenance Cost",
      },

      tooltip: {
        y: {
          formatter: (val) => formatChartTooltipValue(val),
          height: 1,
        },
      },
      axisBorder: {
        show: true,
        width: 1,
        color: "#000",
        offsetX: 0,
        offsetY: 0,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };
  return <Chart options={options} series={series} type="line" height={280} />;
};
