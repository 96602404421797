import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * User Api method declarations
 */
export const UserAPi = {
  getUser(request) {
    return postData(ApiUris.userApi.getUser, request);
  },

  createUser(request) {
    return postData(ApiUris.userApi.createUser, request);
  },

  updateUser(request) {
    return postData(ApiUris.userApi.updateUser, request);
  },

  deleteUser(request) {
    return deleteData(`${ApiUris.userApi.deleteUser}?id=${request}`);
  },

  getbyId(request) {
    return getData(`${ApiUris.userApi.getbyId}/${request}`,request );
  }
  
};
