import { Button } from "@mui/material";
import React from "react";
import { layoutStyle } from "../../pages/layout-ui/style";
import { useSpeechSynthesis } from "react-speech-kit";
import { PlayArrow } from "@mui/icons-material";

export const SpeakToTextButton = ({ value }) => {
  const classes = layoutStyle();
  const { speak } = useSpeechSynthesis();
  return (
    <span
      variant="contained"
      className={"speakToTextButton"}
      onClick={() =>
        speak({
          text: value,
        })
      }
    >
      <PlayArrow />
    </span>
  );
};
