import React, { createContext, useState, useCallback } from "react";
import { createTheme } from "@mui/material";
import { themeType } from "../constants/theme-type";

export const ThemeContext = createContext();

const createThemes = (typeOfTheme) => {
  return createTheme({
    palette: {
      primary: {
        main: "#098AE3",
        dark: "#034e87",
        light: "#549ed6",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#9CBF2E",
        dark: "#F5F5F5",
        light: "#9CBF2E",
        contrastText: "#ffffff",
      },
      type: typeOfTheme,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundColor: "#9CBF2E",

            "&:hover": {
              backgroundColor: "#9CBF2E",
            },

            "&:focus:not(:hover)": {
              backgroundColor: "#9CBF2E",
            },
          },
        },
      },
    },
  });
};

export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(
    createThemes(themeType.light)
  );

  const contextValue = {
    currentTheme,
    changeTheme: useCallback((type) => onChangeThemeType(type), []),
  };

  const onChangeThemeType = (typeOfTheme) => {
    const theme = createThemes(typeOfTheme);
    setCurrentTheme(theme);
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};
