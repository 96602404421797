import { Box } from "@mui/material";
import React from "react";

export const ReplacementYearCount = ({ classes, countYear }) => {
  return (
    <Box>
      <div className={classes.countReplacementYear}>
        <div>
          <span className={classes.countReplacementYearSpan}>
            Y1 : Proposed ({countYear?.year1 > 0 ? countYear?.year1 : 0})
          </span>
        </div>
        <div>
          <span className={classes.countReplacementYearSpan}>
            Y2 : Proposed ({countYear?.year2 > 0 ? countYear?.year2 : 0})
          </span>
        </div>
        <div>
          <span className={classes.countReplacementYearSpan}>
            Y3 : Proposed ({countYear?.year3 ? countYear?.year3 : 0})
          </span>
        </div>
        <div>
          <span className={classes.countReplacementYearSpan}>
            Y4 : Proposed ({countYear?.year4 > 0 ? countYear?.year4 : 0})
          </span>
        </div>
        <div>
          <span className={classes.countReplacementYearSpan}>
            Y5 : Proposed ({countYear?.year5 > 0 ? countYear?.year5 : 0})
          </span>
        </div>
      </div>
    </Box>
  );
};
