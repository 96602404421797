import { showToast } from "../../components/Toast";

export const CsvUploadErrors =(rows,column)=>{
    let errorColumns = [];
    
    rows?.map((items, index) => {
        items.year = Number(items.year);
        items.totalMiles = Number(items.totalMiles);
        if (items?.model == "" || items?.model == null) {
            column?.map((items1) => {
            if (items1.field == "model") {
              // items['index'] = index
              errorColumns.push({ field: "model", index: index });
            }
          });
          showToast(`Model field is required on line number ${index + 1}! `);
        }else if(items?.make == "" || items?.make == null){
          showToast(`Make field is required on line number ${index + 1}! `);
        }else if(items?.year == null || items?.year == ''){
          showToast(`Year field is required on line number ${index + 1}! `);
        }else if(items?.totalMiles == null || items?.totalMiles == ''){
          showToast(`Total Miles field is required on line number ${index + 1}! `);
        }else if(items?.stateCode == '' || items?.stateCode == null){
          showToast(`State code field is required on line number ${index + 1}! `);
        }else if(items?.dealer == '' || items?.dealer == null){
          showToast(`Dealer field is required on line number ${index + 1}! `);
        }else if(items?.company == '' || items?.company == null){
            showToast(`Company field is required on line number ${index + 1}! `);
        }else if(isNaN(items?.year)){
          showToast(`Year field is not a number on line number ${index + 1}! `);
        }else if(isNaN(items?.totalMiles)){
          showToast(`Total Miles field is not a number on line number ${index + 1}! `);
        }
        
      })

    return {rowData:rows,error:errorColumns}
}