import { Box } from "@mui/material";
import React from "react";

export const RequireFieldComponent = ({ requireFields, classes }) => {
  return (
    <Box>
      {requireFields &&
        requireFields?.length > 0 &&
        requireFields?.map((items, index) => {
          if (index !== requireFields?.length - 1) {
            return (
              <span className={classes.requireFieldValue}>{items + ","}</span>
            );
          } else {
            return (
              <span className={classes.requireFieldValueLast}>{items}</span>
            );
          }
        })}
      {requireFields && requireFields?.length > 0 && (
        <span className={classes.columnRequired}>
          columns in fleet list is required to generate report.
        </span>
      )}
    </Box>
  );
};
