import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  TextField,
  Button,
  Grid,
  InputLabel,
} from "@mui/material";
import React, { useState } from "react";
import { layoutStyle } from "../pages/layout-ui/style";
import { useSupport } from "../common/hooks";

export const SupportModal = ({ open, close, userData }) => {
  const classes = layoutStyle();
  let data = new FormData();
  const { supportData } = useSupport();

  const [binaryImage, setBinaryImage] = useState(null);
  const [formValues, setFormValues] = useState({
    name: userData?.name,
    userName: userData?.userName,
    email: userData?.email,
    description: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    data.append("File", binaryImage);
    e.preventDefault();
    let payload = {
      ...formValues,
      userId: userData?.id,
      image: binaryImage,
    };
    // Handle form submission logic here
    await supportData(payload);
    // close();
  };
  const imageToBinary = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const binaryString = reader.result;
        resolve(binaryString);
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsBinaryString(file);
    });
  };

  const handleSupportImage = async (event) => {
    let file = event.target.files[0];
    try {
      await imageToBinary(file);
      setBinaryImage(file);
    } catch (error) {
      console.error("Error converting image to binary:", error);
    }
  };
  return (
    <Box>
      <Drawer
        open={open}
        onClose={close}
        maxWidth="sm"
        anchor="bottom"
        fullHeight
        style={{ top: "100px ", bottom: "56px !important" }}
        className="DrawerName"
      >
        <DialogTitle id="modal-title" className={classes.dailogTitle}>
          Support
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="modal-description">
            <Box sx={{ maxWidth: 500, margin: "auto", mt: 5 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="name"
                      label="Name"
                      fullWidth
                      className={classes.supportInput}
                      value={formValues.name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="userName"
                      label="User Name"
                      fullWidth
                      className={classes.supportInput}
                      value={formValues.userName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                      type="email"
                      fullWidth
                      className={classes.supportInput}
                      value={formValues.email}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Description"
                      type="text"
                      fullWidth
                      className={classes.supportInput}
                      value={formValues.description}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.compnayFormField}>
                    <InputLabel className={classes.fontSize12}>
                      Image
                    </InputLabel>
                    <TextField
                      type="file"
                      fullWidth
                      inputProps={{
                        accept: "image/jpeg, image/png, image/gif, image/bmp",
                      }}
                      onChange={handleSupportImage}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Drawer>
    </Box>
  );
};
