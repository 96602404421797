import { Box, Button, Grid, InputLabel, TextField } from "@mui/material";
import React from "react";
import { layoutStyle } from "../layout-ui/style";

export const AddDealer = () => {
  const classes = layoutStyle();
  return (
    <Box className={classes.mainContentCompany}>
      <Box className={classes.companyHeader}>
        <form className={classes.ModelForm}>
          <Grid className={classes.companyForm}>
            <Button
              variant="outlined"
              type="submit"
              className={classes.fleetListNewButton}
              autoFocus
              sx={{ mt: 1, height: "50px" }}
            >
              Add Dealer
            </Button>
            <Grid className={classes.compnayFormField}>
              <InputLabel>Company Name</InputLabel>
              <TextField
                type="text"
                fullWidth
                // {...register("companyName")}
              />
            </Grid>
            <Grid className={classes.compnayFormField}>
              <InputLabel>Company Logo</InputLabel>
              <TextField
                type="file"
                fullWidth
                // {...register("companyLogo")}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};
