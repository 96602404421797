import { deleteData, getData, postData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Company Api method declarations
 */
export const dealerApi = {
  getDealer(request) {
    return postData(ApiUris.dealerApi.getDealer, request);
  },
  getListDealer(request) {
    return postData(ApiUris.dealerApi.getListDealer, request);
  },
  getDealerByid(id) {
    return getData(`${ApiUris.dealerApi.getDealerById}/${id}`);
  },
  deleteDealerByid(id) {
    return deleteData(`${ApiUris.dealerApi.deleteDealerById}?id=${id}`);
  },
  createDealer(request) {
    return postData(ApiUris.dealerApi.createDealer, request);
  },
  editDealer(request) {
    return postData(ApiUris.dealerApi.editDealer, request);
  },
  uploadCSVDealer(request) {
    return postData(ApiUris.dealerApi.uploadCsv, request);
  },
  multipleFleetUpdate(request) {
    return postData(ApiUris.dealerApi.multipleFleetUpdate, request);
  },
  singleFleetUpdate(request) {
    return postData(ApiUris.dealerApi.singleFleetUpdate, request);
  },
  manualCalculationUpdate(request) {
    return postData(ApiUris.dealerApi.manualCalculationUpdate, request);
  },
  multipleManualCalculationUpdate(request) {
    return postData(ApiUris.dealerApi.multipleManualCalculationUpdate, request);
  },
};
