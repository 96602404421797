import {
  AttachMoney,
  Build,
  ListAlt,
  Speed,
  SyncAlt,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AdminPageStyle } from "../style";
import { NewFleetListFuelAnalysis } from "./NewFleetList-FuelAnalysis";
import { NewFleetListFundingNewVehicle } from "./NewFleetList-FundingNewVehicle";
import { NewFleetListMaintenanceAnalysis } from "./NewFleetList-MaintenanceAnalysis";
import { NewFleetListReplacementSchedule } from "./NewFleetList-ReplacementSchedule";
import { NewFleetListGeneralContent } from "./NewFleetListGeneralContent";
import { useNewVehicle } from "../../../common/hooks";
import { TAB_CONTENT, TAB_VALUE } from "../../../common/constants/popupMessage";
import { showToast } from "../../../components/Toast";

export const NewFleetList = () => {
  const classes = AdminPageStyle();
  const [tabValue, setTabValue] = useState(0);
  const [selectedContent, setSelectedContent] = useState("Fleet List");
  const [fleetNoValue, setFleetNoValue] = useState();
  const location = useLocation();
  const { setNewVehicle } = useNewVehicle();

  const handleButtonClick = (content, value) => {
    if (!fleetNoValue) {
      showToast("Please create fleet.");
    } else {
      setTabValue(value);
      setSelectedContent(content);
    }
    if (value === 4) {
      setNewVehicle([]);
    }
  };

  useEffect(() => {
    if (location.state) {
      setFleetNoValue(location.state?.data?.fleetNo);
    }
    if (location.state && location.state.tabValue) {
      setTabValue(parseInt(location.state.tabValue));
      setSelectedContent(location.state.selectedContent);
    }
  }, [location.state]);

  return (
    <Box className={classes.mainContentFleet}>
      <Box className={classes.fleetPaper}>
        <Box className={classes.fleetPaperMainDiv}>
          <Box className={classes.fleetListSecDiv}>
            {tabValue != 0 && tabValue != TAB_VALUE.FLEET_LIST && (
              <Button
                variant="text"
                className={classes.bgGraycolor}
                onClick={() =>
                  handleButtonClick(
                    TAB_CONTENT.FLEET_LIST,
                    TAB_VALUE.FLEET_LIST
                  )
                }
                classes={{
                  focusVisible: classes.focusVisible,
                }}
              >
                <Box className={classes.btn1}>
                  <Box className={classes.btn2}>
                    <ListAlt className={classes.divMt10} />
                  </Box>
                  <Box className={classes.btn3}>
                    <Box className={classes.fleetListButtonText}>
                      Fleet List
                    </Box>
                  </Box>
                </Box>
              </Button>
            )}
            {tabValue != TAB_VALUE.REPLACEMENT_SCHEDULE && (
              <Button
                variant="text"
                className={classes.bgGraycolor}
                onClick={() =>
                  handleButtonClick(
                    TAB_CONTENT.REPLACEMENT_SCHEDULE,
                    TAB_VALUE.REPLACEMENT_SCHEDULE
                  )
                }
                classes={{
                  focusVisible: classes.focusVisible,
                }}
              >
                <Box className={classes.btn1}>
                  <Box className={classes.btn2}>
                    <SyncAlt className={classes.divMt10} />
                  </Box>
                  <Box className={classes.btn3}>
                    <Box className={classes.fleetListButtonText}>
                      Replacement Schedule
                    </Box>
                  </Box>
                </Box>
              </Button>
            )}
            {tabValue != TAB_VALUE.MAINTENANCE_ANALYSIS && (
              <Button
                variant="text"
                className={classes.bgGraycolor}
                onClick={() =>
                  handleButtonClick(
                    TAB_CONTENT.MAINTENANCE_ANALYSIS,
                    TAB_VALUE.MAINTENANCE_ANALYSIS
                  )
                }
              >
                <Box className={classes.btn1}>
                  <Box className={classes.btn2}>
                    <Build className={classes.divMt10} />
                  </Box>
                  <Box className={classes.btn3}>
                    <Box className={classes.fleetListButtonText}>
                      Maintenance Analysis
                    </Box>
                  </Box>
                </Box>
              </Button>
            )}
            {tabValue != TAB_VALUE.FUEL_ANALYSIS && (
              <Button
                variant="text"
                className={classes.bgGraycolor}
                onClick={() =>
                  handleButtonClick(
                    TAB_CONTENT.FUEL_ANALYSIS,
                    TAB_VALUE.FUEL_ANALYSIS
                  )
                }
              >
                <Box className={classes.btn1}>
                  <Box className={classes.btn2}>
                    <Speed className={classes.divMt10} />
                  </Box>
                  <Box className={classes.btn3}>
                    <Box className={classes.fleetListButtonText}>
                      Fuel Analysis
                    </Box>
                  </Box>
                </Box>
              </Button>
            )}
            {tabValue != TAB_VALUE.FUNDING_NEW_VEHICLE && (
              <Button
                variant="text"
                className={classes.bgGraycolor}
                onClick={() =>
                  handleButtonClick(
                    TAB_CONTENT.FUNDING_NEW_VEHICLE,
                    TAB_VALUE.FUNDING_NEW_VEHICLE
                  )
                }
              >
                <Box className={classes.btn1}>
                  <Box className={classes.btn2}>
                    <AttachMoney className={classes.divMt10} />
                  </Box>
                  <Box className={classes.btn3}>
                    <Box className={classes.fleetListButtonText}>
                      Funding/New Vehicle
                    </Box>
                  </Box>
                </Box>
              </Button>
            )}
          </Box>
        </Box>
        {selectedContent === "Fleet List" ? (
          <NewFleetListGeneralContent
            selectedContent={selectedContent}
            fleetNoValue={fleetNoValue}
          />
        ) : selectedContent === "Replacement Schedule" ? (
          <NewFleetListReplacementSchedule
            selectedContent={selectedContent}
            fleetNoValue={fleetNoValue}
          />
        ) : selectedContent === "Maintenance Analysis" ? (
          <NewFleetListMaintenanceAnalysis
            selectedContent={selectedContent}
            fleetNoValue={fleetNoValue}
          />
        ) : selectedContent === "Fuel Analysis" ? (
          <NewFleetListFuelAnalysis
            selectedContent={selectedContent}
            fleetNoValue={fleetNoValue}
          />
        ) : selectedContent === "Funding/New Vehicle" ? (
          <NewFleetListFundingNewVehicle
            selectedContent={selectedContent}
            fleetNoValue={fleetNoValue}
          />
        ) : null}
      </Box>
    </Box>
  );
};
