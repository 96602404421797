import React, { createContext, useCallback, useState } from "react";
import { showToast } from "../../components/Toast";
import { replacementScheduleApi } from "../api";

/**
 * Replacement list context
 */
export const ReplacementScheduleContext = createContext();

/**
 * Replacement list provider
 */
export const ReplacementScheduleProvider = ({ children }) => {
  const [replacementList, setReplacementList] = useState();
  const [replacementListById, setReplacementListById] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);

  const getReplacementListApi = async (values) => {
    setLoading(true);
    const response = await replacementScheduleApi.getReplacement({
      limit: values?.limit ? values.limit : 1000,
      offset: values?.offset ? values.offset : 0,
      ...values,
      deleted: false,
      filter: {
        ...values?.filter,
      },
      sortColumn: "FleetNo",
      sortType: "desc",
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      setLoading(false);
      setReplacementList([]);
    }

    if (response?.data?.data?.length > 0) {
      setReplacementList(response?.data?.data);
      setLoading(false);
    } else {
      setReplacementList([]);
      setLoading(false);
    }

    return response.data;
  };
  const getReplacementByIdApi = async (id) => {
    setLoading(true);
    const response = await replacementScheduleApi.getReplacementByid(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setReplacementListById(response?.data?.data);
      setLoading(false);
    } else {
      setReplacementListById([]);
      setLoading(false);
    }

    return response.data;
  };
  const createReplacementApi = async (values) => {
    setLoading(true);
    const response = await replacementScheduleApi.createReplacement(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setReplacementList(response?.data?.data);

      getReplacementListApi({
        companyId: values.companyId,
        dealerId: values.dealerId,
        limit: 10000,
        offset: 0,
      });
      setLoading(false);
      showToast("Replacement schedule created successfully!");
    } else {
      setReplacementList([]);
      showToast("Something went wrong Try again!");
      setLoading(false);
    }

    return response.data;
  };
  const editReplacementListApi = async (values) => {
    setLoading(true);
    const response = await replacementScheduleApi.editReplacement(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      getReplacementListApi({
        companyId: values?.companyId,
        dealerId: values?.dealerId,
      });
      setLoading(false);
      showToast("Replacement schedule updated successfully!");
    } else {
      setLoading(false);
      showToast("Something went wrong Try again!");
    }

    return response.data;
  };
  const deleteReplacementByIdApi = async (id) => {
    setLoading(true);
    const response = await replacementScheduleApi.deleteReplacementByid(id?.id);

    if (response?.error) {
      setLoading(false);
    }
    if (response?.data) {
      showToast("Replacement schedule deleted successfully!");
      getReplacementListApi({
        companyId: id?.companyId,
        limit: 10000,
        offset: 0,
      });
      setLoading(false);
    } else {
      showToast("Something went wrong Try again!");
      setLoading(false);
    }

    return response.data;
  };
  const contextValue = {
    replacementList,
    setReplacementList,
    loading,
    setLoading,
    setReplacementList,
    replacementListById,
    setEditData,
    editData,
    getReplacementListApi: useCallback((request) => {
      getReplacementListApi(request);
    }, []),
    createReplacementApi: useCallback((request) => {
      createReplacementApi(request);
    }, []),
    editReplacementListApi: useCallback((request) => {
      editReplacementListApi(request);
    }, []),
    getReplacementByIdApi: useCallback((request) => {
      getReplacementByIdApi(request);
    }, []),
    deleteReplacementByIdApi: useCallback((id) => {
      deleteReplacementByIdApi(id);
    }, []),
  };

  return (
    <ReplacementScheduleContext.Provider value={contextValue}>
      {children}
    </ReplacementScheduleContext.Provider>
  );
};
