import React, { useEffect, useMemo, useState } from "react";
import { layoutStyle } from "../layout-ui/style";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { analysisReportData } from "../../common/constants/gridRowColumn";
import { AgGridReact } from "ag-grid-react";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import moment from "moment";
import { useAnalysis, useCompany, useNewVehicle } from "../../common/hooks";
import { AdminPageStyle } from "./style";
import * as XLSX from "xlsx";
import {
  getCompanyId,
  getDefaultColDef,
} from "../../common/constants/commonFunction";
import { TruckLoader } from "./truckLoader";
import EditReportName from "../../components/Analysis-Report/editReportName";
let dataFiscalYear = [
  { fiscalYear: "Current" },
  { fiscalYear: "Year1" },
  { fiscalYear: "Year2" },
  { fiscalYear: "Year3" },
  { fiscalYear: "Year4" },
  { fiscalYear: "Year5" },
];
export const AnalysisResults = () => {
  const classes = layoutStyle();
  const [columnFinal, setColumnFinal] = useState([]);
  const navigation = useNavigate();
  const { state } = useLocation();
  const { companyNameHeader } = useCompany();
  const [company, setCompany] = useState();
  const [editReportModal, setEditReportModal] = useState(false);
  const [reportName, setReportName] = useState();
  const {
    getAnalysisResultApi,
    analysisResult,
    setAnalysisResult,
    loading,
    setAnalysisResultId,
  } = useAnalysis();
  const { setToastClose } = useNewVehicle();
  const userType = localStorage.getItem("type");

  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  useEffect(() => {
    if (companyNameHeader) {
      const companyId = getCompanyId(userType, companyNameHeader);
      setCompany(companyNameHeader);
      if (companyId) {
        let payload = {
          filter: { companyId: companyId },
          limit: 10000,
          offset: 0,
        };
        getAnalysisResultApi(payload);
      } else {
        setAnalysisResult([]);
      }
    }
  }, [companyNameHeader]);
  const handleViewButton = (params) => {
    navigation("/analysisReport", {
      state: { data: params },
    });
  };
  const handleExportButton = (params) => {
    let reportData = JSON.parse(params?.reportData);
    const fleetMix = XLSX.utils.json_to_sheet([...reportData?.fleetMix]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, fleetMix, "FleetMix");

    const fleet = XLSX.utils.json_to_sheet([reportData?.fleet]);
    XLSX.utils.book_append_sheet(workbook, fleet, "Fleet");

    const maintananceCost = XLSX.utils.json_to_sheet([
      reportData?.maintainanceCost,
    ]);
    XLSX.utils.book_append_sheet(
      workbook,
      maintananceCost,
      "Maintainance Cost"
    );

    const cyclingComparision = XLSX.utils.json_to_sheet([
      reportData?.cyclingComparision,
    ]);
    XLSX.utils.book_append_sheet(
      workbook,
      cyclingComparision,
      "Cycling Comparision"
    );

    const fuelCost = XLSX.utils.json_to_sheet([reportData?.fuelCost]);
    XLSX.utils.book_append_sheet(workbook, fuelCost, "Fuel Cost");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, params?.reportName + ".xlsx");
  };
  const handleEdit = (params) => {
    setEditReportModal(true);
    setReportName(params);
  };
  const handleClose = () => {
    setEditReportModal(false);
  };
  useEffect(() => {
    if (analysisReportData) {
      let actionColumn = {
        field: "action",
        headerName: "Action",
        sortable: false,
        resizable: false,
        width: 140,
        disableClickEventBubbling: true,
        cellRenderer: (params) => (
          <>
            <Button
              style={{
                color: "black",
              }}
              className={classes.addFleetListButton}
              onClick={() => handleViewButton(params.data)}
              // disabled={selectedRows?.length > 1}
            >
              View
            </Button>
            <Button
              className={classes.addFleetListButton}
              style={{
                color: "black",
              }}
              onClick={() => handleExportButton(params.data)}
            >
              Export
            </Button>
            <Button
              className={classes.addFleetListButton}
              style={{
                color: "black",
              }}
              onClick={() => handleEdit(params?.data)}
            >
              Edit
            </Button>
          </>
        ),
      };
      setColumnFinal([...analysisReportData, actionColumn]);
    }
  }, [analysisReportData]);
  const handleCreateFleet = () => {
    navigation("/fleet-list");
  };
  useEffect(() => {
    setAnalysisResultId(null);
    setToastClose(false);
  }, []);

  return (
    <Box className={classes.mainContent}>
      <Box className={classes.gridContantFleetList}>
        <Box className={classes.gridContantDealer}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.headerAnalysisReport}
            >
              <span className={classes.headerAnalysisName}>
                Analysis Report
              </span>
              <div>
                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.reportButton}
                  autoFocus
                  onClick={handleCreateFleet}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
          {loading ? (
            <TruckLoader />
          ) : (
            <AgGridReact
              className="ag-theme-quartz"
              suppressRowClickSelection
              rowHeight={35}
              pagination={true}
              rowData={analysisResult}
              defaultColDef={defaultColDef}
              columnDefs={columnFinal}
            />
          )}
          <EditReportName
            setOpen={setEditReportModal}
            open={editReportModal}
            title={"Edit Report Name"}
            data={reportName}
            handleClose={() => handleClose()}
          />
        </Box>
      </Box>
    </Box>
  );
};
