import { Box } from "@mui/material";
import React from "react";
import { layoutStyle } from "../layout-ui/style";

export const Vincentric = () => {
  const classes = layoutStyle();
  return (
    <Box className={classes.backgroundImage}>
      <Box className={classes.dashboardMainContent}>
        <iframe
          id="myFrame"
          src="
https://www.dynamiccto.com/VincentricSite/SegmentAnalysis"
          width={"100%"}
          height={"690px"}
        ></iframe>
      </Box>
    </Box>
  );
};
