import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Box, Button, Card, Grid, Stack } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReportColumnData } from "../../common/constants/gridRowColumn";
import { useAnalysis, useCompany } from "../../common/hooks";
import { layoutStyle } from "../layout-ui/style";
import { BarChart } from "./charts/barChart";
import { DonutChart } from "./charts/donutChart";
import { LineChart } from "./charts/lineChart";
import { MixChart } from "./charts/mixChart";
import { TruckLoader } from "./truckLoader";
import { getDefaultColDef } from "../../common/constants/commonFunction";
import { MaintananceLineChart } from "./charts/maintananceLineChart";
import { getCompanyId } from "../../common/constants/commonFunction";
import { FindArrayValue } from "../../common/services/findArrayValue";
import { handleExportButton } from "../../common/services/exportPowerBI";

export const Reports = () => {
  const classes = layoutStyle();
  const { state } = useLocation();
  const { getAnalysisResultByIdApi, analysisResultId, loading } = useAnalysis();
  const { companyNameHeader, getCompanyLogo } = useCompany();
  const [reportData12, setReportData12] = useState();
  const [rowData, setRowData] = useState([]);
  const [donutChartData, setDonutChartData] = useState([]);
  const [fiscalYearLabel, setFiscalYearLabel] = useState([]);
  const [lineChartData, setLineChartData] = useState();
  const [maintananceChartData, setMaintananceChartData] = useState();
  const [barChartData, setBarChartData] = useState([]);
  const [barChartLabel, setBarChartLabel] = useState([]);
  const [fleetMixLabel, setFleetMixLabel] = useState([]);
  const [vehicleEquityData, SetVehicleEquityData] = useState([]);
  const [anualNeedsData, setAnualNeedsData] = useState([]);
  const [cashOutlayData, setCashOutlayData] = useState([]);
  const [currentPaymentData, setCurrentPaymentData] = useState([]);
  const [cyclingPaymentData, setCyclingPaymentData] = useState([]);
  const [fleetSizeData, setFleetSizeData] = useState([]);
  const [fleetSpeedData, setFleetSpeedData] = useState([]);
  const [savingsData, setSavingsData] = useState([]);
  const [currentFleetSize, setCurrentFleetSize] = useState(null);
  const [noOfVehicle, setNoOfVehicle] = useState(null);
  const [totalSavings, setTotalSavings] = useState(0);
  const [avgAnnualMiles, setAvgAnnualMiles] = useState(null);
  const [avgCurrentMileage, setAvgCurrentMileage] = useState(null);
  const [vehicleModelYear, setVehicleModelYear] = useState(null);
  const [avgPurchasePrice, setAvgPurchasePrice] = useState(0);
  const [companyId, setCompanyId] = useState();
  const [maintenanceFuel, setMaintenanceFuel] = useState(null);
  const userType = localStorage.getItem("type");

  useEffect(() => {
    const fetchAnalysisResult = async () => {
      await getAnalysisResultByIdApi(state?.id);
    };
    fetchAnalysisResult();
  }, [state, getAnalysisResultByIdApi]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (analysisResultId?.reportData) {
      try {
        const parsedData = JSON.parse(analysisResultId?.reportData);
        setReportData12(parsedData);
        let fuelValue;
        let maintenanceValue;
        //donut n line chart
        let donutData = [];
        let yearLabel = [];
        let lineData = [];
        let maintananceData = [];
        let barData = [];
        let barLabel = [];
        let mixLabel = [];
        let vehicleEquityDataTemp = [];
        let annualNeedsDataTemp = [];
        let cashOutlayDataTemp = [];
        let currentPaymentDataTemp = [];
        let cyclingPaymentDataTemp = [];
        let fleetSizeDataTemp = [];
        let fleetSpeedDataTemp = [];
        let savingsDataTemp = [];
        let totalSavingValue = [];
        let maintenanceFuel = [];
        parsedData?.fleetMix.sort((a, b) => b.savings - a.savings);
        parsedData?.fleetMix.forEach((element) => {
          donutData.push(
            element?.fuel
              ? { [element?.fiscalYear]: element?.fuel.toFixed(2) }
              : { [element?.fiscalYear]: 0 }
          );
          yearLabel.push(element?.fiscalYear);
          lineData.push(
            element?.savings
              ? { [element?.fiscalYear]: element?.savings.toFixed(2) }
              : { [element?.fiscalYear]: 0 }
          );
          totalSavingValue.push(
            element?.savings ? element?.savings.toFixed(2) : 0
          );
          maintananceData.push(
            element?.maintenance
              ? { [element?.fiscalYear]: element?.maintenance.toFixed(2) }
              : { [element?.fiscalYear]: 0 }
          );
          mixLabel.push(element?.fiscalYear);
          vehicleEquityDataTemp.push(
            element?.vehicleEquity
              ? { [element?.fiscalYear]: element?.vehicleEquity.toFixed(2) }
              : { [element?.fiscalYear]: 0 }
          );
          annualNeedsDataTemp.push(
            element?.annualNeeds ? element?.annualNeeds : 0
          );
          cashOutlayDataTemp.push(
            element?.cashOutlay ? element?.cashOutlay : 0
          );
          currentPaymentDataTemp.push(
            element?.currentPayment
              ? { [element?.fiscalYear]: element?.currentPayment.toFixed(2) }
              : { [element?.fiscalYear]: 0 }
          );
          cyclingPaymentDataTemp.push(
            element?.cyclingPayment
              ? { [element?.fiscalYear]: element?.cyclingPayment.toFixed(2) }
              : { [element?.fiscalYear]: 0 }
          );
          fleetSizeDataTemp.push(element?.fleetSize ? element?.fleetSize : 0);
          fleetSpeedDataTemp.push(
            element?.fleetSpeed
              ? { [element?.fiscalYear]: element?.fleetSpeed.toFixed(2) }
              : { [element?.fiscalYear]: 0 }
          );
          savingsDataTemp.push(
            element?.savings
              ? { [element?.fiscalYear]: element?.savings.toFixed(2) }
              : { [element?.fiscalYear]: 0 }
          );
          maintenanceFuel.push(
            element?.fuel || element?.maintenance
              ? {
                  [element?.fiscalYear]:
                    Number(element?.fuel) + Number(element?.maintenance),
                }
              : { [element?.fiscalYear]: 0 }
          );

          const sum = totalSavingValue.reduce(
            (accumulator, currentValue) => accumulator + Number(currentValue),
            0
          );

          setCurrentFleetSize(parsedData?.fleet?.currentFleetSize);
          setNoOfVehicle(parsedData?.fuelCost?.numberOfVehicle);
          setTotalSavings(Math.round(sum)?.toLocaleString());
          let annual = Math.round(parsedData?.fuelCost?.avgAnnualMiles);
          let addCommaAnnual = annual?.toLocaleString();
          setAvgAnnualMiles(addCommaAnnual);
          let currentAvg = Math.round(parsedData?.fleet?.currentMileage);
          let addCommaCurrentAvg = currentAvg?.toLocaleString();
          setAvgCurrentMileage(addCommaCurrentAvg);
          setVehicleModelYear(parsedData?.fleet?.avgVehicleYear);
          let newVehicleAvgPrice = Math.round(
            parsedData?.fleet?.avgNewVehiclePrice
          );
          let newAvgVehiclePrice = newVehicleAvgPrice?.toLocaleString();
          setAvgPurchasePrice(newAvgVehiclePrice);
        });
        let lineDataValue = {
          Current: FindArrayValue(lineData, "Current"),
          Year1: FindArrayValue(lineData, "Year1"),
          Year2: FindArrayValue(lineData, "Year2"),
          Year3: FindArrayValue(lineData, "Year3"),
          Year4: FindArrayValue(lineData, "Year4"),
          Year5: FindArrayValue(lineData, "Year5"),
        };
        let donutChartDataValue = {
          Current: FindArrayValue(donutData, "Current"),
          Year1: FindArrayValue(donutData, "Year1"),
          Year2: FindArrayValue(donutData, "Year2"),
          Year3: FindArrayValue(donutData, "Year3"),
          Year4: FindArrayValue(donutData, "Year4"),
          Year5: FindArrayValue(donutData, "Year5"),
        };
        let maintananceDataValue = {
          Current: FindArrayValue(maintananceData, "Current"),
          Year1: FindArrayValue(maintananceData, "Year1"),
          Year2: FindArrayValue(maintananceData, "Year2"),
          Year3: FindArrayValue(maintananceData, "Year3"),
          Year4: FindArrayValue(maintananceData, "Year4"),
          Year5: FindArrayValue(maintananceData, "Year5"),
        };
        let mixChartSavingValue = {
          Current: FindArrayValue(savingsDataTemp, "Current"),
          Year1: FindArrayValue(savingsDataTemp, "Year1"),
          Year2: FindArrayValue(savingsDataTemp, "Year2"),
          Year3: FindArrayValue(savingsDataTemp, "Year3"),
          Year4: FindArrayValue(savingsDataTemp, "Year4"),
          Year5: FindArrayValue(savingsDataTemp, "Year5"),
        };
        let mixChartFleetSpendValue = {
          Current: FindArrayValue(fleetSpeedDataTemp, "Current"),
          Year1: FindArrayValue(fleetSpeedDataTemp, "Year1"),
          Year2: FindArrayValue(fleetSpeedDataTemp, "Year2"),
          Year3: FindArrayValue(fleetSpeedDataTemp, "Year3"),
          Year4: FindArrayValue(fleetSpeedDataTemp, "Year4"),
          Year5: FindArrayValue(fleetSpeedDataTemp, "Year5"),
        };
        let mixChartCyclingPaymentValue = {
          Current: FindArrayValue(cyclingPaymentDataTemp, "Current"),
          Year1: FindArrayValue(cyclingPaymentDataTemp, "Year1"),
          Year2: FindArrayValue(cyclingPaymentDataTemp, "Year2"),
          Year3: FindArrayValue(cyclingPaymentDataTemp, "Year3"),
          Year4: FindArrayValue(cyclingPaymentDataTemp, "Year4"),
          Year5: FindArrayValue(cyclingPaymentDataTemp, "Year5"),
        };
        let mixChartVehicleEquityValue = {
          Current: FindArrayValue(vehicleEquityDataTemp, "Current"),
          Year1: FindArrayValue(vehicleEquityDataTemp, "Year1"),
          Year2: FindArrayValue(vehicleEquityDataTemp, "Year2"),
          Year3: FindArrayValue(vehicleEquityDataTemp, "Year3"),
          Year4: FindArrayValue(vehicleEquityDataTemp, "Year4"),
          Year5: FindArrayValue(vehicleEquityDataTemp, "Year5"),
        };
        let mixChartCurrentPaymentValue = {
          Current: FindArrayValue(currentPaymentDataTemp, "Current"),
          Year1: FindArrayValue(currentPaymentDataTemp, "Year1"),
          Year2: FindArrayValue(currentPaymentDataTemp, "Year2"),
          Year3: FindArrayValue(currentPaymentDataTemp, "Year3"),
          Year4: FindArrayValue(currentPaymentDataTemp, "Year4"),
          Year5: FindArrayValue(currentPaymentDataTemp, "Year5"),
        };
        let maintenanceFuelTemp = {
          Current: FindArrayValue(maintenanceFuel, "Current"),
          Year1: FindArrayValue(maintenanceFuel, "Year1"),
          Year2: FindArrayValue(maintenanceFuel, "Year2"),
          Year3: FindArrayValue(maintenanceFuel, "Year3"),
          Year4: FindArrayValue(maintenanceFuel, "Year4"),
          Year5: FindArrayValue(maintenanceFuel, "Year5"),
        };
        //bar chart
        barData.push(parsedData?.fuelCost?.totalFuelSpend);
        barLabel.push("Current Total Fuel Spend");
        barData.push(parsedData?.fuelCost?.annualSpendVehicle);
        barLabel.push("Current Fuel Spend Per Vehicle");
        barData.push(parsedData?.maintainanceCost?.totalAnnualized);
        barLabel.push("Current Total Maintenance Spend");
        barData.push(parsedData?.maintainanceCost?.vehicleSpend);
        barLabel.push("Current Maintenance Spend Per Vehicle");
        barData.push(parsedData?.maintainanceCost?.costPerMile);
        barLabel.push("Current Average Cost Per Mile");
        parsedData?.fleetMix?.map((item) => {
          if (item.fiscalYear === "Year1") {
            fuelValue = item?.fuel;
            maintenanceValue = item?.maintenance;
          }
          return true;
        });
        barData.push(fuelValue);
        barLabel.push("Y1 Total Fuel Spend");
        barData.push(parsedData?.cyclingComparision?.fuelSpendY1);
        barLabel.push("Y1 Fuel Spend Per Vehicle");
        barData.push(maintenanceValue);
        barLabel.push("Y1 Total Maintenance Spend");
        barData.push(parsedData?.maintainanceCost?.vehicleSpendMonth);
        barLabel.push("Y1 Monthly Main. Spend Per Vehicle");
        barData.push(parsedData?.cyclingComparision?.avgYear1CostPerMiles);
        barLabel.push("Average Y1 Cost Per Mile");

        // barData.push(parsedData?.cyclingComparision?.monthlyLeaseCost);
        // barLabel.push("Monthly Lease Cost Average");
        // barData.push(parsedData?.cyclingComparision?.monthlyMaintananceYear1);
        // barLabel.push("Year 1 monthly maintanance");
        // barData.push(parsedData?.cyclingComparision?.avgYear1Mpg);
        // barLabel.push("Average Year 1 MPG");
        // barData.push(parsedData?.fuelCost?.avgAnnualMiles);
        // barLabel.push("Avg. Annual Miles");
        // barData.push(parsedData?.fuelCost?.costPerGallon);
        // barLabel.push("Avg. Cost Per Gallon");
        // barData.push(parsedData?.fuelCost?.avgMpg);
        // barLabel.push("Avg. MPG");
        // barData.push(parsedData?.fuelCost?.monthlySpendVehicle);
        // barLabel.push("Monthly Spend/Vehicle");
        // barData.push(parsedData?.fuelCost?.numberOfVehicle);
        // barLabel.push("No. of Vehicles");

        setDonutChartData(donutChartDataValue);
        setFiscalYearLabel(yearLabel);
        setLineChartData(lineDataValue);
        setMaintananceChartData(maintananceDataValue);
        setBarChartData(barData);
        setBarChartLabel(barLabel);
        setFleetMixLabel(mixLabel);
        SetVehicleEquityData([
          mixChartVehicleEquityValue?.Current,
          mixChartVehicleEquityValue?.Year1,
          mixChartVehicleEquityValue?.Year2,
          mixChartVehicleEquityValue?.Year3,
          mixChartVehicleEquityValue?.Year4,
          mixChartVehicleEquityValue?.Year5,
        ]);

        setAnualNeedsData(annualNeedsDataTemp);
        setCashOutlayData(cashOutlayDataTemp);
        setCurrentPaymentData([
          mixChartCurrentPaymentValue?.Current,
          mixChartCurrentPaymentValue?.Year1,
          mixChartCurrentPaymentValue?.Year2,
          mixChartCurrentPaymentValue?.Year3,
          mixChartCurrentPaymentValue?.Year4,
          mixChartCurrentPaymentValue?.Year5,
        ]);

        setCyclingPaymentData([
          mixChartCyclingPaymentValue?.Current,
          mixChartCyclingPaymentValue?.Year1,
          mixChartCyclingPaymentValue?.Year2,
          mixChartCyclingPaymentValue?.Year3,
          mixChartCyclingPaymentValue?.Year4,
          mixChartCyclingPaymentValue?.Year5,
        ]);

        setFleetSizeData(fleetSizeDataTemp);
        setFleetSpeedData([
          mixChartFleetSpendValue?.Current,
          mixChartFleetSpendValue?.Year1,
          mixChartFleetSpendValue?.Year2,
          mixChartFleetSpendValue?.Year3,
          mixChartFleetSpendValue?.Year4,
          mixChartFleetSpendValue?.Year5,
        ]);

        setSavingsData([
          mixChartSavingValue?.Current,
          mixChartSavingValue?.Year1,
          mixChartSavingValue?.Year2,
          mixChartSavingValue?.Year3,
          mixChartSavingValue?.Year4,
          mixChartSavingValue?.Year5,
        ]);
        setMaintenanceFuel([
          maintenanceFuelTemp?.Current?.toString(),
          maintenanceFuelTemp?.Year1?.toString(),
          maintenanceFuelTemp?.Year2?.toString(),
          maintenanceFuelTemp?.Year3?.toString(),
          maintenanceFuelTemp?.Year4?.toString(),
          maintenanceFuelTemp?.Year5?.toString(),
        ]);
        //grid
        setRowData(parsedData?.fleetMix);
      } catch (e) {
        console.error("Error parsing reportData", e);
      }
    }
    return () => {};
  }, [analysisResultId]); // eslint-disable-line react-hooks/exhaustive-deps

  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  useEffect(() => {
    if (companyNameHeader) {
      let companyValue = getCompanyId(userType, companyNameHeader);
      setCompanyId(companyValue);
    }
    getCompanyLogo(companyId);
  }, [companyNameHeader]); // eslint-disable-line react-hooks/exhaustive-deps

  // const defaultColDef = useMemo(() => {
  //   return {
  //     flex: 1,
  //     minWidth: 150,
  //     filter: "agTextColumnFilter",
  //     menuTabs: ["filterMenuTab"],
  //     wrapHeaderText: true,
  //     autoHeaderHeight: true,
  //   };
  // }, []);
  const CardThree = ({ par1, par2, par3 }) => {
    return (
      <div className={classes.reportInnerDiv}>
        <Grid container spacing={0} className={classes.reportCardThree}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.reportInnerDivTitle}
          >
            {par1}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.reportInnerDivTotal}
          >
            {par2}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            md={6}
            className={classes.reportInnerDivIcon}
          >
            {par3}
          </Grid>
        </Grid>
      </div>
    );
  };
  const CardTwo = ({ par1, par2 }) => {
    return (
      <div className={classes.reportInnerDivSec}>
        <Grid container spacing={0} className={classes.reportCardSec}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.reportInnerDivSecTotal}
          >
            {par1}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.reportInnerDivSecTitle}
          >
            {par2}
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Box className={classes.reportFleetMargin}>
      <Box className={classes.reportMainDiv}>
        <Box className={classes.reportHeader}>
          <Box className={classes.reportHeaderTitle}>
            <Box>Fleet Management</Box>
            {companyNameHeader?.companyImage && (
              <Box className={classes.companyReportLogo}>
                <img
                  src={`data:image/png;base64,${companyNameHeader?.companyImage}`}
                  height="auto"
                  width="50%"
                  objectFit="cover"
                  alt="company logo"
                />
              </Box>
            )}
            <Button
              className={classes.exportPowerBIButton}
              onClick={() => handleExportButton(analysisResultId)}
            >
              Export
            </Button>
          </Box>
        </Box>
        <Grid container spacing={1} marginTop={1}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4} lg={4} margin={0}>
                <Card variant="outlined" className={classes.reportDivThree}>
                  <CardThree
                    par1="Current Fleet Size"
                    par2={currentFleetSize}
                    par3={<LocalShippingIcon style={{ fontSize: 35 }} />}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} margin={0}>
                <Card variant="outlined" className={classes.reportDivThree}>
                  <CardThree
                    par1="No. of Cycled Vehicles"
                    par2={noOfVehicle}
                    par3={<DirectionsCarIcon style={{ fontSize: 35 }} />}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} margin={0}>
                <Card variant="outlined" className={classes.reportDivThree}>
                  <CardThree
                    par1="Total Savings"
                    par2={"$" + totalSavings}
                    par3=""
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card variant="outlined" className={classes.reportCardBorder}>
                  <Stack className={classes.cardChartHeader}>
                    Savings by Fiscal Year
                  </Stack>
                  {lineChartData && (
                    <LineChart
                      seriesData={lineChartData}
                      labels={fiscalYearLabel}
                    />
                  )}
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className={classes.reportDonutGrid}
            height={"320px"}
          >
            <Card variant="outlined" className={classes.reportCardBorder}>
              <Stack className={classes.cardChartHeader}>
                Fuel By Fiscal Year
              </Stack>
              <DonutChart
                seriesData={donutChartData}
                labels={fiscalYearLabel}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} margin={0}>
                <Card variant="outlined" className={classes.reportDivThree}>
                  <CardTwo par1={avgAnnualMiles} par2="Avg. Annual Miles" />
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} margin={0}>
                <Card variant="outlined" className={classes.reportDivThree}>
                  <CardTwo
                    par1={avgCurrentMileage}
                    par2="Avg. Current Mileage"
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} margin={0}>
                <Card variant="outlined" className={classes.reportDivThree}>
                  <CardTwo
                    par1={vehicleModelYear}
                    par2="Current Avg. Age in Yrs."
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} margin={0}>
                <Card variant="outlined" className={classes.reportDivThree}>
                  <CardTwo
                    par1={"$" + avgPurchasePrice}
                    par2="Avg. Purchase Price"
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.marginTopOne}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Card variant="outlined" className={classes.reportCardBorder}>
              <Stack className={classes.cardChartHeader}>
                First Year Cycling Spend Comparison
              </Stack>
              <BarChart
                seriesData={barChartData}
                labels={barChartLabel && barChartLabel}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Card variant="outlined" className={classes.reportCardBorder}>
              <Stack className={classes.cardChartHeader}>5-Year Plan</Stack>
              <MixChart
                fleetMixLabel={fleetMixLabel}
                vehicleEquityData={vehicleEquityData}
                anualNeedsData={anualNeedsData}
                cashOutlayData={cashOutlayData}
                currentPaymentData={currentPaymentData}
                cyclingPaymentData={cyclingPaymentData}
                fleetSizeData={fleetSizeData}
                fleetSpeedData={fleetSpeedData}
                savingsData={savingsData}
                maintenanceFuel={maintenanceFuel}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card variant="outlined" className={classes.reportCardBorder}>
              <Stack className={classes.cardChartHeader}>
                Maintenance savings
              </Stack>
              {maintananceChartData && (
                <MaintananceLineChart
                  seriesData={maintananceChartData}
                  labels={fiscalYearLabel}
                />
              )}
            </Card>
          </Grid>
          {loading ? (
            <TruckLoader />
          ) : (
            <Grid item md={12} lg={12} sm={12} xs={12} marginBottom={10}>
              {rowData && (
                <AgGridReact
                  className="ag-theme-quartz ag-report-class"
                  rowSelection="multiple"
                  suppressRowClickSelection
                  // pinnedColumns={{ right: ["action"] }}
                  pagination={false}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  columnDefs={ReportColumnData}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
