import { Box, Button, Stack } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ManageUserColumnData } from "../../common/constants/gridRowColumn";
import { useUser } from "../../common/hooks/user";
import { SearchBar } from "../../components/SearchBar";
import { useDebounce } from "../../components/useDebounce";
import { layoutStyle } from "../layout-ui/style";
import AddMangeUser from "./AddMangeUser";
import { DeleteDialog } from "../../components/DeleteDialog";
import { TruckLoader } from "./truckLoader";
import {
  actionColumnFunction,
  getDefaultColDef,
  userControl,
} from "../../common/constants/commonFunction";
import { UserControlDialogue } from "../../components/UserControlDialog";
import { USER_CONTROL } from "../../common/constants/popupMessage";

export const ManageUser = () => {
  const classes = layoutStyle();
  const navigation = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [columnFinal, setColumnFinal] = useState([]);
  const { getUser, user, deleteUser, loading, setUserData } = useUser();
  const [editRowData, setEditRowData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [mainRowData, setMainRowData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userControlOpen, setUserControlOpen] = useState(false);
  const [userControlPayload, setUserControlPayload] = useState(null);
  const [deleteValue, setDeleteValue] = useState();
  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const handleAddButton = () => {
    setOpen(true);
  };

  const handleEditButton = (rowData) => {
    setEditRowData(rowData);
    setOpen(true);
  };
  const handleDeleteButton = async (values) => {
    setDeleteOpen(true);
    setDeleteValue(values?.id);
  };

  const handleDelete = async () => {
    await deleteUser(deleteValue);
    setDeleteOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setEditRowData(null);
    setUserData(null);
  };
  const handleDeactivate = async (params) => {
    const payload = {
      id: params.id,
      status: USER_CONTROL.DEACTIVATE,
    };
    setUserControlOpen(true);
    setUserControlPayload(payload);
  };

  const handleReactivate = async (params) => {
    const payload = {
      id: params.id,
      status: USER_CONTROL.ACTIVATE,
    };
    setUserControlOpen(true);
    setUserControlPayload(payload);
  };

  useEffect(() => {
    if (ManageUserColumnData) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        handleDeleteButton: handleDeleteButton,
        deleteButton: true,
      });
      const userControlColumn = userControl({
        handleDeactivate: handleDeactivate,
        handleReactivate: handleReactivate,
        classes: classes,
      });
      setColumnFinal([
        ...ManageUserColumnData,
        actionColumn,
        // userControlColumn,
      ]);
    }
  }, [ManageUserColumnData, selectedRows]);
  const onSearchFunc = (e) => {
    setSearchValue(e[0]);
  };
  const debouncedSearch = useDebounce(onSearchFunc, 500);
  const searchData = mainRowData?.filter((company) =>
    company?.name?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const onSearch = (e) => {
    debouncedSearch(e?.target?.value);
    handleClickSearch(e);
  };
  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };
  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (searchValue) {
      setMainRowData(searchData);
    } else if (user?.length > 0) {
      setMainRowData(user);
    }
  }, [searchValue, user]);

  return (
    <Box className={classes.mainContent}>
      <Box className={classes.gridContantFleetList}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Box className={classes.headerNameText}>Users</Box>
          <Box className={classes.addEditGrid}>
            <Button
              variant="outlined"
              onClick={handleAddButton}
              className={classes.fleetListNewButton}
            >
              Add
            </Button>
            <SearchBar onSearch={onSearch} />
          </Box>
        </Stack>
        {loading ? (
          <TruckLoader />
        ) : (
          <AgGridReact
            className="ag-theme-quartz"
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids);
            }}
            rowSelection="multiple"
            pinnedColumns={{ right: ["action"] }}
            pagination={true}
            rowData={mainRowData}
            defaultColDef={defaultColDef}
            columnDefs={columnFinal}
            checkboxSelection
          />
        )}
        <AddMangeUser
          setOpen={setOpen}
          open={open}
          rowData={editRowData}
          handleClose={() => handleClose()}
        />
      </Box>
      {deleteOpen && (
        <DeleteDialog
          open={deleteOpen}
          setOpen={() => setDeleteOpen(false)}
          deleteApi={() => handleDelete()}
        />
      )}
      {userControlOpen && (
        <UserControlDialogue
          open={userControlOpen}
          setOpen={() => setUserControlOpen(false)}
          data={userControlPayload}
        />
      )}
    </Box>
  );
};
